import Axios from 'axios'

export async function getConfig(domain) {
    let instance = Axios.create({
        baseURL: `https://${domain}/api/config`,
    })

    return new Promise((resolve, reject) => {
        instance
            .get()
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

