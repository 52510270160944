import React from "react";

import MainHeader from "../../components/modules/dashboard/partials/MainHeader";
import { getAllUsers, getUsersFor } from "../../service/module/Users";
import { getAllGroupsCount, getGroupsNoPaginate } from "../../service/module/Groups";

import css from "./Home.module.css";

const Home = () => {
  const [counterUsers, setCounterUsers] = React.useState();
  const [counterGroups, setCounterGroups] = React.useState();
  const [counterStudentUsers, setCounterStudentUsers] = React.useState();
  const [counterStudentsInTeams, setCounterStudentsInTeams] = React.useState();

  React.useEffect(() => {
    async function getUsers(current) {
      await getAllUsers(current).then((response) => {
        setCounterUsers(response.meta.total);
      }).catch(() => {});
      await getUsersFor("learner").then((response) => {
        setCounterStudentUsers(response.data.length);
        // Set counter for users that doesnt have a null role_id
        var counter = 0;
        response.data.forEach((user) => {
          if (user.role_id !== null) {
            counter++;
          }
        });
      }).catch(() => {});
      await getAllGroupsCount().then((response) => {
        setCounterStudentsInTeams(response.data)
      }).catch(() => {});
      await getGroupsNoPaginate().then((response) => {
        setCounterGroups(response.data.length)
      }).catch(() => {});
    }

    getUsers("");
  }, []);

  return (
    <div className={css.root}>
      <MainHeader 
        counterUsers={counterUsers} 
        counterGroups={counterGroups}
        counterStudentUsers={counterStudentUsers}
        counterStudentsInTeams={counterStudentsInTeams}
      />
    </div>
  );
};

export default Home;
