let routes = [
  {
    path: "",
    name: "Dashboard",
    layout: "/dashboard",
  },
  {
    path: "users",
    name: "Usuarios",
    layout: "/dashboard",
  },
  {
    path: "roles",
    name: "Perfiles",
    layout: "/dashboard",
  },
  {
    path: "groups",
    name: "Grupos",
    layout: "/dashboard",
  },
  {
    path: "challenges",
    name: "Retos",
    layout: "/dashboard",
  },
];

export const breadCrumbRoutes = [
  {
    path: "/dashboard",
    label: "Dashboard",
    current: "/dashboard",
    last: null,
    parent: null,
  },
  {
    path: "users",
    label: "Usuarios",
    current: "/dashboard/users",
    last: null,
    parent: null,
  },
  {
    path: "users/edit",
    label: "Editar usuario",
    current: "/dashboard/users/1/edit",
    last: "/dashboard/users",
    parent: "users",
  },
  {
    path: "users/assign",
    label: "Asignar usuario",
    current: "/dashboard/users/1/assign",
    last: "/dashboard/users",
    parent: "users",
  },
  {
    path: "groups",
    label: "Grupos",
    current: "/dashboard/groups",
    last: null,
    parent: null,
  },
  {
    path: "groups/edit",
    label: "Editar grupo",
    current: "/dashboard/groups/1/edit",
    last: "/dashboard/groups",
    parent: "groups",
  },
  {
    path: "groups/add",
    label: "Agregar grupo",
    current: "/dashboard/groups/add",
    last: "/dashboard/groups",
    parent: "groups",
  },
  {
    path: "roles",
    label: "Perfiles",
    current: "/dashboard/roles",
    last: null,
    parent: null,
  },
  {
    path: "roles/edit",
    label: "Editar perfil",
    current: "/dashboard/roles/1/edit",
    last: "/dashboard/roles",
    parent: "roles",
  },
  {
    path: "roles/edit/addchallenges",
    label: "Agregar retos al perfil",
    current: "/dashboard/roles/1/edit/addchallenges",
    last: "/dashboard/roles/1/edit",
    parent: "roles/edit",
  },
  {
    path: "roles/add",
    label: "Agregar perfil",
    current: "/dashboard/roles/add",
    last: "/dashboard/roles",
    parent: "roles",
  },
  {
    path: "challenges",
    label: "Retos",
    current: "/dashboard/challenges",
    last: null,
    parent: null,
  },
  {
    path: "challenges/edit",
    label: "Editar reto",
    current: "/dashboard/challenges/1/edit",
    last: "/dashboard/challenges",
    parent: "challenges",
  },
  {
    path: "challenges/sprint/add",
    label: "Agregar sprint",
    current: "/dashboard/challenges/1/sprint/add",
    last: "/dashboard/challenges/1/edit",
    parent: "challenges/edit"
  },
  {
    path: "challenges/sprint/edit",
    label: "Editar sprint",
    current: "/dashboard/challenges/1/sprint/2/edit",
    last: "/dashboard/challenges/1/edit",
    parent: "challenges/edit"
  },
  {
    path: "challenges/sprint/modality/child/add",
    label: "Agregar sprint hijo",
    current: "/dashboard/challenges/1/sprint/2/modality/3/child/add",
    last: "/dashboard/challenges/1/sprint/2/edit",
    parent: "challenges/sprint/edit"
  },
  {
    path: "challenges/sprint/modality/child/edit",
    label: "Editar sprint hijo",
    current: "/dashboard/challenges/1/sprint/2/modality/3/child/4/edit",
    last: "/dashboard/challenges/1/sprint/2/edit",
    parent: "challenges/sprint/edit"
  },
  {
    path: "challenges/sprint/modality/child/resource/add",
    label: "Agregar recurso sprint hijo",
    current: "/dashboard/challenges/1/sprint/2/modality/3/child/4/resource/add",
    last: "/dashboard/challenges/1/sprint/2/modality/3/child/4/edit",
    parent: "challenges/sprint/modality/child/edit"
  },
  {
    path: "challenges/sprint/modality/child/resource/edit",
    label: "Editar recurso sprint hijo",
    current: "/dashboard/challenges/1/sprint/2/modality/3/child/4/resource/5/edit",
    last: "/dashboard/challenges/1/sprint/2/modality/3/child/4/edit",
    parent: "challenges/sprint/modality/child/edit"
  },
  {
    path: "challenges/sprint/resource/edit",
    label: "Editar recurso",
    current: "/dashboard/challenges/1/sprint/2/resource/3/edit",
    last: "/dashboard/challenges/1/sprint/2/edit",
    parent: "challenges/sprint/edit"
  },
  {
    path: "challenges/sprint/resource/add",
    label: "Agregar recurso",
    current: "/dashboard/challenges/1/sprint/2/resource/add",
    last: "/dashboard/challenges/1/sprint/2/edit",
    parent: "challenges/sprint/edit"
  },
  {
    path: "challenges/add",
    label: "Agregar reto",
    current: "/dashboard/challenges/add",
    last: "/dashboard/challenges",
    parent: "challenges",
  }
]

export default routes;
