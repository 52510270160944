import React from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import Header from '../../components/modules/dashboard/partials/Header'
import RolesForm from '../../components/modules/dashboard/roles/RolesForm'
import { useProfile } from '../../context/MainContext'
import { storeRole } from '../../service/module/Roles'

import css from './AddRole.module.css'

export default function AddRole() {
  const [errors, setErrors] = React.useState()
  const [role, setRole] = React.useState({})
  const { userId, userType } = useProfile()
  const history = useHistory()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  function changeInput(e) {
    setRole({
      ...role,
      [e.target.name]: e.target.value,
    })
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function register() {
    if (!role.seniority) {
      swalCustom.fire({
        title: 'Error!',
        text: 'Selecciona un Seniority',
        icon: 'error',
        confirmButtonText: 'Regresar',
      })
      return
    }
    role.userId = userId
    await storeRole(role)
      .then(response => {
        console.log(response)
        if (response.success) {
          swalCustom.fire({
            title: 'Perfil creado exitosamente',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: 'Seguir editando',
            confirmButtonText: 'Ver todos los perfiles',
          }).then(result => {
            if (result.isConfirmed) {
              history.push('/dashboard/roles')
            }
            if (result.isDenied) {
              history.push(`/dashboard/roles/${response.meta.data}/edit`)
            }
          })
        }
        if (!response.success) {
          swalCustom.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error',
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {})
        }
      })
      .catch(error => {
        setErrors(error.errors)
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        }).then(_result => {})
      })
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <RolesForm
          changeInput={changeInput}
          errors={errors}
          register={register}
          title="Agregar Perfil"
        />
      )}
    </div>
  )
}
