import React, { forwardRef } from 'react'

import { makeStyles, InputBase } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  inputStyle: {
    height: 41,
    position: 'relative',
    borderTopLeftRadius: '8px 20px',
    borderBottomLeftRadius: '8px 20px',
    borderTopRightRadius: '8px 20px',
    borderBottomRightRadius: '8px 20px',
    border: '2px solid #737578',
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: '100%',
    marginBottom: 15,
  },
  readonly: {
    background: '#EBEBEA !important',
    border: '2px solid #EBEBEA !important',
  },
  inputWithIcon: {
    paddingLeft: '35px !important',
  },
  textAreaStyle: {
    position: 'relative',
    borderRadius: 12,
    border: '2px solid #737578',
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: '100%',
    marginBottom: 15,
  },
  icon: {
    color: '#737578',
    padding: theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    color: '#6e6e71',
    padding: theme.spacing(1.4, 2, 1.4, 2),
    transition: theme.transitions.create('width'),
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
  },
  inputReadonly: {
    color: '#696A6D !important',
  },
  error: {
    border: '2px solid #FF4031',
    margin: '0 0 30px 0',
  },
  errorColor: {
    color: '#FF4031 !important',
  },
  errorMessage: {
    color: '#FF4031 !important',
    fontSize: '14px',
    margin: '0 0 0 5px',
    padding: '2px 0 0 0',
  },
  datePicker: {
    marginLeft: 35,
    marginTop: 5,
    '& > .MuiInputBase-root': {
      fontSize: 14,
      color: '#6e6e71',
      fontWeight: 500,
    },
    '& > .MuiInput-underline::before': {
      borderBottom: 0,
    },
    '& > .MuiInput-underline::after': {
      borderBottom: 0,
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: 0,
    },
  },
}))

const Input = (props, ref) => {
  const classes = useStyles()
  const title = props.title
  const icon = props.icon
  const value = props.value ? props.value : ''
  const readOnly = props.readonly ? props.readonly : false
  const _error = props.error
  const _isDate = props.isDate
  const errorMessage = props.errorMessage
  const isRequired = props.isRequerid

  const [error, setError] = React.useState(null)
  const [errorColor, setErrorColor] = React.useState(null)

  React.useEffect(() => {
    if (_error) {
      setError(classes.error)
      setErrorColor(classes.errorColor)
    } else {
      setError(null)
      setErrorColor(null)
    }
  }, [_error, classes.error, classes.errorColor])

  React.useEffect(() => {
    if (isRequired) {
      if (value === '' || value === null || value.length === 0) {
        setError(classes.error)
        setErrorColor(classes.errorColor)
      }
    }
  }, [classes.error, classes.errorColor, isRequired, value])

  return (
    <div
      className={
        props.type === 'textarea'
          ? readOnly
            ? `${classes.textAreaStyle} ${classes.readonly} ${error}}`
            : `${classes.textAreaStyle} ${error}}`
          : readOnly
          ? `${classes.inputStyle} ${classes.readonly} ${error}`
          : `${classes.inputStyle} ${error}`
      }
    >
      {icon && <div className={`${classes.icon} ${errorColor}`}>{icon}</div>}
      {_isDate ? (
        <DatePicker
          autoOk
          label=""
          format="DD/MM/yyyy"
          clearable
          disableFuture
          value={props.valueDate}
          onChange={props.onChangeDate}
          className={classes.datePicker}
        />
      ) : props.type === 'textarea' ? (
        <InputBase
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          type={props.type}
          name={props.name}
          multiline={true}
          maxRows={4}
          minRows={4}
          value={value}
          placeholder={title}
          required
          readOnly={readOnly}
          classes={{
            root: `${classes.inputRoot} ${errorColor}`,
            input: classes.inputInput,
          }}
          inputProps={{
            'aria-label': title,
            autoComplete: 'new-password',
          }}
        />
      ) : (
        <InputBase
          inputRef={ref}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          type={props.type}
          name={props.name}
          placeholder={title}
          value={value}
          required
          readOnly={readOnly}
          classes={{
            root: `${classes.inputRoot} ${errorColor}`,
            input: icon
              ? readOnly
                ? `${classes.inputInput} ${classes.inputReadonly} ${classes.inputWithIcon}`
                : `${classes.inputInput} ${classes.inputWithIcon}`
              : readOnly
              ? `${classes.inputInput} ${classes.inputReadonly}`
              : `${classes.inputInput}`,
          }}
          inputProps={{
            'aria-label': title,
            autoComplete: 'new-password',
            pattern: props.pattern,
          }}
        />
      )}
      {errorColor && <p className={classes.errorMessage}>{errorMessage}</p>}
    </div>
  )
}

export default forwardRef(Input)
