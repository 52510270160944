import React from 'react'

import css from './Banner.module.css'

export default function Banner({ img, title, minutes, image }) {
  return (
    <div className={css.card}>
      <h5 className={`${css.contentCard} ${css.titleCard}`}>
        {title}
        {(minutes && minutes !== null && parseInt(minutes) !== 0) ? <p style={{margin: 0}}> Tiempo aproximado para la actividad: {minutes} {parseInt(minutes) === 1 ? "hr" : "hrs"} </p> : null}
      </h5>
      {image && (
        <div className={css.cardButton}>
          <img className={css.image} height="250" src={image ? image : img} alt="Persona programando" />
        </div>
      )}
    </div>
  )
}
