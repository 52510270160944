import React from "react";
import useSofia from "../../../../hooks/useSofia";
import css from "./SofiaBot.module.css";

export default function SofiaBot() {
  // States
  const [chatIsOpen, setChatIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [chatMessages, setChatMessages] = React.useState([]);
  const [deletingMessages, setDeletingMessages] = React.useState(false);

  // Refs
  const chatContentRef = React.useRef(null);
  // Custom hooks
  const { botIsWriting, getBotResponse } = useSofia();

  // * Open or close the chat
  const toggleChat = () => {
    setChatIsOpen(!chatIsOpen);
  };

  // * Generate an Id that will match a pair of user and bot messages (userN, botN)
  const generateId = (author) => {
    // Early return if empty array
    if (chatMessages.length === 0) return author.concat(1);
    // Array of messages IDs
    const idArray = chatMessages.map((m) => Number(m.id.replace(m.author, "")));
    // Next ID is current max + 1
    return author.concat(Math.max(...idArray) + 1);
  };

  // * Add message to chatMessages state
  const appendMessage = (message, author, error) => {
    setChatMessages((currentMessages) => [
      ...currentMessages,
      { content: message, author, error, id: generateId(author) },
    ]);
  };

  // Generate a random ID
  const generateRandomId = () => {
    return "platform-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  // Check and set user ID
  const setUserId = () => {
    let userId = localStorage.getItem("userId");
    //console.log(`Retrieved userId from localStorage: ${userId}`);

    if (!userId) {
      userId = generateRandomId();
      localStorage.setItem("userId", userId);
      //console.log(`Generated and set new userId: ${userId}`);
    }
  };

  // * Send and append the userMessage, then receive and append the botResponse
  const createUserInteraction = async (userMessage) => {
    // 1. Reset message state
    setMessage("");
    // 2. Append user message to message list
    appendMessage(userMessage, "user", false);

    const userId = localStorage.getItem("userId");

    // 3. Get bot response
    const [botResponse, error] = await getBotResponse(userMessage, userId);
    // 4.a Append error message if error exists
    if (error || botResponse === null) {
      return appendMessage(
        "Parece que hay un error, vuelve a intentarlo más tarde",
        "bot",
        true
      );
    }
    // 4. Success: append bot response to message list
    appendMessage(botResponse, "bot", false);
  };

  // * Receive userMessage and create an interaction with bot if message is not empty
  const handleMessage = async (e) => {
    e.preventDefault();
    // Early return if empty message is sent
    if (message === "") return;
    // User - Bot interaction
    await createUserInteraction(message);
  };

  // * Scroll to bottom depending on chatContent height
  const scrollToBottom = () => {
    // Scroll to the bottom
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  };

  // * Check if there are stored messages in the localStorage to init state
  React.useEffect(() => {
    // Get storedChatMessages from localStorage
    const storedMessages = localStorage.getItem("storedChatMessages");
    // Initialize chatMessages Stat if there are stored messages in localStorage
    if (storedMessages) {
      const parsedMessages = JSON.parse(storedMessages);
      setChatMessages(parsedMessages);
    }

    // Set user ID
    setUserId();
  }, []);

  // * useEffect hook to scroll to the bottom after messages are updated
  React.useEffect(() => {
    if (chatMessages.length === 0) return;
    // Scroll whenever a message is appended and chatIsOpen is true
    if (chatIsOpen) {
      scrollToBottom();
    }
    // Store message in localStorage
    localStorage.setItem("storedChatMessages", JSON.stringify(chatMessages));
  }, [chatMessages]); // Run whenever chatMessages change

  // * useEffect hook to scroll to the bottom whenever chat is opened
  React.useEffect(() => {
    if (chatIsOpen) {
      scrollToBottom();
    }
  }, [chatIsOpen]);

  // * Delete error messages (bot and user) on demand
  const deleteErrorMessages = async (e, errorMessageId) => {
    e.preventDefault();
    // Disable chatbox input and send button
    setDeletingMessages(true);
    // Get userMessageId
    const userMessageId = errorMessageId.replace("bot", "user");
    const chatWithoutErrors = chatMessages
      .filter((message) => message.id !== errorMessageId)
      .filter((message) => message.id !== userMessageId);
    setDeletingMessages(false);
    // Update state
    setChatMessages(chatWithoutErrors);
  };

  // * Retry sending a message with error
  const retrySendMessage = async (e, errorMessageId) => {
    e.preventDefault();
    // Get userMessageId
    const userMessageId = errorMessageId.replace("bot", "user");
    // Get userMessage corresponding with userMessageId
    const foundUserMessage = chatMessages.find(
      (userMessage) => userMessage.id === userMessageId
    );
    // Resend foundUsermessage content
    if (foundUserMessage !== null) {
      await createUserInteraction(foundUserMessage.content);
    }
  };

  return (
    <>
      {/* ToggleBtn */}
      <button onClick={toggleChat} className={css.sofiaButton}>
        <img
          src="https://ik.imagekit.io/taf6zzl9d/chatbot/sofia.png?updatedAt=1701196091122"
          alt="sofia-profile"
        />
      </button>
      {/* Chatbot */}
      {chatIsOpen && (
        <div className={css.chatContainer}>
          <div className={css.chatHeader}>
            <h6>sofIA</h6>
            <button onClick={toggleChat}>
              <img
                src="https://ik.imagekit.io/taf6zzl9d/chatbot/close.png?updatedAt=1701196924568"
                alt="close-btn-icon"
              />
            </button>
          </div>
          <div ref={chatContentRef} className={css.chatContent}>
            {/* sofIA Intro Message */}
            <div className={css.botReply}>
              <img
                src="https://ik.imagekit.io/taf6zzl9d/chatbot/sofia.png?updatedAt=1701196091122"
                alt="bot-profile"
              />
              <p>
                Soy sofIA, su mentora basada en inteligencia artificial. Estoy
                aquí para simplificar sus desafíos técnicos, desde resolver
                problemas de código hasta ofrecer orientación en proyectos
                tecnológicos.
              </p>
            </div>
            <div className={css.botReply}>
              <img
                src="https://ik.imagekit.io/taf6zzl9d/chatbot/sofia.png?updatedAt=1701196091122"
                alt="bot-profile"
              />
              <p>
                Ya seas principiante o experto, estoy aquí para responder tus
                preguntas y ayudarte en cada paso.
              </p>
            </div>
            {/* Conditional lodaing */}
            {botIsWriting && (
              <div className={css.botLoading}>
                <img
                  src="https://ik.imagekit.io/taf6zzl9d/chatbot/sofia.png?updatedAt=1701196091122"
                  alt="bot-profile"
                />
                {/* Loading Bubble */}
                <div className={css.loadingBubble}>
                  <div className={css.firstDot}></div>
                  <div className={css.middleDot}></div>
                  <div className={css.lastDot}></div>
                </div>
              </div>
            )}
            {/* Dynamic messages: User and Bot */}
            {chatMessages.map((chatMessage) => {
              {
                /* User Message */
              }
              if (chatMessage.author === "user") {
                return (
                  <div key={chatMessage.id} className={css.userMessage}>
                    {chatMessage.content}
                  </div>
                );
                {
                  /* Bot Response */
                }
              } else if (!chatMessage.error) {
                return (
                  <div key={chatMessage.id} className={css.botReply}>
                    <img
                      src="https://ik.imagekit.io/taf6zzl9d/chatbot/sofia.png?updatedAt=1701196091122"
                      alt="bot-profile"
                    />
                    <p>{chatMessage.content}</p>
                  </div>
                );
                {
                  /* Bot Error */
                }
              } else {
                return (
                  <div key={chatMessage.id} className={css.botError}>
                    <img
                      src="https://ik.imagekit.io/taf6zzl9d/chatbot/sofia.png?updatedAt=1701196091122"
                      alt="bot-profile"
                    />
                    <div className={css.errorBubble}>
                      <p>{chatMessage.content}</p>
                      <div className={css.errorActions}>
                        <button
                          className={css.retryButton}
                          onClick={(e) => retrySendMessage(e, chatMessage.id)}
                        >
                          Reintentar
                        </button>
                        <button
                          className={css.deleteButton}
                          onClick={(e) =>
                            deleteErrorMessages(e, chatMessage.id)
                          }
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {/* Chatbox */}
          <form onSubmit={handleMessage} className={css.chatBox}>
            <input
              disabled={botIsWriting || deletingMessages}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              name="message"
              placeholder="Escribe..."
            />
            <button
              disabled={message === "" || deletingMessages}
              className={css.sendButton}
              type="submit"
            >
              <img
                src="https://ik.imagekit.io/taf6zzl9d/chatbot/chevron.png?updatedAt=1701195945915"
                alt="chevron"
              />
            </button>
          </form>
        </div>
      )}
    </>
  );
}
