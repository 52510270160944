import React from "react";

import { useHistory, useParams } from "react-router-dom";
import Swal from 'sweetalert2'

import Header from "../../components/modules/dashboard/partials/Header";
import ResourceFrom from "../../components/modules/dashboard/resources/ResourceForm";
import { useProfile } from "../../context/MainContext";
import { showResource, updateResource } from "../../service/module/Challenges";

import css from "./EditResource.module.css";

export default function EditResource() {
  const { id, idSprint, idResource, modalityId, idChild } = useParams();
  const [message, setMessage] = React.useState();
  const [resource, setResource] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const { userType } = useProfile();
  const history = useHistory();
  const [resourceTemp, setResourceTemp] =  React.useState({})
  const [changed, setChanged] = React.useState(true)

  React.useEffect(() => {
    async function getResource(id) {
      await showResource(id)
        .then((response) => {
          const resourceFilled = response.data;
          setResource({
            ...resourceFilled,
          });

          setResourceTemp({
            ...resourceFilled
          })
        })
        .catch(() => {});
    }

    getResource(idResource);
  }, [idResource]);
  
  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  React.useEffect(() => { 
    isEqual(resource, resourceTemp)
  }, [resource, resourceTemp])

  function isEqual(resource, resourceTemp){
   if(resource.title !== resourceTemp.title){
    setChanged(false)
   }else if(resource.description !== resourceTemp.description){
    setChanged(false)
   } else if(resource.thumbnail_url !== resourceTemp.thumbnail_url){
    setChanged(false)
   }else if(resource.url !== resourceTemp.url){
    setChanged(false)
   }else if(resource.number !== resourceTemp.number){
    setChanged(false)
   }else if(resource.type !== resourceTemp.type){
    setChanged(false)
   }else if(resource.time !== resourceTemp.time){
    setChanged(false)
   }else{
    setChanged(true)
   }
  }


  async function update() {
    await updateResource(idResource, resource)
      .then((response) => {
        swalCustom.fire({
          title: 'Recurso actualizado exitosamente',
          icon: 'success',
          showDenyButton: true,
          showConfirmButton: true,
          denyButtonText: 'Seguir editando',
          confirmButtonText: 'Regresar al sprint',
        }).then(result => {
          if (!idChild) {
            if (result.isConfirmed) {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
            }
            if (result.isDenied) {
              setResourceTemp(resource)
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/${idResource}/edit/${modalityId}`)
            }
          }

          if (idChild) {
            if (result.isConfirmed) {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${idChild}/edit`)
            }
            if (result.isDenied) {
              setResourceTemp(resource)
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${idChild}/resource/${idResource}/edit`)
            }
          }
        })
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: error.errors,
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(result => {
          if (idChild) {
            history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
          }

          if (!idChild) {
            history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
          }
        })
      });
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function changeInput(e) {
    if (e.target.type === 'number') {
      if (e.target.value < 0) {
        errors[e.target.name] = 'El valor no puede ser negativo.'
        e.target.value = 0
        
        sleep(5000).then(() => {
          errors[e.target.name] = null
          // Set e.target.value to null to avoid the error message to be shown but mantain the other error messages
          setErrors({...errors, [e.target.name]: null})
        })
      }

      e.target.value = Math.round(e.target.value)
    }

    setResource({
      ...resource,
      [e.target.name]: e.target.value,
    });
  }

  const goBack = () => {
    if (idChild) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${idChild}/edit`)
    }

    if (!idChild) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
    }
  }

  return (
    <div>
      <Header />
      {(userType === "operative" || userType === "manager") && (
        <ResourceFrom
          title="Editar Recurso"
          message={message}
          resource={resource}
          changeInput={changeInput}
          errors={errors}
          update={update}
          changed={changed}
          goBack={goBack}
        />
      )}
    </div>
  );
}
