import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Alert } from 'reactstrap'
import Swal from 'sweetalert2'

import { useProfile } from '../../../../context/MainContext'

import { deleteSprintChallenge } from '../../../../service/module/Challenges'
import { getSprintsChallenge, updateSprintOrder } from '../../../../service/module/Challenges'

import Button from '../partials/Button'
import { Modal } from "../../../../components"
import { AssignSprintModality } from '../../../../forms'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import checkIcon from '../../../../assets/Check.png'

import css from './ChallengesForm.module.css'

export default function ChallengesForm({
  message,
  title,
  changeInput,
  register,
  errors,
  challenge,
  update,
  list,
  id,
  changed,
  setList,
  currentPage,
}) {

  const { userId } = useProfile()
  const history = useHistory()
  const location = useLocation()
  const dragItem = useRef()
  const dragOverItem = useRef()
  const [listSprints, setListSprints] = React.useState()

  const [messageSprint, setMessageSprint] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const [notification, setNotification] = React.useState()
  const [isSprintDeleted, setIsSprintDeleted] = React.useState()
  const [showModalityModal, setShowModalityModal] = React.useState(false)

  React.useEffect(() => {
    if (location.message) {
      setMessageSprint(location.message.message)
      setColorMessage('success')
      setNotification('Éxito')
    }
  }, [location])

  React.useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        setMessageSprint('')
        setColorMessage('')
        setNotification('')
      }, 5000)
    }
  }, [messageSprint, colorMessage, notification])

  async function getSprints(current) {

    await getSprintsChallenge(id, current)
      .then(response => {
        setList(response.data.sort((a, b) => a.challenge_order - b.challenge_order))

      })
      .catch(() => { })

  }

  React.useEffect(() => {
    if (id) {
      getSprints(currentPage)
    }
  }, [isSprintDeleted, currentPage])


  function add(modality) {
    history.push(`/dashboard/challenges/${id}/sprint/add?modality=${modality}`)
  }

  function editSprintAction(sprintId) {
    history.push(`/dashboard/challenges/${id}/sprint/${sprintId}/edit`)
  }

  async function deleteSprint(sprintId) {
    await deleteSprintChallenge(sprintId, { "created_by": userId })
      .then(response => {
        setMessageSprint('Sprint eliminado correctamente')
        setColorMessage('success')
        setNotification('Éxito')
        setIsSprintDeleted(!isSprintDeleted)
      })
      .catch(error => {
        setMessageSprint(error.message)
        setColorMessage('danger')
        setNotification('Error')
      })
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
      icon: css.warningIcon,
      icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id) {
    swalCustom.fire({
      title: '¿Estás seguro que deseas quitar este sprint?',
      iconHtml: '<div className="' + css.warningIcon + '"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Quitar sprint',
    }).then(_result => {
      if (_result.isDenied) {
        deleteSprint(id)
      }
    })
  }

  const dragStart = (e, position) => {
    dragItem.current = position
  }

  const dragEnter = (e, position) => {
    dragOverItem.current = position
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const drop = e => {
    const copyListItems = [...list]
    const dragItemContent = copyListItems[dragItem.current]
    copyListItems.splice(dragItem.current, 1)
    copyListItems.splice(dragOverItem.current, 0, dragItemContent)
    dragItem.current = null
    dragOverItem.current = null
    setListSprints(copyListItems)

    let sprintsData = []
    copyListItems.map((sprint, index) => {
      let challengeObject = {
        id: sprint.id,
        challenge_order: index + 1,
      }
      sprintsData.push(challengeObject)
    })

    let da = {
      id: challenge.id,
      challenges: sprintsData
    }

    updateSprintOrder(da).then(response => {
      if (response.success) {
        getSprints(currentPage)
        setMessageSprint(' Orden actualizado correctamente')
        setColorMessage('success')
        setNotification('Éxito')
      }
      if (!response.success) {
        setMessageSprint('Ha ocurrido un error')
        setColorMessage('danger')
        setNotification('Error')
      }

      sleep(3000).then(() => {
        setMessageSprint('')
        setColorMessage('')
        setNotification('')
      })
    })
  }

  return (
    <>
      <div className={css.root}>
        {messageSprint && colorMessage && (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt="" /><strong>¡{notification}!</strong>{messageSprint}
          </Alert>
        )}
        {message && typeof message === 'object' ? (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt="" /><strong>{message.message}</strong>
          </Alert>
        ) : message ? (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt="" /><strong>{message}</strong>
          </Alert>
        ) : null}
        <div className={classNames(css.tableContainer, css.darkTableContainer)}>
          <div className={css.tableHeader}>
            <h3 className={css.title}>{title}</h3>
          </div>
          <form className={css.form}>
            <div className={css.inputs}>
              <div className={css.inputHolder}>
                <label className="form-control-label" htmlFor="input-name">
                  Nombre
                </label>
                <input
                  invalid={errors && errors.name ? true : false}
                  onChange={changeInput}
                  name="name"
                  id="input-name"
                  defaultValue={challenge && challenge.name}
                  className={css.input}
                  type="text"
                />
                {errors && errors.name && <div className={css.feedback}>{errors.name}</div>}
              </div>

              <div className={css.inputHolder}>
                <label className="form-control-label" htmlFor="input-name">
                  Imagen
                </label>
                <input
                  invalid={errors && errors.url_image ? true : false}
                  onChange={changeInput}
                  name="url_image"
                  id="input-image"
                  defaultValue={challenge && challenge.url_image !== null ? challenge.url_image : 'https://concepto.de/wp-content/uploads/2018/09/lenguaje-de-programación-e1537466880796-800x400.jpg'}
                  className={css.input}
                  type="text"
                  placeholder="https://example.com"
                />
                {errors && errors.url_image && <div className={css.feedback}>{errors.url_image}</div>}
              </div>

            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-email">
                Competencia
              </label>
              <textarea
                name="description"
                onChange={changeInput}
                defaultValue={challenge && challenge.description ? challenge.description : ''}
                className={classNames(css.input, css.textarea)}
                placeholder="..."
                rows="4"
              />
            </div>
            {list && (
              <div className={css.sprints}>
                <div className={css.tableContainer}>
                  <div className={css.tableHeader}>
                    <h3 className={css.title}>
                      Sprints
                      <span className={css.small}>{list?.length || 0}/10</span>
                    </h3>
                    <Button
                      className={css.addButton}
                      type="button"
                      href="/dashboard/users/add"
                      onClick={() => setShowModalityModal(true)}
                      disabled={list && list.length >= 10 ? true : false}
                      title={list && list.length >= 10 ? "No puedes agregar más de 10 sprints" : "Agregar sprint"}
                    >
                      Agregar
                    </Button>
                  </div>
                  <div className={css.table}>
                    <table className={css.table}>
                      <thead className={css.thead}>
                        <tr className={css.tr}>
                          <th scope="col" className={css.th}>
                            Mover
                          </th>
                          <th className={css.th} scope="col">
                            Nombre
                          </th>
                          <th className={css.th} scope="col">
                            Modalidad
                          </th>
                          <th className={css.th} scope="col">
                            Número
                          </th>
                          <th className={css.th} scope="col">
                            Acciones
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list &&
                          list.map((item, key) => {
                            return (
                              <tr
                                className={css.tr}
                                key={key}
                                draggable
                                onDragStart={e => dragStart(e, key)}
                                onDragEnter={e => dragEnter(e, key)}
                                onDragEnd={drop}
                              >
                                <td className={classNames(css.td)}>
                                  <Button isicon={false} className={css.order}>
                                    <span>
                                      <FontAwesomeIcon icon={faBars} className={css.order} />
                                    </span>
                                  </Button>
                                </td>
                                <th className={classNames(css.td, css.name)} scope="row">
                                  {item.name}
                                </th>
                                <td className={classNames(css.td, css.modailities)}>
                                  {item?.modalities?.length > 0 && item?.modalities?.reduce((ac, n) => {
                                    if (!ac.some((item) => item.modality === n.modality)) {
                                      ac.push(n)
                                    }
                                    return ac
                                  }, [])?.map((item, key) => {
                                    return (
                                      <div className={css.tag} key={key}>
                                        <span className={css.tagText}>{item.modality}</span>
                                      </div>
                                    )
                                  })}
                                </td>
                                <td className={css.td}>{item.challenge_order}</td>
                                <td className={css.td}>
                                  <Button
                                    isicon={true}
                                    className={css.edit}
                                    type="button"
                                    title="Editar"
                                    onClick={() => editSprintAction(item.id)}
                                  >
                                    <span className="btn-inner--icon mr-0">
                                      <FontAwesomeIcon icon={faEdit} />
                                    </span>
                                  </Button>

                                  <Button isicon={true} className={css.delete} type="button" tittle="Quitar" onClick={() => toggleModal(item.id)}>
                                    <span>
                                      <FontAwesomeIcon icon={faXmark} />
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className={css.tableFooter}>
                    {list && list.length === 0 ? (
                      <div className={css.noData}>
                        <p>Sin sprints</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </form>
          <div className={css.tableFooter}>
            <nav aria-label="...">
              <div className={css.pagination}>
                <Button
                  className={css.delete}
                  type="button"
                  onClick={() => {
                    history.push('/dashboard/challenges')
                  }}
                >
                  Cancelar
                </Button>
                {register && (
                  <Button type="button" onClick={register}>
                    Registrar
                  </Button>
                )}
                {update && (
                  <Button type="button" onClick={update} disabled={changed}>
                    Actualizar
                  </Button>
                )}
              </div>
            </nav>
          </div>
        </div>
        <Modal
          className={css.modal}
          show={showModalityModal}
          onClose={() => setShowModalityModal(false)}
        >
          <p className={css.modalTitle}>
            ¿A qué modalidad corresponde este contenido?
          </p>
          <AssignSprintModality
            onSubmit={(e) => {
              e.preventDefault()
              setShowModalityModal(false)
              add(e.target.modality.value)
            }}
            onCancel={() => setShowModalityModal(false)}
          />
        </Modal>
      </div>
    </>
  )
}
