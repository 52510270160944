import React from 'react'

import { useHistory } from 'react-router-dom'

import {
  Drawer,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormControlLabel,
  withStyles,
  useTheme,
} from '@material-ui/core'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle'
import CircleIcon from '@material-ui/icons/Brightness1'
import { useProfile } from '../../../../context/MainContext'

import css from './AsideDrawer.module.css'

const NaoAccordion = withStyles({
  root: {
    marginLeft: 40,
    backgroundColor: '#F6F6F6',
    borderLeft: '1px solid #000',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      marginLeft: 40,
    },
  },
  expanded: {},
})(Accordion)

const NaoAccordionSummary = withStyles({
  root: {
    backgroundColor: '#F6F6F6',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 30,
    '&$expanded': {
      minHeight: 30,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(AccordionSummary)

const NaoAccordionDetails = withStyles(() => ({
  root: {
    backgroundColor: '#F6F6F6',
    padding: '0px 16px',
  },
}))(AccordionDetails)

const NaoControlLabel = withStyles({
  root: {
    marginLeft: -37,
  },
  label: {
    fontWeight: 700,
    color: '#000',
  },
})(FormControlLabel)

const NaoRadio = withStyles({
  root: {
    '&>.MuiIconButton-label': {
      background: '#F6F6F6',
      color: '#b9b9b9',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&+span': {
      color: '#000 !important',
    },
  },
  checked: {
    '&>.MuiIconButton-label': {
      background: '#F6F6F6',
      color: '#004DFF !important',
    },
    '&+span': {
      color: '#004DFF !important',
    },
  },
})(Radio)

function ListItemLink(props) {
  const isCurrent = props.isCurrent
  return (
    <button {...props} className={isCurrent ? `${css.listItem} ${css.current}` : css.listItem}>
      {props.children}
    </button>
  )
}

export default function AsideDrawer({
  sprints,
  currentSprint,
  challengesComplete,
  challenge,
  saveAsideSprint
}) {

  const history = useHistory()
  const [isMobile, setIsMobile] = React.useState(false)
  const [container, setContainer] = React.useState(undefined)
  const theme = useTheme()
  const { handleDrawerToggle, mobileOpen } = useProfile()

  React.useEffect(() => {
    if (window !== undefined && isMobile) {
      setContainer(() => window.document.body)
    } else {
      setContainer(undefined)
    }
  }, [isMobile])

  function changeWindow() {
    if (window.innerWidth < 960) {
      setIsMobile(true)
    }
    if (window.innerWidth >= 960) {
      setIsMobile(false)
    }
  }

  React.useEffect(() => {
    document.getElementById('root').classList.add('withoutHand')
    // add event listener for width change in window
    window.addEventListener('resize', changeWindow)
    
    changeWindow()

  }, [])

  function getType(current) {
    const currentSprintIsChild = current?.modality_content[0]?.children?.find(
      item => item.id === currentSprint?.id
    )

    if (
      currentSprint &&
      (current.id === currentSprint.id || current.id === currentSprint.challenge_parent_id ||currentSprintIsChild)
    ) {
      return <NaoRadio checked={true} color="default" disableRipple disabled checkedIcon={<CircleIcon />}/>
    }
    if (challengesComplete) {
      const filter = challengesComplete.filter(item => item?.id === current.id || item?.challenge_id === current.id)
      if (filter.length > 0) {
        return (
          <NaoRadio
            checked={true}
            color="default"
            disableRipple
            disabled
            checkedIcon={<CheckCircleOutlineIcon />}
          />
        )
      }
    }

    if (challengesComplete && current?.modality_content[0]?.children !== undefined) {
      const filter = challengesComplete.filter(item => {
        return item?.challenge_id === current.id
      })
      if (filter.length === current?.modality_content[0]?.children.length) {
        return (
          <NaoRadio
            checked={true}
            color="default"
            disableRipple
            disabled
            checkedIcon={<CheckCircleOutlineIcon />}
          />
        )
      }
    }

    return <NaoRadio checked={false} color="default" disableRipple disabled />
  }
  const drawerProps = isMobile
    ? {
        container,
        variant: 'temporary',
        anchor: theme.direction === 'rtl' ? 'right' : 'left',
        open: mobileOpen,
        onClose: handleDrawerToggle,
      }
    : {
        variant: 'permanent',
      }

  const getLabelMinutes = sprint => {

    if (sprint.minutes === 0 || sprint.minutes === null) {
      return <span className={css.label}>{sprint.name || sprint.tittle_modality}</span>
    }

    return <><span className={css.label}>{sprint.name || sprint.tittle_modality}</span><br/><span className={css.minutes}>{sprint.minutes} hrs</span></>
  }

  return (
    <>
      <Drawer
        className={css.drawer}
        classes={{
          paper: css.drawerPaper,
        }}
        {...drawerProps}
      >
        <div className={css.container}>
          <h6 className={css.title}>
            <IconButton
              className={css.backButton}
              color="inherit"
              onClick={() => history.push('/challenges')}
            >
              <ArrowBackIcon />
            </IconButton>
            <span className={css.challenge}>{challenge && challenge.name}</span>
          </h6>

          {sprints && currentSprint &&
            sprints.map((sprint, key) => {
              return sprint?.modality_content[0]?.children === undefined ? (
                <NaoAccordion key={key}>
                  <NaoAccordionSummary aria-controls="panel2a-content" id="panel1a-header">
                    {sprint.content_type !== 'release' ? (
                      <NaoControlLabel
                        aria-label="Acknowledge"
                        className={currentSprint && sprint.id === currentSprint.id && css.current}
                        onClick={() => {
                          saveAsideSprint(sprint)
                        }}
                        control={getType(sprint)}
                        label={getLabelMinutes(sprint)}
                      />
                    ) : (
                      <NaoControlLabel
                        aria-label="Acknowledge"
                        className={currentSprint && sprint.id === currentSprint.id && css.current}
                        onClick={() => {
                          saveAsideSprint(sprint)
                        }}
                        control={getType(sprint)}
                        label={`${sprint.name}`}
                      />
                    )}
                  </NaoAccordionSummary>
                </NaoAccordion>
              ) : (
                <NaoAccordion 
                  key={key}
                  defaultExpanded={sprint?.modality_content[0]?.children?.find(
                    item => item.id === currentSprint?.id
                  )}
                >
                  <NaoAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a_content"
                    id="panel1a-header"
                  >
                    <NaoControlLabel
                      aria-label="Acknowledge"
                      control={getType(sprint)}
                      label={sprint.name}
                    />
                  </NaoAccordionSummary>
                  <NaoAccordionDetails style = {{background:'#F6F6F6'}}>
                    <nav aria-label="secondary mailbox folders" className={css.list}>
                      {sprint?.modality_content[0]?.children &&
                        sprint?.modality_content[0]?.children.map((child, sprintKey) => {
                          return (
                            <ListItemLink
                              key={sprintKey}
                              href="#"
                              isCurrent={currentSprint && child.id === currentSprint.id}
                              onClick={e => {
                                e.preventDefault()
                                saveAsideSprint(child)
                              }}
                              
                            >
                              <span className={css.text}>{getLabelMinutes(child)}</span>
                            </ListItemLink>
                          )
                        })}
                    </nav>
                  </NaoAccordionDetails>
                </NaoAccordion>
              )
            })}
        </div>
      </Drawer>
    </>
  )
}
