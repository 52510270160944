import React from 'react'

import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import Footer from '../../components/modules/main/partials/Footer'
import Banner from '../../components/modules/main/sprints-page/Banner'
import { getMission } from '../../service/module/Missions'
import { useProfile } from '../../context/MainContext'
import { getGroupBy } from '../../service/module/Groups'
import { showUser } from '../../service/module/Users'
import { getSurvey, getUsersWhole, setSurvey } from '../../service/module/Users'
import Team from '../../components/modules/main/mission-detail-page/Team'
import Discord from '../../components/modules/main/partials/Discord'
import Analisis from '../../assets/analisis.png'
import Investigacion from '../../assets/investigacion.png'
import Desarrollo from '../../assets/desarrollo.png'
import Publicacion from '../../assets/publicacion.png'
import Retro from '../../assets/retro.png'
import Button from '../../components/modules/main/partials/Button'

import css from './Introduction.module.css'

export default function Introduction() {
  const [mission, setMission] = React.useState()
  const [users, setUsers] = React.useState([])
  const [user, setUser] = React.useState()
  const [group, setGroup] = React.useState()
  const [groupId, setGroupId] = React.useState()
  const [team, setTeam] = React.useState()
  const [surveyIsVisible, setSurveyIsVisible] = React.useState(false)
  const [changeSurvey, setChangeSurvey] = React.useState(false)
  //const [currentChallenge, setCurrentChallenge] = React.useState()

  const { userId } = useProfile()

  React.useEffect(() => {
    async function getIntro() {
      await getMission(1)
        .then(response => {
          setMission(response.data[0])
        })
        .catch(() => { })

      await showUser(userId)
        .then(response => {
          setUser(response.data[0])
          setGroupId(response.data[0].profile.group_id)
        })
        .catch(() => { })
    }

    if (userId) {
      getIntro()
    }
  }, [userId])

  const getGroups = async () => {
    await getGroupBy(groupId)
      .then(response => {
        setGroup(response.data[0])
      })
      .catch(() => { })
  }

  React.useEffect(() => {
    if (groupId !== undefined) {
      getGroups()
    }

  }, [groupId])

  React.useEffect(() => {
    async function updateView() {
      await getSurvey(0)
        .then(response => {
          setSurveyIsVisible(!response.success)
        })
        .catch(() => { })
    }

    updateView()
  }, [changeSurvey])

  async function saveSurvey(response) {
    const data = {
      response: response,
      is_intro: true,
      challenge_id: 0,
    }

    await setSurvey(data)
      .then(response => {
        if (response.success) {
          setChangeSurvey(!changeSurvey)
        }
      })
      .catch(() => { })
  }

  return (
    <div className={css.root}>
      <div className={css.card}>
        <h5 className={`${css.contentCard} ${css.titleCard}`}>
          Presentación
        </h5>
        <div className={css.cardButton}>
          <img className={css.imageBanner} height="250" src={"https://drive.google.com/uc?export=download&id=1ukOYHr9dN-omstIZRCISgqOHruqbfvz0"} alt="Persona programando" />
        </div>
      </div>
      <h5 className={css.description}>
        Te invitamos a conocer Digital Nao, donde tendrás la oportunidad de impulsar tu perfil en Tecnologías de la Información de una forma diferente. Di adiós a las prácticas repetitivas y prepárate para iniciar una experiencia de aprendizaje que te ayudará a consolidar tus competencias, afianzar tu perfil profesional y acceder a las vacantes más hot del mercado laboral. En Digital Nao tendrás la oportunidad de resolver retos, conformar un portafolio de evidencias y, al final, realizarás una presentación con la que se evaluará tu desarrollo competencial.
        <br />
        <br />
        <b>ENFOQUE:</b> Aprendizaje basado en proyectos (POD).
        <br />
        <b>MODALIDAD:</b> Online.
        <br />
        <b>ACTIVIDADES:</b> 80% asincrónicas y 20% sincrónicas.
      </h5>
      <Discord />
      <h5 className={css.learnMoreTitle}>
        Explora la estructura de los RETOS
      </h5>
      <p
        className={css.theChallengeDescription}
        align="justify"
      >
        Cada Reto representa una experiencia de aprendizaje basada en la metodología orientada a proyectos, por lo tanto promueve en ti, como aprendedor, procesos reflexivos para resolver una problemática que se plantea en  el mismo, es decir, se centra en la contextualización del reto a resolver, esto quiere decir que cada problemática  a la que buscarás una solución responde a situaciones que se presentan en el ámbito laboral, por lo tanto, estarás inmerso en proceso de aprendizaje que te hará pensar en soluciones a problemas reales. Cada reto se conforma de cuatro segmentos. Notarás que el primero y el último son fijos, pero los dos centrales conforman ciclos ID (Investiga-Desarrolla) que se repiten a lo largo de la experiencia de aprendizaje. ¿Todo listo? Ahora puedes comenzar a explorar la estructura de un reto.
      </p>
      <div className={css.evidenceContainer}>
        <div className={css.container}>
          <div className={css.imageContainer}>
            <img className={css.image} src={Analisis} alt="Personaje reflexivo" />
          </div>
          <div className={css.textContainer}>
            <h5 className={css.theSubTitle}>
              Primer contacto
            </h5>
            <h5
              className={css.getMindDescription}
              align="justify"
            >
              Representa el inicio de tu desafío. Además de información relevante, aquí encontrarás las herramientas necesarias para comenzar a crear una solución.
            </h5>
          </div>
        </div>
        <div className={css.reverse}>
          <div className={css.textContainer}>
            <h5 className={css.theSubTitle}>
              Investiga
            </h5>
            <h5
              className={css.getMindDescription}
              align="justify"
            >
              Esta parte representa para ti un momento de descubrimiento.
              Aquí entrarás en contacto con los temas a investigar y podrás consultar los materiales que han sido curados por un experto para guiarte en la elaboración de una estrategia que abone a la construcción de tu proyecto final.
            </h5>
          </div>
          <div className={css.imageContainer}>
            <img
              className={css.image}
              src={Investigacion}
              alt="Cotorro investigando por télefono"
            />
          </div>
        </div>
        <div className={css.container}>
          <div className={css.imageContainer}>
            <img
              className={css.image}
              src={Desarrollo}
              alt="Samurai en movimiento"
            />
          </div>
          <div className={css.textContainer}>
            <h5 className={css.theSubTitle}>
              Desarrolla
            </h5>
            <h5
              className={css.getMindDescription}
              align="justify"
            >
              En esta parte entrarán en juego tus habilidades para el análisis y la investigación que, en conjunto con el trabajo realizado previamente, serán clave para avanzar en tu propuesta de aprendizaje.
            </h5>
          </div>
        </div>
        <div className={css.reverse}>
          <div className={css.textContainer}>
            <h5 className={css.theSubTitle}>
              Show On
            </h5>
            <h5
              className={css.getMindDescription}
              align="justify"
            >
              ¡Este será tu momento para sobresalir! Al concluir tu experiencia de aprendizaje debes exponer los resultados de tu proyecto final, describiendo cada parte del proceso. De ahí la necesidad de compartir un portafolio de evidencias en Notion que debes comenzar a crear desde el  PRIMER CONTACTO, como elemento de referencia que te ayudará a guiar y fundamentar tu presentación.
            </h5>
          </div>
          <div className={css.imageContainer}>
            <img
              className={css.image}
              src={Publicacion}
              alt="Elementos flotando sobre mano"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
