import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert } from 'reactstrap'

import Button from '../partials/Button'

import css from './AssignUserForm.module.css'
import classNames from 'classnames'

export default function AssignUserForm({
  assign,
  errors,
  changeInput,
  title,
  user,
  roles,
  groups,
  message,
  modalites
}) {
  const history = useHistory()

  const [roleName, setRoleName] = React.useState(null)
  const [roleSeniority, setRoleSeniority] = React.useState(null)
  const [modalityName, setModalityName ] = React.useState(null)
  const [hasChanged, setHasChanged] = React.useState(false)

  useEffect(() => {
    if (roleName && roleSeniority && hasChanged) {
      const role = roles.find(item => item.name === roleName && item.seniority === roleSeniority)
      if (role) {
        changeInput({
          target: {
            name: 'role_id',
            value: role.id
          }
        })
        setHasChanged(false)
      }
    }
  }, [roleName, roleSeniority])

  useEffect(() => {
    if (user && user.role_id) {
      const role = roles?.find(item => item.id === user.role_id)
      if (role) {
        setRoleName(role.name)
        setRoleSeniority(role.seniority)
      }
    }

    if(user && user.group_id){
      const group = groups?.find(item => item.id === parseInt(user.group_id))
      if(group) { 
        setModalityName(group.modality)
      }
    }
  }, [user, groups])
  
  return (
    <div className={css.root}>
      {message && typeof message === 'object' ? (
        <Alert color="danger">
          <strong>¡Ocurrio un error! |</strong>
        </Alert>
      ) : message ? (
        <Alert color="success">
          <strong>{'¡Usuario asignado con éxito!'}</strong>
        </Alert>
      ) : null}
      <div className={classNames(css.tableContainer, css.darkTableContainer)}>
        <div className={css.tableHeader}>
          <h3 className={css.title}>{title}</h3>
        </div>
        <form className={css.form}>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-name">
                Usuario
              </label>
              <input
                invalid={errors && errors.name ? true : false}
                onChange={changeInput}
                name="name"
                id="input-name"
                defaultValue={user && user.names ? `${user.sur_name}` : ''}
                className={css.input}
                type="text"
                readOnly
              />
              {errors && errors.name && <div className={css.feedback}>{errors.name}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-email">
                Email
              </label>
              <input
                invalid={errors && errors.email ? true : false}
                onChange={changeInput}
                name="email"
                id="input-email"
                defaultValue={user && user.email ? user.email : ''}
                className={css.input}
                placeholder="example@example.com"
                type="text"
                readOnly
              />
              {errors && errors.email && <div className={css.feedback}>{errors.email}</div>}
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-user-role-name">
                Perfil
              </label>
              <select
                invalid={errors && errors.role_id ? true : false}
                name="role_name"
                onChange={(e) => {
                  setHasChanged(true)
                  setRoleName(e.target.value)
                  setRoleSeniority("")
                }}
                type="select"
                defaultValue={(user && user.role_id) || ''}
                id="input-role-name"
                className={css.input}
              >
                <option value="" hidden>
                  Selecciona un perfil
                </option>
                {roles &&
                  roles.map((item, key) => {
                    return (
                      <option value={item.name} key={key} selected={roleName === item.name}>
                        {item.name}
                      </option>
                    )
                  })}
              </select>
              {errors && errors.role_id && <div className={css.feedback}>{errors.role_id}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-user-role-seniority">
                Seniority
              </label>
              <select
                invalid={errors && errors.role_id ? true : false}
                name="role_seniority"
                onChange={(e) => {
                  setHasChanged(true)
                  setRoleSeniority(e.target.value)
                }}
                type="select"
                defaultValue={(user && user.role_id) || ''}
                id="input-role-seniority"
                className={css.input}
              >
                <option value="" hidden>
                  Selecciona un seniority
                </option>
                {roleName &&
                  roles &&
                  roles.map((item, key) => {
                    if (item.name !== roleName) {
                      return null
                    }
                    return (
                      <option
                        value={item.seniority}
                        key={key}
                        selected={roleSeniority === item.seniority}
                      >
                        {item.seniority}
                      </option>
                    )
                  })}
              </select>
              {errors && errors.role_id && <div className={css.feedback}>{errors.role_id}</div>}
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-group_id">
                Grupo
              </label>
              <select
                name="group_id"
                onChange={changeInput}
                defaultValue={(user && user.group_id) || ''}
                id="input-group-id"
                className={css.input}
                type="select"
              >
                <option value="" hidden>
                  Selecciona un grupo
                </option>
                {groups &&
                  groups.map((item, key) => {
                    if (item.role_id !== parseInt(user.role_id)) {
                      return null
                    }
                      return (
                        <option value={item.id} key={key} selected={user.group_id === item.id}>
                          {item.group_name}
                        </option>
                      )
                  })}
              </select>
              {errors && errors.user_group && (
                <div className={css.feedback}>{errors.user_group}</div>
              )}
            </div>

            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-users-count">
                Cantidad de Usuarios
              </label>
              <input
                invalid={errors && errors.users_count ? true : false}
                name="users_count"
                id="input-users-count"
                value={
                  user && groups && user.group_id
                    ? groups.find((group) => {
                        return parseInt(group.id) === parseInt(user.group_id)
                      }).members
                    : '0'
                }
                className={classNames(css.input)}
                type="number"
                min="0"
                readOnly
              />
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-users-count">
                Modalidad
              </label>
              <input
                name="modality_name"
                id="input-modality-name"
                value={modalityName ? modalityName : ''}
                className={classNames(css.input)}
                readOnly
              />
            </div>
          </div>
        </form>
        <div className={css.tableFooter}>
          <nav aria-label="...">
            <div className={css.pagination}>
              <Button
                className={css.delete}
                type="button"
                onClick={() => {
                  history.push('/dashboard/users')
                }}
              >
                Cancelar
              </Button>
              {assign && (
                <Button color="buttonColor" type="button" onClick={assign}>
                  Asignar
                </Button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}