import React from 'react'

import Header from '../../components/modules/dashboard/partials/Header'
import TeamForm from '../../components/modules/dashboard/teams/TeamForm'

import { getAllMissions } from '../../service/module/Missions'
import { getUsersFor } from '../../service/module/Users'
import { storeTeam } from '../../service/module/Teams'
import { useProfile } from '../../context/MainContext'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function AddTeam() {
  const [errors, setErrors] = React.useState()
  const [team, setTeam] = React.useState({})
  const [operatives, setOperatives] = React.useState()
  const [learners, setLearners] = React.useState()
  const [roles, setRoles] = React.useState()
  const [usersSelected, setUsersSelected] = React.useState([])
  const { userType, userId } = useProfile()
  const history = useHistory()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    async function getMissionsAll() {
      await getAllMissions()
        .then(response => {
          const index = 1
          const mission = response.find(item => `${item.id}` === `${index}`)
          setRoles(mission.roles)
        })
        .catch()

      await getUsersFor('operative')
        .then(response => {
          setOperatives(response.data)
        })
        .catch()

      await getUsersFor('learner')
        .then(response => {
          let learnersList = []
          learnersList.push({ value: '-1', label: 'Selecciona un aprendedor' })
          response.data.forEach(item => {
            learnersList.push({ value: item.id, label: item.full_name })
          })
          learnersList.sort((a, b) => {
            return a.value - b.value
          })
          setLearners(learnersList)
        })
        .catch()
    }

    getMissionsAll()
  }, [])

  function selectUser(value, roleId) {
    const oldItem = usersSelected.filter(item => item.role_id === roleId)
    const filterLearners = value.value === '-1' ? learners : learners.filter(item => item !== value)

    //if (oldItem[0]) filterLearners.push({ value: oldItem[0].value, label: oldItem[0].label })

    if (oldItem[0] && oldItem[0].value != '-1')
      filterLearners.push({
        value: oldItem[0].value,
        label: oldItem[0].label,
      })

    filterLearners.sort((a, b) => a.value - b.value)

    setLearners(filterLearners)

    const newItem = { role_id: roleId, ...value }
    const filterUsers = usersSelected.filter(item => item.role_id !== roleId)
    filterUsers.sort((a, b) => a.role_id - b.role_id)
    filterUsers.push(newItem)
    setUsersSelected(filterUsers)

    const rolesToSend = []

    filterUsers.forEach(item => rolesToSend.push({ role_id: item.role_id, user_id: item.value }))

    setTeam({
      ...team,
      roles: rolesToSend,
      created_by: userId,
    })
  }

  function changeInput(e) {
    setTeam({
      ...team,
      [e.target.name]: e.target.value,
      created_by: userId,
    })
  }

  async function register() {
    await storeTeam(team)
      .then(response => {
        Swal.fire({
          title: response.message,
          icon: 'success',
          confirmButtonText: 'Regresar',
        }).then(_result => {
          history.push(`/dashboard/teams`)
        })
      })
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: error.errors,
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(_result => {
          history.push(`/dashboard/teams`)
        })
      })
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <TeamForm
          usersSelected={usersSelected}
          selectUser={selectUser}
          roles={roles}
          operatives={operatives}
          learners={learners}
          register={register}
          changeInput={changeInput}
          errors={errors}
          title="Agregar Equipo"
        />
      )}
    </div>
  )
}
