import React from 'react'

import { useParams, useLocation, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import Header from '../../components/modules/dashboard/partials/Header'
import ChallengesForm from '../../components/modules/dashboard/challenges/ChallengesForm'
import { getAllRoles } from '../../service/module/Roles'
import {
  getSprintsChallenge,
  showChallenge,
  updateChallenge,
  deleteSprintChallenge
} from '../../service/module/Challenges'
import { useProfile } from '../../context/MainContext'

import css from './EditChallenge.module.css'

export default function EditChallenge() {
  const { id } = useParams()
  const [message, setMessage] = React.useState()
  const [challenge, setChallenge] = React.useState({})
  const [errors, setErrors] = React.useState()
  const [roles, setRoles] = React.useState()
  const [meta, setMeta] = React.useState()
  const [list, setList] = React.useState()
  const [pages, setPages] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const { userType, userId } = useProfile()
  const history = useHistory()
  const [challengeTemp, setChallengeTemp] = React.useState({})
  const [changed, setChanged] = React.useState(true) 

  const location = useLocation()

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
    }
  }, [location])

  React.useEffect(() => {
    async function getRoles() {
      await getAllRoles()
        .then(response => {
          setRoles(response)
        })
        .catch(() => {})
    }

    async function getSprints(current) {
      await getSprintsChallenge(id, current)
        .then(response => {
          setList(response.data.sort((a, b) => a.challenge_order - b.challenge_order))
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => {})
    }

    getRoles()
    getSprints(currentPage)
  }, [id, currentPage])

  React.useEffect(() => {
    async function getChallenge(id) {
      await showChallenge(id)
        .then(response => {
          const roleFilled = response.data
          setChallenge({
            ...roleFilled,
            created_by: userId,
          })

          setChallengeTemp({...roleFilled,
            created_by: userId,})
        })
        .catch(() => {})
    }

    getChallenge(id)
  }, [id, userId])

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
      container: css.container,
      popup: css.popup,
    },
    buttonsStyling: false,
  })

  /*Se actualiza el challenge*/
  async function update() {
    await updateChallenge(id, challenge)
      .then(response => {
        swalCustom.fire({
          title: 'Reto actualizado exitosamente',
          icon: 'success',
          showDenyButton: true,
          showConfirmButton: true,
          denyButtonText: 'Seguir editando',
          confirmButtonText: 'Ver todos los retos',
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/dashboard/challenges')
          }
        })
      })
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: error.errors,
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(result => {
          history.push('/dashboard/challenges')
        })
      })
  }

  async function deleteSprint() { 
    await deleteSprintChallenge(id, {"created_by": userId})
    .then(response => { 
      console.log('response =>', response)
    })
    .catch(error => { 
      setErrors(error.errors)
      Swal.fire({
        title: 'Error!',
        text: error.errors,
        icon: 'error',
        confirmButtonText: 'Regresar',
      }).then(result => {
        
      })
    })
  }


  React.useEffect(() => { 
    isEqual(challenge, challengeTemp)
  },[challenge, challengeTemp])

  function changeInput(e) {
    setChallenge({
      ...challenge,
      [e.target.name]: e.target.value,
    })

    isEqual(challenge, challengeTemp)
  }

  function selectedOption(e) {
    changeInput(e)
  }

  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function isEqual(challenge, challengeTemp){ 

    if(challenge.name !== challengeTemp.name){
      setChanged(false)
    }else if(challenge.description !== challengeTemp.description){
      setChanged(false)
    }else if(challenge.url_image !== challengeTemp.url_image){
      //la imagen bien por defecto :c 
      setChanged(false)
    }else{ 
      setChanged(true)
    }
  }

  return (
    <div>
      <Header />
      {(userType === 'operative' || userType === 'manager') && (
        <ChallengesForm
          id={id}
          setCurrentPage={setCurrentPage}
          meta={meta}
          list={list}
          pages={pages}
          title="Editar Reto"
          message={message}
          roles={roles}
          challenge={challenge}
          changeInput={changeInput}
          errors={errors}
          update={update}
          selectedOption={selectedOption}
          changed = {changed}
          setList = {setList}
          currentPage = {currentPage}
        />
      )}
    </div>
  )
}
