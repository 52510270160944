import React from 'react'

import { useParams } from 'react-router-dom'

import Header from '../../components/modules/dashboard/partials/Header'
import TeamForm from '../../components/modules/dashboard/teams/TeamForm'

import { getTeamBy, updateTeam } from '../../service/module/Teams'
import { getUsersFor } from '../../service/module/Users'
import { getAllMissions } from '../../service/module/Missions'
import { getMission } from '../../service/module/Missions'
import { useProfile } from '../../context/MainContext'

export default function EditTeam() {
  const { id } = useParams()
  const [team, setTeam] = React.useState({})
  const [errors, setErrors] = React.useState()
  const [operatives, setOperatives] = React.useState()
  const [missions, setMissions] = React.useState()
  const [learners, setLearners] = React.useState()
  const [roles, setRoles] = React.useState()
  const [members, setMembers] = React.useState()
  const [usersSelected, setUsersSelected] = React.useState([])
  const [message, setMessage] = React.useState()
  const { userType, userId } = useProfile()

  React.useEffect(() => {
    async function getTeam(id) {
      var team
      var members = []

      await getAllMissions()
        .then(response => {
          setMissions(response)
        })
        .catch(() => {})

      await getTeamBy(id)
        .then(response => {
          team = response.data
          setTeam({
            mission_id: team.mission_id,
            coach: team.coach,
            leader: team.leader,
            name: team.name,
            created_by: userId,
          })

          members = team.members.data

          setMembers(team.members.data)

          getMission(team.mission_id)
            .then(response => {
              setRoles(response.mission[0].roles)
            })
            .catch(() => {})
        })
        .catch(() => {})

      await getUsersFor('operative')
        .then(response => {
          setOperatives(response.data)
        })
        .catch(() => {})

      await getUsersFor('learner')
        .then(response => {
          var learners = []
          var usersExist = []

          learners.push({ value: '-1', label: 'Selecciona un aprendedor' })

          response.data.forEach(item => {
            const findMember = members.find(member => member.user_id === item.id)

            if (!findMember) learners.push({ value: item.id, label: item.full_name })
            else
              usersExist.push({
                role_id: findMember.role_id,
                value: item.id,
                label: item.full_name,
              })
          })

          setUsersSelected(usersExist)

          learners.sort((a, b) => {
            return a.value - b.value
          })
          setLearners(learners)
        })
        .catch(() => {})
    }

    getTeam(id)
  }, [id, userId])

  function changeInput(e) {
    setTeam({
      ...team,
      [e.target.name]: e.target.value,
    })
  }

  function selectUser(value, roleId) {
    const oldItem = usersSelected.filter(item => item.role_id === roleId)
    const filterLearners = value.value === '-1' ? learners : learners.filter(item => item !== value)

    if (oldItem[0] && oldItem[0].value != '-1')
      filterLearners.push({
        value: oldItem[0].value,
        label: oldItem[0].label,
      })

    filterLearners.sort((a, b) => a.value - b.value)

    setLearners(filterLearners)

    const newItem = { role_id: roleId, ...value }
    const filterUsers = usersSelected.filter(item => item.role_id !== roleId)
    filterUsers.sort((a, b) => a.role_id - b.role_id)
    filterUsers.push(newItem)
    setUsersSelected(filterUsers)

    const rolesToSend = []

    filterUsers.forEach(item => rolesToSend.push({ role_id: item.role_id, user_id: item.value }))

    setTeam({
      ...team,
      roles: rolesToSend,
      created_by: userId,
    })
  }

  async function update() {
    await updateTeam(id, team)
      .then(response => {
        setMessage(response.message)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      })
      .catch(error => {
        setErrors(error.errors)
      })
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <TeamForm
          title="Editar Equipo"
          message={message}
          team={team}
          missions={missions}
          operatives={operatives}
          learners={learners}
          roles={roles}
          changeInput={changeInput}
          members={members}
          usersSelected={usersSelected}
          selectUser={selectUser}
          errors={errors}
          update={update}
        />
      )}
    </div>
  )
}
