import React from 'react'
// Components:
import Slider from 'react-slick'
import Footer from '../../components/modules/main/partials/Footer'
import { ChallengeCard, CurrentChallenge } from '../../components'
// Functions:
import { showUser } from '../../service/module/Users'
import { useProfile } from '../../context/MainContext'
import {
	getChallengesComplete,
	getChallengesModules,
	getPercentageComplete,
} from '../../service/module/Missions'
// Libraries:
import { LinearProgress, withStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
// CSS:
import css from './Challenges.module.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// Assets:
import ornito from '../../assets/ornito.png'

const MainBlueProgress = withStyles(() => ({
  root: {
    marginBottom: 20,
    height: 14,
    borderRadius: 7,
  },
  colorPrimary: {
    backgroundColor: '#dddcdd',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#004DFF',
  },
}))(LinearProgress)

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Challenges() {
  // Custom Hooks
  const history = useHistory()
  const query = useQuery()
  if (query.get('error') === 'access_denied') {
    history.push('/verify-email')
  }
  const { userId, setImageProfile } = useProfile()
  // States
  const [user, setUser] = React.useState(null)
  const [percentageChallenges, setPercentageChallenges] = React.useState()
  const [percentageCurrent, setPercentageCurrent] = React.useState()
  const [challenges, setChallenges] = React.useState()
  const [challengesComplete, setChallengesComplete] = React.useState()
  const [currentChallenge, setCurrentChallenge] = React.useState()
  const [newChallenges, setNewChallenges] = React.useState([])
  const [displayChallenges, setDisplayChallenges] = React.useState(false)
  //const [finished, setFinished] = React.useState([])
  const [pathCompleted, setPathCompleted] = React.useState(false)
  const [defaultCurrentChallenge, setDefaultCurrentChallenge] = React.useState(null)

  
React.useEffect(() => {
	async function getUser(id) {
      	let challengeArr = []
      	if (!id) return // Early return
		// Get user data
        await showUser(id).then(response => {
        	const userData = response.data[0]
          	setImageProfile(userData.profile?.image_profile)
          	setUser(userData)
          	localStorage.setItem('role_id', userData.role_id)
		  	// Get challenges data
          	getChallengesModules(userData?.profile?.group_id, userData.role_id).then(response => {
            	let challengeList = response.data
            	if (response.success) {
              		setChallenges(challengeList)
            	}

            	getChallengesComplete(userId).then(response => {
              		let completeList = []

					if (!Array.isArray(challengeList)) return // Early return

					challengeList?.forEach(item => {
						const completes = response.success === true 
							? response.data.filter( o => { return o.challenges_module === item.challenge_id } )
							: []
						//const challenges = item?.challenges?.filter(o => o.content_type !== 'parent')
						let challenges = []
						for (const challenge of item?.challenges) {
							if (challenge.content_type !== 'parent') {
								challenges.push(challenge)
							} else {
								for (const modality of challenge.modalityContent) {
									if (modality.content_type !== 'parent') {
										challenges.push(challenge)
									}
								}
							}
						}
						// * Patch: Greater than or equal (include duplicated progress)
						if (completes.length >= challenges.length && completes.length > 0) {
							item['status'] = 'complete'
							completeList.push(item)
						} else {
							item['status'] = 'pending'
						}
						challengeArr.push(item)
					})
					//ChallengesComplete: Array con los retos completados
					setChallengesComplete(completeList)
					//barra de arrriba.
					//setPercentageChallenges((completeList.length / modules.length) * 100)
					

					// * If challengesComplete equals challenges length, set pathCompleted as true
					// * Patch: Greater than or equal (include duplicated progress)
					if(completeList.length >= challengeList.length){
						const defaultChallenge = challengeList[challengeList.length - 1]
						setPathCompleted(true)
						setDefaultCurrentChallenge(defaultChallenge)
					}

            	})
			})
		
			// * PercentageChallenges: Porcentaje de avance global (todos los retos)
			// * PercentageCurrent: Porcentaje de avance del reto que actualmente se encuentra en progreso
			// * CurrentChallenge: Reto que actualmente se encuentra en progreso
			getPercentageComplete(1, userData.role_id, userId).then(response => {
				setPercentageCurrent(response.data[0].percentage_current)
				setPercentageChallenges(response.data[0].percentage)
				setCurrentChallenge(response.data[0].current)
				setNewChallenges(challengeArr)
			})
        })
    }
    getUser(userId)
}, [userId])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: newChallenges && newChallenges.length > 2 ? 3 : 2,
    slidesToScroll: 1,
  }


  return (
    <div className={css.root}>
    	{challenges && challenges.length > 0 && (
			<div className={classNames(css.container, {
				[css.containerFinished]: challengesComplete && challengesComplete.length === challenges.length && challenges
			})}>
				{/* Challenge Header */}
				{ challengesComplete && challenges ? (
					<>
						<h6 className={css.title}>
							<b>¡Hola {user && user.names}!</b>
						</h6>
						<h5 className={css.progressText}>
							En esta experiencia de aprendizaje deberás completar{' '}
							<b>
								<span className={css.blueText}>{challenges && challenges.length}</span>
							</b>{' '}
							tarjetas. Has completado{' '}
							<b>
								<span className={css.blueText}>{challengesComplete && challengesComplete.length}</span>{' '}
							</b>
							de{' '}
							<b>
								<span className={css.blueText}>{challenges && challenges.length}</span>
							</b>
							.
						</h5>
						<MainBlueProgress
							variant="determinate"
							value={((challengesComplete.length * 100) / challenges.length)}
						/>
					</>
				) : null }
				{/* Current challenge */}
				{ !pathCompleted ? 
					/* Uses real current challenge when in progress */
					(
						currentChallenge && currentChallenge !== null ? 
						(
							<CurrentChallenge
								challengeId={currentChallenge?.challenge_id}
								imageUrl={currentChallenge?.url_image}
								currentPercentage={percentageCurrent}
								sequence={currentChallenge?.sequence}
								name={currentChallenge?.name}
								description={currentChallenge?.description}
								challengesComplete={challengesComplete}
								roleId={user?.role_id}
								completed={false}
							/>
						) : null 
					) 
					: defaultCurrentChallenge && defaultCurrentChallenge !== null ?
					/* Uses default current challenge when finished */
					(
						<CurrentChallenge
							challengeId={defaultCurrentChallenge?.challenge_id}
							imageUrl={defaultCurrentChallenge?.challengesModule[0].url_image}
							currentPercentage={100}
							sequence={defaultCurrentChallenge?.sequence}
							name={defaultCurrentChallenge?.challengesModule[0].name}
							description={defaultCurrentChallenge?.challengesModule[0].description}
							challengesComplete={null}
							roleId={user?.role_id}
							completed={true}
						/>
					) 
					/* No content */
					: null 
				}
				<div className={css.challengesContainer}>
					{/* Challenge Slider - Desktop */}
					<div className={css.hideSmall}>
						{ challenges && challenges.length > 1 && challengesComplete && (
							<Slider {...settings} className={css.slider}>
								{ challenges &&
								challenges.map((item, index) => {
									return (
										<ChallengeCard 
											key={index} 
											challenge={item} 
											className={css.smallCard}
											onClick={() => {
												history.push(`/challenges/${item.challenge_id}/sprints?role_id=${user.role_id}`)
											}}
										/>
									)
								})}
							</Slider>
						)}
					</div>
					{/* Challenge Slider - Mobile */}
					{ challenges && challenges.length > 1 && challengesComplete && (
						<div className={css.hideLarge}>
							<p className={css.subtitle}>Mis retos:</p>
							<div className={css.challengeList}>
								{challenges && challenges.map((item, index) => {
									
									if (displayChallenges === false && index > 2) {
										return null
									}

									return (
										<ChallengeCard 
											key={index}
											index={index} 
											challenge={item} 
											className={css.smallCard}
											onClick={() => {
												history.push(`/challenges/${item.challenge_id}/sprints?role_id=${user.role_id}`)
											}}
										/>
									)
								})}
							</div>
							<button
							className={css.showMore}
							onClick={() => {
								setDisplayChallenges(!displayChallenges)
							}}
							>
								{displayChallenges ? 'Ver menos' : 'Ver más'}
							</button>
						</div>
					)}
				</div>
			</div>
      	)}
	
	  {/* Ornito message shown to unassigned users */}
      {(!userId || !challenges || challenges.length === 0 || challenges.message) && (
        <div className={css.noChallengesContainer}>
          <div className={css.typeformDegree}>
            <img src={ornito} className={css.image} alt="NAO" />
            <h6 className={css.noChallengeSubtitle}>
              <span className={css.challengeBold}>Welcome my friend</span> <br />
              Soy Ornito Rito y te estaré acompañando en tu experiencia de aprendizaje. Te confirmo que si llegaste hasta aquí es porque ya estás en Digital NAO.
              Sé que aún no puedes visualizar los retos, not to worry. Te avisaremos cuando estén habilitados, así que revisa tu correo periódicamente.
            </h6>
            <h6 className={css.noChallengeSubtitle}>
              <b>#OrnitoToRescue</b>
            </h6>
            <h6 className={css.noChallengeSubtitle}>
              <b>#NAOKeepCalm</b>
            </h6>
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}
