import React from 'react'

import { Link } from 'react-router-dom'
import classNames from 'classnames'

import NaoLogo from '../../../../assets/NAO_Tecmilenio.svg'
import Facebook from '../../../../assets/facebook.svg'
import Instagram from '../../../../assets/Instagram.svg'

import css from './Footer.module.css'

export default function Footer({
  className
}) {
  return (
    <>
      <div className={classNames(className, css.root)}>
        <div className={css.socialContainer}>
          <img src={NaoLogo} alt="NAO y Tecmilenio" />
          {
            <div className={css.btnContainer}>
              <a href="https://instagram.com/now_is_nao" rel="noopener noreferrer" target="_blank">
                <div className={css.socialBtn}>
                  <img src={Instagram} alt="Instagram" />
                </div>
              </a>
              <a
                href="https://www.facebook.com/nowisnao/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className={css.socialBtn}>
                  <img src={Facebook} alt="Facebook" />
                </div>
              </a>
            </div>
          }
        </div>
      </div>
      <div className={css.root} style={{ backgroundColor: '#f6f6f6' }}>
        <div className={css.linkContainer}>
          <a
            href="mailto:hola@mail.nowisnao.com"
            rel="noopener noreferrer"
            target="_blank"
            className={css.link}
          >
            CONTACTO &ensp;•&ensp;
          </a>

          <Link className={css.link} to={`/aviso-de-privacidad`}>
            POLÍTICA DE PRIVACIDAD &ensp;•&ensp;
          </Link>
          <Link className={css.link} to={`/terminos-y-condiciones`}>
            TÉRMINOS Y CONDICIONES
          </Link>
        </div>
      </div>
    </>
  )
}
