import React from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { Pagination, PaginationItem, PaginationLink, Alert, Modal } from 'reactstrap'
import classNames from 'classnames'

import Button from '../../components/modules/dashboard/partials/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faAngleRight,faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/modules/dashboard/partials/Header'
import { getAllTeams, deleteTeam } from '../../service/module/Teams'
import { useProfile } from '../../context/MainContext'

import css from './Teams.module.css'

export default function Teams() {
  const [message, setMessage] = React.useState()
  const [list, setList] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const [notification, setNotification] = React.useState()
  const [meta, setMeta] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const [isTeamDeleted, setIsTeamDeleted] = React.useState()
  const [pages, setPages] = React.useState()
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const [teamId, setTeamId] = React.useState()
  const { userType } = useProfile()

  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
      setColorMessage('success')
      setNotification('Éxito')
    }
  }, [location])

  React.useEffect(() => {
    async function getUsers(current) {
      await getAllTeams(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => {})
    }

    getUsers(currentPage)
  }, [currentPage, isTeamDeleted])

  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function add(e) {
    e.preventDefault()
    history.push('/dashboard/teams/add')
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage(meta.next_page_url)
    }
  }

  function prepareDeleteTeam(teamId) {
    setTeamId(teamId)
    toggleModal()
  }

  function editTeamAction(userId) {
    history.push(`/dashboard/teams/${userId}/edit`)
  }

  async function deleteTeamAction() {
    await deleteTeam(teamId)
      .then(response => {
        setMessage('Equipo eliminado correctamente.')
        setColorMessage('success')
        setNotification('Éxito')
        setIsTeamDeleted(!isTeamDeleted)
      })
      .catch(error => {
        setMessage(error.message)
        setColorMessage('danger')
        setNotification('Error')
      })

    toggleModal()
  }

  function toggleModal() {
    setIsOpenModal(!isOpenModal)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div>
      <Header />
      {/* Page content */}
      <div className={classNames(css.root)}>
        {message && colorMessage && (
          <Alert color={colorMessage}>
            <strong>¡{notification}!</strong> {message}
          </Alert>
        )}
        <div className={css.tableContainer}>
          <div className={css.tableHeader}>
            <h3 className="mb-0">Lista de Equipos</h3>
            {userType === 'operative' && (
              <Button href="/dashboard/users/add" className={css.addButton} onClick={add}>
                Agregar
              </Button>
            )}
          </div>
          <div className={css.table}>
            <table className={css.table}>
              <thead className={css.thead}>
                <tr className={css.tr}>
                  <th scope="col" className={css.th}>
                    Nombre
                  </th>
                  {userType === 'operative' && (
                    <th scope="col" className={css.th}>
                      Acciones
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className={css.tbody}>
                {list &&
                  list.map((item, key) => {
                    return (
                      <tr className={css.tr} key={key}>
                        <td scope="row" className={css.td}>
                          <span className={css.name}>{item.name}</span>
                        </td>
                        {userType === 'operative' && (
                          <td className={classNames(css.td, css.actionButtons)}>
                            <Button
                              isicon={true}
                              className={css.edit}
                              type="button"
                              title="Editar"
                              onClick={() => editTeamAction(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Button>
                            <Button
                              isicon={true}
                              className={css.delete}
                              type="button"
                              title="Eliminar"
                              onClick={() => prepareDeleteTeam(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </Button>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div className={css.tableFooter}>
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                {meta && (
                  <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                    <PaginationLink
                      href={meta.first_page_url}
                      onClick={previous}
                      tabIndex={!meta.previous_page_url ? '-1' : undefined}
                    >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                )}
                {pages &&
                  pages.map((item, key) => {
                    /*
                     Always show 9 pages (if there are more than 9)
                     show 4 pages before and 4 pages after the current page
                    */
                    if (meta.current_page <= 5 && key < 9) {
                      return (
                        <PaginationItem
                          key={key}
                          className={meta.current_page === item ? 'active' : ''}
                        >
                          <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                            {item}
                            {meta.current_page === item && (
                              <span className="sr-only">(current)</span>
                            )}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    }

                    if (meta.current_page > 5) {
                      if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    }

                    if (meta.last_page - meta.current_page < 5) {
                      if (key > meta.last_page - 10) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    }
                  })}
                {meta && (
                  <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                    <PaginationLink href={meta.last_page_url} onClick={next}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </nav>
          </div>
        </div>
      </div>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={isOpenModal}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            ¡Atención!
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">¿Estás seguro de eliminar el equipo?</h4>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            onClick={() => deleteTeamAction()}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
    </div>
  )
}
