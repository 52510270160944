import css from './Footer.module.css'

export default function Footer() {
  return (
    <footer className={css.root}>
          <div className={css.copyright}>
            © {new Date().getFullYear()}{' '}
            <a className={css.link} href="/" rel="noopener noreferrer" target="_blank">
              Digital NAO
            </a>
          </div>

          <ul className={css.nav}>
            <li>
              <a href="/" rel="noopener noreferrer" target="_blank" className={css.navLink}>
                Tecmilenio
              </a>
            </li>
          </ul>
    </footer>
  )
}
