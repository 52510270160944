import React from 'react'

import moment from 'moment'

import {
  IconButton,
  withStyles,
  makeStyles,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faDiscord,
  faGithub,
  faBehance,
} from '@fortawesome/free-brands-svg-icons'

import defaultAvatar from '../../../../assets/default-user-profile.png'

import Button from '../partials/Button'
import SocialNetworkInput from './SocialNetworkInput'

import uploadFileToBlob, { isStorageConfigured } from '../../../../hooks/AzureBlobStorage'

import { updateUser } from '../../../../service/module/Users'

import css from './DialogEditProfile.module.css'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: '100%',
  },
  inputsContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px',
    },
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
  },
  avatar: {
    width: 135,
    height: 135,
    position: 'relative',
  },
  avatarOverlay: {
    position: 'absolute',
    width: 143,
    height: 143,
    borderRadius: '50%',
    background: 'rgba(84, 86, 90, 0.5)',
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 1,
    },
  },
  cameraIcon: {
    width: '30px !important',
    height: 30,
    color: '#fff',
  },
  cameraLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: '#fff',
    fontWeight: 600,
    margin: 0,
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: 5,
    color: '#747474',
    fontWeight: 600,
    marginBottom: 10,
  },
  select: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  popperContainer: {
    zIndex: theme.zIndex.drawer + 100,
  },
  paper: {
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1.5),
    textAlign: 'center',
    backgroundColor: '#faeede',
    borderRadius: 10,
    color: '#635e5a',
  },
  mail: {
    color: '#8aa7f4',
  },
  socialTitle: {
    padding: '20px 0 10px',
  },
}))

const styles = theme => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontFamily: "Hind Madurai,Baskerville,Baskerville BT,serif",
    fontWeight: 500,
    lineHeight: 1.6,
    margin: 0,
  },
  dialog: {
    margin: 0,
    padding: '20px 30px 2px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider: {
    borderBottom: '1px solid #bebebe',
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <div  className={classes.dialog} {...other}>
      <h6 className={classes.title}>{children}</h6>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <div className={classes.divider}></div>
    </div>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '16px 30px',
    },
  },
  dividers: {
    borderTop: 0,
    borderBottom: 0,
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '16px 30px',
  },
}))(MuiDialogActions)

export default function DialogEditProfile({
  setOpen,
  open,
  user,
  setUploading,
  userId,
  setUpdateUser,
}) {
  const classes = useStyles()

  const [linkedinValue, setLinkedinValue] = React.useState(null)
  const [facebookValue, setFacebookValue] = React.useState(null)
  const [discordValue, setDiscordValue] = React.useState(null)
  const [behanceValue, setBehanceValue] = React.useState(null)
  const [twitterValue, setTwitterValue] = React.useState(null)
  const [instagramValue, setInstagramValue] = React.useState(null)
  const [githubValue, setGithubValue] = React.useState(null)
  
  const [gender, setGender] = React.useState('')
  const [birthdateValue, setBirdateValue] = React.useState(new Date())
  const [celularValue, setCelularValue] = React.useState('')
  const [aliasValue, setAliasValue] = React.useState('')
  const [bioValue, setBioValue] = React.useState(null)

  const [celularError, setCelularError] = React.useState(false)
  const [discordError, setDiscordError] = React.useState(false)

  const inputDiscord = React.useRef()

  isStorageConfigured()

  React.useEffect(() => {
    if (user != null) {
      setLinkedinValue(user.profile?.linkedin || '')
      setFacebookValue(user.profile?.facebook || '')
      setDiscordValue(user.profile?.discord || '')
      setBehanceValue(user.profile?.behance || '')
      setTwitterValue(user.profile?.twitter || '')
      setInstagramValue(user.profile?.instagram || '')
      setGithubValue(user.profile?.github || '')
      setGender(user.gender ? user.gender : null)
      setBirdateValue(moment(user.birthdate))
      setCelularValue(user.profile?.phone_number)
      setBioValue(user.profile?.bio)
      setAliasValue(user.alias)
    }
  }, [user, open])

  const handleSave = async e => {
    e.preventDefault()

    if (celularError || discordError) {
      return
    } else {
      if (inputDiscord.current.value.length === 0) {
        setDiscordError(true)
        return
      }
    }

    setUploading(true)

    var urlUploadedFile = user.profile?.image_profile

    let data = {
      email: user.email,
      user_type: user.user_type,
      names: user.names ? user.names : 'undefined',
      last_name: user.last_name ? user.last_name : 'undefined',
      sur_name: user.sur_name ? user.sur_name : '',
      gender: gender,
      phone_number: celularValue,
      bio: bioValue,
      birthdate: birthdateValue.format('yyyy-MM-DD'),
      allow_public_name: user.allow_public_name ? user.allow_public_name : false,
      alias: aliasValue,
      image_profile: urlUploadedFile,
      linkedin: linkedinValue,
      facebook: facebookValue,
      discord: discordValue,
      behance: behanceValue,
      twitter: twitterValue,
      instagram: instagramValue,
      github: githubValue,
    }

    await updateUser(userId, data)
      .then(() => {
        setUploading(false)
        setOpen(false)
        setUpdateUser(true)
      })
      .catch(() => {
        setUploading(false)
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const isDisabled = () => {
    if(user && user.profile) {
      if (
        (linkedinValue === user.profile?.linkedin)  &&
        (facebookValue === user.profile?.facebook) &&
        (discordValue === user.profile?.discord || discordValue === '') &&
        (behanceValue === user.profile?.behance) &&
        (twitterValue === user.profile?.twitter) &&
        (instagramValue === user.profile?.instagram) &&
        (githubValue === user.profile?.github)
      ) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={'md'}
      scroll={'body'}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Redes Sociales
      </DialogTitle>
      <DialogContent dividers>
        <form className={css.form}>
          <div className={classes.inputsContainer}>
            <p className={classes.label}>
              En esta sección puedes agregar tu usuario de redes sociales, para compartir tu perfil.
            </p>
            <SocialNetworkInput
              title="LinkedIn"
              icon={faLinkedin}
              value={linkedinValue}
              url="https://www.linkedin.com/in/"
              onChangeInput={e => setLinkedinValue(e.target.value)}
              onFocusInput={text => setLinkedinValue(text)}
              onBlurInput={text => setLinkedinValue(text)}
            />
            <SocialNetworkInput
              title="Facebook"
              icon={faFacebook}
              value={facebookValue}
              url="https://www.facebook.com/"
              onChangeInput={e => setFacebookValue(e.target.value)}
              onFocusInput={text => setFacebookValue(text)}
              onBlurInput={text => setFacebookValue(text)}
            />
            <SocialNetworkInput
              title="Discord*"
              icon={faDiscord}
              value={discordValue}
              url="https://www.discord.com/users/"
              ref={inputDiscord}
              error={discordError}
              onChangeInput={e =>
                setDiscordValue(() => {
                  setDiscordError(e.target.value.length === 0 ? true : false)
                  return e.target.value
                })
              }
              onFocusInput={text => setDiscordValue(text)}
              onBlurInput={text => setDiscordValue(text)}
              errorMessage="Agrega tu usuario de discord"
              isRequerid={true}
            />
            <SocialNetworkInput
              title="Behance"
              icon={faBehance}
              value={behanceValue}
              url="https://www.behance.net/"
              onChangeInput={e => setBehanceValue(e.target.value)}
              onFocusInput={text => setBehanceValue(text)}
              onBlurInput={text => setBehanceValue(text)}
            />
            <SocialNetworkInput
              title="Twitter"
              icon={faTwitter}
              value={twitterValue}
              url="https://www.twitter.com/"
              onChangeInput={e => setTwitterValue(e.target.value)}
              onFocusInput={text => setTwitterValue(text)}
              onBlurInput={text => setTwitterValue(text)}
            />
            <SocialNetworkInput
              title="Instagram"
              icon={faInstagram}
              value={instagramValue}
              url="https://www.instagram.com/"
              onChangeInput={e => setInstagramValue(e.target.value)}
              onFocusInput={text => setInstagramValue(text)}
              onBlurInput={text => setInstagramValue(text)}
            />
            <SocialNetworkInput
              title="GitHub"
              icon={faGithub}
              value={githubValue}
              url="https://www.github.com/"
              onChangeInput={e => setGithubValue(e.target.value)}
              onFocusInput={text => setGithubValue(text)}
              onBlurInput={text => setGithubValue(text)}
            />
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button newButton={true} light="true" title="Cancelar" onClick={handleClose}/>
        <Button
          newButton={true}
          type="button"
          title="Guardar"
          disabled={isDisabled()}
          onClick={e => handleSave(e)}
        />
      </DialogActions>
    </Dialog>
  )
}
