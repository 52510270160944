import React from 'react'

import { useParams, useHistory, useLocation } from 'react-router-dom'

import AsideDrawer from '../../components/modules/main/sprints-page/AsideDrawer'
import {
  getChallenge,
  getLastChallenge,
  getSprints,
  getSprintsResourcesAll,
} from '../../service/module/Challenges'
import SprintContent from '../../components/modules/main/sprints-page/SprintContent'
import { useProfile } from '../../context/MainContext'
import { registerChallenge } from '../../service/module/Missions'
import { getSurvey, setSurvey } from '../../service/module/Users'
import { getPercentageComplete } from '../../service/module/Missions'
import { getGroupBy } from '../../service/module/Groups'
import Footer from '../../components/modules/main/partials/Footer'

import css from './Sprints.module.css'

export default function Sprints() {
  const { id } = useParams()

  //Get role_id variable from query params
  const role_id = localStorage.getItem('role_id')

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const forceShowContinue = params.get('force_show_continue')

  const [sprints, setSprints] = React.useState()
  const [challenge, setChallenge] = React.useState()
  const [lastChallenge, setLastChallenge] = React.useState()
  const [currentSprint, setCurrentSprint] = React.useState()
  const [challengesComplete, setChallengesComplete] = React.useState()
  const [resources, setResources] = React.useState()
  const [surveyIsVisible, setSurveyIsVisible] = React.useState(false)
  const [changeSurvey, setChangeSurvey] = React.useState(false)
  const [currentChallenge, setCurrentChallenge] = React.useState()
  const [nextInProgress, setNextInProgress] = React.useState(false)

  const { userId, userInfo } = useProfile()
  const [userGroup, setUserGroup] = React.useState(null)

  const history = useHistory()

  React.useEffect(() => {
    async function updateView(sprint) {
      if(sprint?.is_child === 0){ 
        if (sprint?.modality_content_id) {
          await getSprintsResourcesAll(sprint.modality_content_id).then(response => {
            setResources(response.success === true ? response.data : null)
          })
  
          await getSurvey(sprint.id, userId).then(survey => {
            setTimeout(() => {
              setSurveyIsVisible(survey.success === false ? true : false)
            }, 800)
          })
        }
      }else{ 
        if(sprint?.id){ 
          await getSprintsResourcesAll(sprint.id).then(response => {
            setResources(response.success === true ? response.data : null)
          })
  
          await getSurvey(sprint.id, userId).then(survey => {
            setTimeout(() => {
              setSurveyIsVisible(survey.success === false ? true : false)
            }, 800)
          })
        }
      }
      
    }
    updateView(currentSprint)
  }, [currentSprint, changeSurvey, userId])

  React.useEffect(() => {
    if (userInfo?.profile?.group_id) {
      getGroupBy(userInfo.profile.group_id).then(response => {
        if (response.success) {
          setUserGroup(response.data[0])
        }
      })
    }
  }, [userInfo])

  React.useEffect(() => {
    async function getSprintsForChallenge(idChallenge) {
      var sprints = []
      if (idChallenge && userId) {
        await getSprints(idChallenge, userInfo?.profile?.group_id).then(response => {
          const sprintsFilled = response.data
          sprints = sprintsFilled
          setSprints(sprintsFilled)
        })

        await getChallenge(idChallenge).then(response => {
          setChallenge(response.data)
        })

        if(userGroup?.id) {
          await getLastChallenge(userId, userGroup?.id).then(response => {
            if (response?.data[0]?.last_Challenge[0]?.modality_content[0]?.content_type === 'parent') {
              let curr = sprints.find(sprint => sprint.id === response?.data[0]?.last_Challenge[0]?.id) || sprints[0]
              let modalityContent = curr.modality_content[0] ? curr.modality_content[0] : {}
              curr.modality_content_id = curr.modality_content?.length > 0 ? curr.modality_content[0].id : null

              setLastChallenge({...curr, ...modalityContent, id: curr.id}.children[0])
              setCurrentSprint({...curr, ...modalityContent, id: curr.id}.children[0])
            }

            if (response?.data[0]?.last_Challenge[0]?.modality_content[0]?.content_type !== 'parent') {
              let curr = sprints.find(sprint => sprint.id === response?.data[0]?.last_Challenge[0]?.id) || sprints[0]

              if (curr?.modality_content[0]?.content_type === 'parent') {
                curr = curr?.modality_content[0]?.children[0]
              }

              let modalityContent = curr.modality_content?.length > 0 ? curr.modality_content[0] : {}
              curr.modality_content_id = curr.modality_content?.length > 0 ? curr.modality_content[0].id : null

              setCurrentSprint({...curr, ...modalityContent, id: curr.id})
              setLastChallenge({...curr, ...modalityContent, id: curr.id})
            }

            setChallengesComplete(response.data[0].challenges_complete)
          })
        }

        //nota camba
        await getPercentageComplete(1, role_id, userId).then(responsePercent => {
          setCurrentChallenge(responsePercent.data[0].current)
        })
      }
    }
    getSprintsForChallenge(id)
  }, [id, userId, userInfo, userGroup])

  async function nextSprint(item) {
    if (userId && currentSprint?.id && challenge?.id ) {
      var modalityId = currentSprint?.modality_content?.length > 0 ? currentSprint?.modality_content[0].id : currentSprint.id
      await registerChallenge(userId, currentSprint?.challenge_id, challenge.id, modalityId).then(() => {
        setLastChallenge(currentSprint)
        // if challenges complete has the current challenge, don't add it again
        if (!challengesComplete.includes(currentSprint)) {
          setChallengesComplete([...challengesComplete, currentSprint])
        }

        if (item === undefined || item === null) {
          history.push('/')
          return
        }

        if (item === 'aside') {
          return
        }

        let modalityContent = item?.modality_content?.length > 0 ? item?.modality_content[0] : {}
        item.modality_content_id = item?.modality_content?.length > 0 ? item?.modality_content[0].id : null

        setCurrentSprint({...item, ...modalityContent, id: item.id})

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      })
    } else {
      setLastChallenge(currentSprint)
      // if challenges complete has the current challenge, don't add it again
      if (!challengesComplete.includes(currentSprint)) {
        setChallengesComplete([...challengesComplete, currentSprint])
      }
      let modalityContent = item.modality_content?.length > 0 ? item?.modality_content[0] : {}
      item.modality_content_id = item.modality_content?.length > 0 ? item.modality_content[0].id : null

      setCurrentSprint({...item, ...modalityContent, id: item.id})
      if (item === undefined || item === null) {
        history.push('/')
        return
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
    setNextInProgress(false)
  }

  function previousSprint(item) {
    let modalityContent = item.modality_content?.length > 0 ? item.modality_content[0] : {}
    item.modality_content_id = item.modality_content?.length > 0 ? item.modality_content[0].id : null

    setCurrentSprint({...item, ...modalityContent, id: item.id})

    if (item === undefined || item === null) {
      history.push('/')
      return
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  async function saveAsideSprint(item) {
    var modalityId = item?.modality_content?.length > 0 ? item?.modality_content[0].id : item.id
    const challengeId = item?.challenge_id ? item?.challenge_id : item.id
    await registerChallenge(userId, challengeId, challenge.id, modalityId).then((res) => {
      nextSprint(item)
    })
  }

  async function saveSurvey(response) {
    const data = {
      response: response,
      challenge_id: currentSprint.id,
      is_intro: false,
      user_id: userId,
    }

    await setSurvey(data).then(response => {
      if (response.success) {
        setChangeSurvey(!changeSurvey)
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  return (
    <div className={css.grid}>
      <AsideDrawer
        sprints={sprints}
        currentSprint={currentSprint}
        lastChallenge={lastChallenge}
        challengesComplete={challengesComplete}
        challenge={challenge}
        saveAsideSprint={saveAsideSprint}
      />
      <main lang="es" className={css.content}>
        <SprintContent
          roleId={role_id}
          sprints={sprints}
          resources={resources}
          lastChallenge={lastChallenge}
          sprint={currentSprint}
          challenge={challenge}
          nextItem={nextSprint}
          previousItem={previousSprint}
          surveyIsVisible={surveyIsVisible}
          saveSurvey={saveSurvey}
          currentChallenge={currentChallenge}
          nextText={'Continuar'}
          nextInProgress={nextInProgress}
          setNextInProgress={setNextInProgress}
          forceShowContinue={forceShowContinue}
        />
        <Footer className={css.footer} />
      </main>
    </div>
  )
}
