import React from "react";

import ChangeMarker from "../../assets/bg-circle.png";
import DialogBG from "../../assets/bg-dialogo.png";
import Footer from "../../components/modules/main/partials/Footer";
import { useProfile } from "../../context/MainContext";

import css from "./LandingPage.module.css";

export default function LandingPage() {
  const { login } = useProfile();

  React.useEffect(() => {
    document.getElementById("root").classList.remove("withoutHand");
    login();
  }, [login]);

  return (
    <>
      <div className={css.root}>
        <div className={css.row}>
          <div className={css.changeMakerContainer}>
            <img
              src={ChangeMarker}
              alt="change maker"
              className={css.changeMakerImage}
            />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.columnLeft} style={{ paddingLeft: 150 }}>
            <img
              src={DialogBG}
              alt="change maker"
              className={css.changeMakerImage}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
