import React from 'react';

import css from './Modal.module.css';

// Modal component with click outside functionality to close
const Modal = ({ children, show, onClose }) => {
    const modalRef = React.useRef(null);

    const handleClickOutside = (event) => {
        if (modalRef.current === event.target) {
            onClose();
        }
    };

    return (
        show && (
            <div className={css.modal} ref={modalRef} onClick={handleClickOutside}>
                <div className={css.modalContent}>
                    {children}
                </div>
            </div>
        ) 
    );
};

export default Modal;