import React from 'react'
import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Button from './Button'

import css from './SearchInput.module.css';

const SearchInput = (props) => {

    const {
        className,
        onSearch,
        placeholder
    } = props;

    const classes = classNames(css.root, className);

    const [hasContent, setHasContent] = React.useState(false)
    const [isFocused, setIsFocused] = React.useState(false)

    return (
        <div className={classNames(css.inputGroup, css.input)} >
            <div className={css.iconHolder}>
                <div className={css.icon}>
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>
            <input
                name="search"
                id="input-search"
                className={css.input}
                type="text"
                placeholder={placeholder || "Buscar"}
                onChange={(e) => {
                    const value = e.target.value
                    if (value.length > 0) {
                        setHasContent(true)
                    } else {
                        onSearch('')
                        setHasContent(false)
                    }
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault()
                        const email = e.target.value
                        onSearch(email)
                    }
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                    setTimeout(() => {
                        setIsFocused(false)
                    }, 2000)
                }}
            />
            {(hasContent || isFocused) && (
                <Button
                    type="button"
                    onClick={e => {
                        e.preventDefault()
                        const email = document.getElementById('input-search').value
                        onSearch(email)
                    }}
                    className={css.searchButton}
                >
                    Buscar
                </Button>
            )}
        </div>
    );
}

export default SearchInput;