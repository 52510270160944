import React from 'react'
import { Link } from 'react-router-dom'
import logoNAO from '../../assets/logo-nao.png'

import css from './VerifyEmail.module.css'

export const VerifyEmail = () => {
    return (
        <div className={css.container}>
            <img className={css.logo} src={logoNAO} alt='Digital NAO'/>
            <div>
                <h1 className={css.successful}>¡Tu registro fue exitoso!</h1>
                <p className={css.message}>Hemos enviado un mensaje de correo electronico a <Link className={css.link}>{localStorage.getItem('email')}</Link> por favor revisa tu buzón y sigue las intrucciones enviadas</p>
            </div>
        </div>
    )
}

