import React from 'react'

import Header from '../../components/modules/dashboard/partials/Header'
import SprintForm from '../../components/modules/dashboard/sprints/SprintForm'

import { useHistory, useParams } from 'react-router-dom'
import { getAllModalities, storeModality, getModalityById } from '../../service/module/Modalities';
import { useProfile } from '../../context/MainContext'
import Swal from 'sweetalert2'

import css from './AddSprintChild.module.css'

export default function AddSprintChild() {
  const [errors, setErrors] = React.useState()
  const [sprint, setSprint] = React.useState({ allow_survey: 'false' })
  const [parentModality, setParentModality] = React.useState()
  const { userType, userId } = useProfile()
  const history = useHistory()

  const { id, idSprint, modalityId } = useParams()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    async function getModalities() {
      await getModalityById(modalityId)
        .then(response => {
          setParentModality(response.data?.message ? [] : response.data[0])
        })
        .catch(() => { })
    }

    getModalities()
  }, [])

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function changeInput(e) {
    if (e.target.type === 'number') {
      if (e.target.value < 0) {
        errors[e.target.name] = 'El valor no puede ser negativo.'
        e.target.value = 0

        sleep(5000).then(() => {
          errors[e.target.name] = null
          // Set e.target.value to null to avoid the error message to be shown but mantain the other error messages
          setErrors({ ...errors, [e.target.name]: null })
        })
      }

      e.target.value = Math.round(e.target.value)
    }

    setSprint({
      ...sprint,
      content_type: 'research',
      [e.target.name]: e.target.value,
      challenges_module: id,
      created_by: userId,
    })
  }

  function changeCheckbox(e, isChecked) {
    e.target.value = isChecked
    changeInput(e)
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function register() {
    storeModality({
      modality_id: parentModality?.modality_id,
      challenge_id: idSprint,
      tittle_modality: sprint?.tittle_modality,
      content: '<p><br></p>',
      is_child: parseInt(modalityId),
      minutes: 0,
      allow_survey: 0,
      created_by: userId,
    }).then(response => {
      if (response.success) {
        swalCustom.fire({
          title: 'Sprint creado exitosamente',
          icon: 'success',
          showDenyButton: true,
          showConfirmButton: true,
          denyButtonText: 'Seguir editando',
          confirmButtonText: 'Regresar al reto',
        }).then(result => {
          if (result.isConfirmed) {
            history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
          }
          if (result.isDenied) {
            history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${response.data}/edit`)
          }
        })
      }
      if(!response.success) {
        Swal.fire({
          title: 'Error!',
          text: 'Hubo un error al crear el sprint, por favor intente de nuevo.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        })
      }
    })
    .catch(error => {
      Swal.fire({
        title: 'Error!',
        text: 'Hubo un error al crear el sprint, por favor intente de nuevo.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      })
    })
  }

  return (
    <div>
      <Header />
      {(userType === 'operative' || userType === 'manager') && (
        <SprintForm
          isChild={true}
          changeCheckbox={changeCheckbox}
          changeInput={changeInput}
          errors={errors}
          register={register}
          title="Agregar Sprint Hijo"
        />
      )}
    </div>
  )
}
