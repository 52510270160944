// DEV
import axios from "axios";
import { useState } from "react";
// const BOT_BACKEND_URL = "http://localhost:5000"
const BOT_BACKEND_URL = "https://sofiawithsteroids.naoincubacion.com.mx";

const useSofia = () => {
  const [botIsWriting, setBotIsWriting] = useState(false);

  // * Try to get a response from LLM API.
  // * If not possible, returns null botResponse and the presented error
  const getBotResponse = async (question, userId) => {
    // Start Loading Animation
    setBotIsWriting(true);
    let response = null;
    try {
      response = await axios.post(`${BOT_BACKEND_URL}/mentor`, {
        message: question,
        user_id: userId,
      });
      console.log("response:", response);
      // Error
    } catch (error) {
      console.error("Error fetching with useSofia hook:", error); // TODO: Log into some DB or Logging system
      return [null, error];
    } finally {
      // End Loading Animation
      setBotIsWriting(false);
    }
    // Success
    const botResponse = await response.data;
    return [botResponse.response, null];
  };

  return {
    botIsWriting,
    getBotResponse,
  };
};

export default useSofia;
