import React from 'react'
import classNames from 'classnames'

import css from './Button.module.css'


export default function Button(props) {
  const {
    className,
    disabled,
    children,
    isicon,
  } = props

    return (
      <button
        {...props}
        className={classNames(className, css.root, {
          [css.light]: props.light,
          [css.discord]: props.discord === 'true',
          [css.icon]: isicon,
          [css.disabled]: disabled,
        })}
      >
        {children}
      </button>
    )
}
