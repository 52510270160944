import React from 'react'

import Header from '../../components/modules/dashboard/partials/Header'
import { useParams } from 'react-router-dom'
import { showUser, assignUser } from '../../service/module/Users'
import { getAllRoles } from '../../service/module/Roles'
import { getGroupsNoPaginate } from '../../service/module/Groups'
import AssignUserForm from '../../components/modules/dashboard/users/AssignUserForm'
import { useProfile } from '../../context/MainContext'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getAllModalities } from '../../service/module/Modalities';


export default function AddUsers() {
  const { id } = useParams()
  const { userId, userType } = useProfile()
  const [user, setUser] = React.useState({
    email: null,
    names: null,
    last_name: null,
    sur_name: null,
    birthdate: null,
    gender: 'female',
    user_type: 'learner',
  })
  const [roles, setRoles] = React.useState()
  const [groups, setGroups] = React.useState()

  const [isUserUpdated, setIsUserUpdated] = React.useState()
  const [errors, setErrors] = React.useState()
  const history = useHistory()
  const [userTemp, setUserTemp] = React.useState({
    email: null,
    names: null,
    last_name: null,
    sur_name: null,
    birthdate: null,
    gender: 'female',
    user_type: 'learner',
  })

  const [changed, setChanged] = React.useState(true)

  const [modalities, setModalities] =  React.useState()

  React.useEffect(() => { 
    isEqual(user, userTemp)
  },[user, userTemp])

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    async function getUser(id) {
      await showUser(id)
        .then(response => {
          const userFilled = response.data[0]
          setUser({
            ...userFilled.profile,
            ...userFilled,
            profile: null,
          })
          setUserTemp({
            ...userFilled.profile,
            ...userFilled,
            profile: null,
          })
        })
        .catch(() => {})
    }

    getUser(id)
  }, [id, isUserUpdated])

  async function getRoles() {
    await getAllRoles()
      .then(response => {
        setRoles(response.data.message ? [] : response.data)
      })
      .catch(err => {
        console.error(err.message)
      })
  }

  async function getGroups() {
    await getGroupsNoPaginate()
      .then(response => {
        setGroups(response.data?.message ? [] : response.data)
      })
      .catch(err => {
        console.error(err.message)
      })
  }

  async function getModalities() { 
    await getAllModalities()
    .then(response => { 
      setModalities(response.data)
    })
    .catch(err => console.log(err.message))
  }

  React.useEffect(() => {
    getRoles()
    getGroups()
    getModalities()
  }, [])

  function changeInput(e) {
    if (e.target.name === 'role_id') {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
        group_id: 0,
      })
    } else {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
      })
    }
    isEqual(user, userTemp)
  }

  async function assign() {

    if(user.group_id){ 
      const data = { 
        role_id: user.role_id,
        group_id: user.group_id,
        userId
      }

      await assignUser(id, data)
      .then(response => {
        setIsUserUpdated(true)
        Swal.fire({
          title: '¡Usuario asignado exitosamente!',
          icon: 'success',
          confirmButtonText: 'Regresar',
        }).then(_result => {
          history.push('/dashboard/users')
        })
      })
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(_result => {})
      })
    }else{ 
      Swal.fire({
        title: 'Aviso',
        text: 'Selecciona un grupo',
        icon: 'warning',
        confirmButtonText: 'Regresar',
      }).then(_result => {})
    }
  }

  function isEqual(user, userTemp){
    if(groups !== undefined){
      setChanged(true)
    }else if(user.role_id !== userTemp.role_id){
      setChanged(false)
    }else if(user.group_id !== userTemp.group_id){
      setChanged(false)
    }else{
      setChanged(true)
    }
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <AssignUserForm
          assign={assign}
          changeInput={changeInput}
          errors={errors}
          user={user}
          roles={roles}
          groups={groups}
          title="Asignar Usuario"
          changed={changed}
          modalities = {modalities}
        />
      )}
    </div>
  )
}
