import React from 'react'

import Header from '../../components/modules/dashboard/partials/Header'
import { storeUser } from '../../service/module/Users'
import UserForm from '../../components/modules/dashboard/users/UserForm'
import { useProfile } from '../../context/MainContext'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function AddUsers() {
  const { userType } = useProfile()
  const [user, setUser] = React.useState({
    email: null,
    names: null,
    last_name: null,
    sur_name: null,
    birthdate: null,
    gender: 'female',
    user_type: 'learner',
  })

  const [errors, setErrors] = React.useState()
  const history = useHistory()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  function changeInput(e) {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
  }

  function changeDate(e) {
    setUser({
      ...user,
      birthdate: e.format('yyyy-MM-DD'),
    })
  }

  async function register() {
    await storeUser(user)
      .then(response => {
        const message = {
          message: response.message,
        }
        Swal.fire({
          title: 'Usuario creado exitosamente',
          icon: 'success',
          confirmButtonText: 'Regresar',
        }).then(_result => {
          history.push('/dashboard/users')
        })
      })
      .catch(error => {
        setErrors(error.errors)
        console.log(error.errors)
        Swal.fire({
          title: 'Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(_result => {})
      })
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <UserForm
          register={register}
          changeInput={changeInput}
          changeDate={changeDate}
          errors={errors}
          title="Agregar Usuario"
        />
      )}
    </div>
  )
}
