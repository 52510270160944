import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Alert,
} from 'reactstrap'

import Button from '../partials/Button'
import classNames from 'classnames'
import css from './ResourceForm.module.css'

export default function ResourceFrom({
  message,
  title,
  changeInput,
  register,
  errors,
  resource,
  update,
  changed,
  goBack,
}) {
  const history = useHistory()

  return (
    <div className={css.root}>
      {message && typeof message === 'object' ? (
          <Alert color="danger">
            <strong>¡Ocurrio un error! |</strong>
          </Alert>
        ) : message ? (
          <Alert color="success">
            <strong>{update ? "¡Actualización exitosa!" : "Registro exitoso"}</strong>
          </Alert>
        ) : null}
      <div className={classNames(css.tableContainer, css.darkTableContainer)}>
        <div className={css.tableHeader}>
          <h3 className={css.title}>{title}</h3>
        </div>
        <form className={css.form}>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-title">
                Título
              </label>
              <input
                invalid={errors && errors.title ? true : false}
                onChange={changeInput}
                name="title"
                id="input-title"
                defaultValue={resource && resource.title}
                className={css.input}
                type="text"
              />
              {errors && errors.title && <div className={css.feedback}>{errors.title}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-number">
                Número
              </label>
              <input
                invalid={errors && errors.number ? true : false}
                onChange={changeInput}
                name="number"
                id="input-number"
                defaultValue={resource && resource.number}
                className={css.input}
                min="0"
                type="number"
              />
              {errors && errors.number && <div className={css.feedback}>{errors.number}</div>}
            </div>
          </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-description">
                Descripción
              </label>
              <textarea
                invalid={errors && errors.description ? true : false}
                name="description"
                onChange={changeInput}
                defaultValue={
                  resource && resource.description ? resource.description : ''
                }
                className={classNames(css.input, css.textarea)}
                placeholder="..."
                rows="4"
              />
              {errors && errors.description && (
                <div className={css.feedback}>{errors.description}</div>
              )}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-thumbnail-url">
                Thumbnail URL
              </label>
              <input
                invalid={errors && errors.thumbnail_url ? true : false}
                onChange={changeInput}
                name="thumbnail_url"
                id="input-thumbnail-url"
                defaultValue={resource && resource.thumbnail_url}
                className={css.input}
                type="text"
              />
              {errors && errors.thumbnail_url && (
                <div className={css.feedback}>{errors.thumbnail_url}</div>
              )}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-url">
                URL
              </label>
              <input
                invalid={errors && errors.url ? true : false}
                onChange={changeInput}
                name="url"
                id="input-url"
                defaultValue={resource && resource.url}
                className={css.input}
                type="text"
              />
              {errors && errors.url && <div className={css.feedback}>{errors.url}</div>}
            </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-type">
                Tipo de Recurso
              </label>
              <select
                name="type"
                onChange={changeInput}
                type="select"
                value={resource && resource.type}
                id="input-type"
                className={css.input}
              >
                <option value="" hidden>Selecciona una opción</option>
                <option value="file">Archivo</option>
                <option value="reading">Lectura</option>
                <option value="audio">Audio</option>
                <option value="video">Video</option>
              </select>
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-time">
                Tiempo (hrs)
              </label>
              <input
                invalid={errors && errors.time ? true : false}
                onChange={changeInput}
                name="time"
                id="input-time"
                defaultValue={resource && resource.time}
                className={css.input}
                min="0"
                type="number"
              />
              {errors && errors.time && <div className={css.feedback}>{errors.time}</div>}
            </div>
          </div>
        </form>
        <div className={css.tableFooter}>
          <nav aria-label="...">
            <div className={css.pagination}>
              <Button className={css.delete} type="button" onClick={() => {
                goBack()
              }}>
                Cancelar
              </Button>
              {register && (
                <Button type="button" onClick={register}>
                  Registrar
                </Button>
              )}
              {update && (
                <Button type="button" onClick={update} disabled={changed}>
                  Actualizar
                </Button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
