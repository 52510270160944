import React from 'react'

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Banner from './Banner'
import Button from '../partials/Button'
import Feedback from './Feedback'
import Resources from './Resources'
import { getMission } from '../../../../service/module/Missions'
import EditorViewer from '../../dashboard/challenges/EditorViewer'

import css from './SprintContent.module.css'

export default function SprintContent({
  sprints,
  sprint,
  challenge,
  nextItem,
  previousItem,
  lastChallenge,
  resources,
  surveyIsVisible,
  saveSurvey,
  currentChallenge,
  nextText,
  nextInProgress,
  setNextInProgress,
  forceShowContinue = true
}) {
  const [mission, setMission] = React.useState()
  const [previusChallenge, setPreviusChallenge] = React.useState()
  const [nextChallenge, setNextChallenge] = React.useState()

  React.useEffect(() => {
    function setSprints() {
      if (
        sprint?.modality_content?.length > 0 &&
        sprint?.modality_content[0]?.content_type === 'parent' &&
        sprint?.modality_content[0]?.children?.length > 0
      ) {
        sprints.map((sp, index) => {
          if (sp.id === sprint.id) {
            if (index === 0) {
              setPreviusChallenge(null) // If is the first sprint, previus challenge is null
            } else {
              setPreviusChallenge(sprints[index - 1]) // If is not the first sprint, previous challenge is the previous sprint
            }

            // First challenge of the sprint
            setNextChallenge(sp.modality_content[0]?.children[0])
          }
        })
      } else if (sprint?.is_child) {
        var parent = sprints.find(
          sp => sp.modality_content[0].id === sprint.is_child
        )
        //TODO: Cambiar a for y hacer break
        parent?.modality_content[0]?.children?.map((ch, index) => {
          if (ch.id === sprint.id) {
            // If is the first child, previus challenge is the previous sprint to the parent
            if (index === 0) {
              var parentSprintIndex = sprints.indexOf(parent)
              // If parent is the first sprint, previus challenge is null
              if (parentSprintIndex === 0) {
                setPreviusChallenge(null)
              }

              // If parent is not the first sprint, previus challenge is the previous sprint
              if (parentSprintIndex !== 0) {
                setPreviusChallenge(sprints[parentSprintIndex - 1])
              }
            }

            // If is not the first child, previus challenge is the previous child
            if (index !== 0) {
              setPreviusChallenge(parent.modality_content[0]?.children[index - 1])
            }

            // If is not the last child, next challenge is the next child
            if (index !== parent.modality_content[0]?.children.length - 1) {
              setNextChallenge(parent.modality_content[0]?.children[index + 1])
            }

            // If is the last child, next challenge is the next sprint
            if (index === parent.modality_content[0]?.children.length - 1) {
              var parentSprintIndex = sprints.indexOf(parent)

              // If parent is the last sprint, next challenge is null
              if (parentSprintIndex === sprints.length - 1) {
                setNextChallenge(null)
              }

              // If parent is not the last sprint, next challenge is the next sprint
              if (parentSprintIndex !== sprints.length - 1) {
                // If next sprint is a parent, next challenge is the first child
                if (
                  sprints[parentSprintIndex + 1].modality_content[0]?.content_type === 'parent'
                ) {
                  setNextChallenge(sprints[parentSprintIndex + 1].modality_content[0]?.children[0])
                }

                // If next sprint is not a parent, next challenge is the next sprint
                if (
                  sprints[parentSprintIndex + 1].modality_content[0]?.content_type !== 'parent'
                ) {
                  setNextChallenge(sprints[parentSprintIndex + 1])
                }
              }
            }
          }
        })
      }else {
        sprints?.map((element, i) => {
          if (element?.id === sprint?.id) {
            if (i < sprints?.length - 1) {
              setNextChallenge(sprints[i + 1])

              // If next sprint is a parent, next challenge is the first child
              if (
                sprints[i + 1]?.modality_content[0]?.content_type === 'parent'
              ) {
                setNextChallenge(sprints[i + 1].modality_content[0]?.children[0])
              }
            } else {
              setNextChallenge(null)
            }

            if (i === 0) {
              setPreviusChallenge(null)
            } else {
              setPreviusChallenge(sprints[i - 1])

              // If previous sprint is a parent, previous challenge is the last child
              if (
                sprints[i - 1]?.modality_content[0]?.content_type === 'parent'
              ) {
                setPreviusChallenge(sprints[i - 1].modality_content[0]?.children[sprints[i - 1].modality_content[0]?.children.length - 1])
              }
            }
          }
        })
      }
    }

    function replaceLink(sprint){ 
      if(sprint?.content.includes('rel=')){ 
        let newContent = sprint.content.replaceAll('rel=', 'target="_blank" rel="noopener"')
        sprint.content = newContent
      }

      if(sprint?.content.includes('<a')){ 
        let newContent = sprint.content.replaceAll('<a', '<a target="_blank" rel="noopener"')
        sprint.content = newContent
      }
    }

    setSprints()
    replaceLink(sprint)
  }, [sprint, sprint?.modality_content, sprints])

  React.useEffect(() => {
    async function getIntro() {
      await getMission(1).then(response => {
        setMission(response.data)
      })
    }

    getIntro()
  }, [])

  return (
    
    <div className={css.root}>
      
      <div className={css.container}>
        <Banner
          img={mission && mission[0].cover_photo}
          title={
            challenge &&
            challenge !== 'undefined' &&
            sprint &&
            `${sprint.name || sprint.tittle_modality}`
          }
          minutes={sprint && sprint.minutes}
          image={sprint && sprint.banner}
        />
        <EditorViewer content={sprint && sprint.content} />
        {resources && <Resources items={resources} />}
        {sprint && sprint.action_name && sprint.action_url &&  sprint.action_name !== 'null' && sprint.action_url !== 'null' && (
          <div className={css.actions}>
            <a href={sprint && sprint.action_url} target="_blank" rel="noopener noreferrer" className={css.actionButton}>
              {sprint && sprint.action_name && sprint && sprint.action_name}
            </a>
          </div>
        )}
      </div>
        <div className={css.navigation}>
          {previusChallenge && (
            <div className={css.navButton}>
              <div className={css.continueButtonContainer}>
                <Button
                  newButton={true}
                  isicon={true}
                  className={css.backButton}
                  disabled={previusChallenge === null && true}
                  disableRipple={true}
                  onClick={() => previousItem(previusChallenge)}
                >
                  <ArrowRightAltIcon />
                </Button>
              </div>
              <h4 className={css.continueChallegeText}>Atrás</h4>
            </div>
          )}
          <div className={css.navButton}>
            <div className={css.continueButtonContainer}>
              <Button
                newButton={true}
                isicon={true}
                className={css.nextButton}
                disabled={nextInProgress}
                onClick={() => {
                  setNextInProgress(true)
                  nextItem(nextChallenge)
                }}
              >
                <ArrowRightAltIcon />
              </Button>
            </div>
            <h4 className={css.continueChallegeText}>{nextText}</h4>
          </div>
        </div>
      {sprint?.allow_survey === 1 && (
        <Feedback saveSurvey={saveSurvey} surveyIsVisible={surveyIsVisible} isFinal={true} />
      )}
    </div>
  )
}
