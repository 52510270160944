import React from 'react'
import { useHistory } from 'react-router'

import uuid from 'react-uuid'

import {
  makeStyles,
  withStyles,
  IconButton,
  Avatar,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import Button from '../partials/Button'

import uploadFileToBlob, { isStorageConfigured } from '../../../../hooks/AzureBlobStorage'

import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import defaultAvatar from '../../../../assets/default-user-profile.png'
import { updateImageProfile } from '../../../../service/module/Users'
import { logoutApi } from '../../../../service/module/Auth'
import { removeToken } from '../../../../service/HelperServices'

import css from './TopDrawer.module.css'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 150,
    backgroundColor: '#F6F6F6',
    paddingTop: 30,
    paddingBottom: 30,
  },
  avatarContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'start',
  },
  avatar: {
    width: 95,
    height: 95,
    position: 'relative',
  },
  avatarOverlay: {
    position: 'absolute',
    width: 95,
    height: 95,
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 1,
    },
  },
  cameraIcon: {
    width: '30px !important',
    height: 30,
    color: '#fff',
  },
  cameraLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: '#fff',
    fontWeight: 600,
    lineHeight: 1.66,
    margin: 0,
  },
  name: {
    fontFamily: "Baskerville BT",
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: 0,
    marginBottom: 0,
    color: '#000',
    whiteSpace: 'nowrap',
  },
  userName: {
    fontSize: theme.typography.pxToRem(16),
    color: '#000',
  },
  hiddenOverflow: {
    overflow: 'hidden',
  },
  userType: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#000',
  },
  progressText: {
    paddingLeft: 10,
    marginTop: 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: '#54565A',
  },
  blueText: {
    color: '#004DFF',
  },
  role: {
    margin: 0,
  },
  roleBlue: {
    fontWeight: 'bold',
    color: '#004DFF',
    margin: 0,
  },
}))

const ProfileButton = withStyles(() => ({
  root: {
    display: 'block',
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton)

export default function TopDrawer({
  user,
  id,
  userId,
  setImage,
  role,
  modality,
  logout,
}) {
  const classes = useStyles()
  const history = useHistory()
  const inputFileRef = React.useRef(null)
  const [imageProfile, setImageProfile] = React.useState(null)
  const [names, setNames] = React.useState(null)
  const [alias, setAlias] = React.useState(null)
  const [uploading, setUploading] = React.useState(false)

  
  isStorageConfigured()

  const onFilechange = async event => {
    setUploading(true)
    const originalFile = event.target.files[0]
    const extension = originalFile.name.split('.')[1]
    const newName = uuid()
    const file = new File([originalFile], `${newName}.${extension}`, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
    const objectUrl = URL.createObjectURL(file)

    const urlUploadedFile = await uploadFileToBlob(file)

    let data = {
      image_profile: urlUploadedFile,
    }

    await updateImageProfile(userId, data)
      .then(() => {
        setImageProfile(objectUrl)
        setImage(objectUrl)
        setUploading(false)
      })
      .catch()

    return () => URL.revokeObjectURL(objectUrl)
  }

  React.useEffect(() => {
    setImageProfile(
      user != null && user.profile?.image_profile != null
        ? user.profile?.image_profile
        : defaultAvatar
    )
    setNames(
      user && (user?.allow_public_name === 1 || id?.toString() === userId?.toString()) ? user.names : ''
    )
    setAlias(
      user && user?.alias !== null ? user.alias : ''
    )
  }, [user])

  const onBtnClick = () => {
    inputFileRef.current.click()
  }

  async function userLogout() {
    logout()
    await logoutApi()
      .then(response => {
        if (response.success) {
          removeToken()
          history.push('/')
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={uploading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={css.root}>
        <div className={classes.avatarContainer}>
          <input
            style={{ display: 'none' }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            ref={inputFileRef}
            onChange={onFilechange}
          />
          <ProfileButton
            onClick={e => {
              if (`${id}` === `${userId}`) {
                onBtnClick()
              }
            }}
          >
            <Avatar alt="" src={imageProfile} className={classes.avatar} />
            {`${id}` === `${userId}` && (
              <div className={classes.avatarOverlay}>
                <FontAwesomeIcon icon={faCamera} className={classes.cameraIcon} />
                <span className={classes.cameraLabel}>
                  Cambiar foto
                </span>
              </div>
            )}
          </ProfileButton>
        </div>
        <div className={css.info}>
          <h5 className={classes.name}>
            {names ? names : ''}
          </h5>
          <p className={classes.userName}>
            {alias ? '@' + alias : ''}
          </p>
          {role && (
            <h5 className={classes.userType}>
              {user && role ? (
                user.user_type === 'learner' ? (
                  <p className={css.role}>
                    Cursando
                    {role.split(" ").length > 1 ? (<br />) : (" ")}
                    <span className={classes.roleBlue}>{role}</span>
                  </p>
                ) :"Mentor"
              ) : null}
              {modality && <span className={css.subtext}>Modalidad {modality.modality}</span>}
            </h5>
          )}
          {`${id}` === `${userId}` && (
            <Button
              className={css.logout}
              newButton={true}
              light="true"
              title="Cerrar Sesión"
              onClick={e => userLogout()}
            />
          )}
        </div>
      </div>
    </>
  )
}
