import Axios from 'axios'
import {
  getMissionsAllURL,
  getMissionsURL,
  getChallengesModulesURL,
  getChallengesURL,
  getRegisterChallengeURL,
  getChallengeCompleteURL,
  getPercentageURL,
  getMissionsWholeURL,
  getMissionByIdURL,
} from '../../const/Constants'

var instance = Axios.create({
  baseURL: `${process.env.REACT_APP_CMS_API}api/v2`,
})

export async function getMissionsForPage(page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getMissionsURL() + page)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getMissionsWhole(ids) {
  return new Promise((resolve, reject) => {
    instance
      .get(getMissionsWholeURL() + `/get-missions-by-ids/${ids}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getAllMissions() {
  return new Promise((resolve, reject) => {
    instance
      .get(getMissionsAllURL())
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getMission(missionId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getMissionByIdURL(missionId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getChallengesComplete(userId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getChallengeCompleteURL(userId) )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getPercentageComplete(missionId, roleId, userId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getPercentageURL(missionId, roleId, userId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function registerChallenge(userId, challengeId, moduleId, modalityContentId) {
  return new Promise((resolve, reject) => {
    instance
      .post(getRegisterChallengeURL() , {
        user_id: userId,
        challenge_id: challengeId,
        challenges_module: moduleId,
        modality_content_id: modalityContentId
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getChallengesModules(groupId, roleId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getChallengesModulesURL(groupId, roleId))
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getChallenges(moduleId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getChallengesURL + `/get-modules-challenges/${moduleId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
