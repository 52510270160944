import Axios from 'axios'
import {
  getSeniorityURL,
  getSurveyURL,
  getUsersURL,
  getUserSurveyURL,
  getUsersWholeURL,
  getUsersTypeURL,
  getUsersCreateURL,
  getUsersDeleteURL,
  getUsersIdURL,
  getSendEmailURL
} from '../../const/Constants'

export async function getAllUsers(page = 1) {
  return new Promise((resolve, reject) => {
    Axios.get(getUsersURL() + `/get-users/${page}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getUsersFor(type, page, keyword) {
  return new Promise((resolve, reject) => {
    Axios.get(getUsersTypeURL(type, page, keyword))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function storeUser(data) {
  return new Promise((resolve, reject) => {
    Axios.post(getUsersCreateURL(), `/create-user/${data}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function deleteUser(userId) {
  return new Promise((resolve, reject) => {
    Axios.delete(getUsersDeleteURL() + `/delete-user/${userId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getReadStream(file) {
  return new Promise((resolve, reject) => {
    Axios.put(getUsersURL() + '/file', file)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function showUser(userId) {
  return new Promise((resolve, reject) => {
    Axios.get(getUsersIdURL(userId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function updateUser(userId, data) {
  return new Promise((resolve, reject) => {
    Axios.put(getUsersURL() + `/update-user/${userId}`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function assignUser(userId, data) {
  return new Promise((resolve, reject) => {
    Axios.post(getUsersURL() + `/assign-user/${userId}`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function updateImageProfile(userId, data) {
  return new Promise((resolve, reject) => {
    Axios.put(getUsersURL() + `/change-image-profile/${userId}`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getUsersWhole(userIds) {
  return new Promise((resolve, reject) => {
    Axios.get(getUsersWholeURL() + `/get-users-profile-by-ids/${userIds}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getSurvey(challengeId, userId) {
  return new Promise((resolve, reject) => {
    Axios.get(getUserSurveyURL(challengeId, userId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function setSurvey(data) {
  return new Promise((resolve, reject) => {
    Axios.post(getSurveyURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getSeniority() {
  return new Promise((resolve, reject) => {
    Axios.get(getSeniorityURL())

      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error.response.data))
  })
}

export async function getUserByEmail(email) {
  return new Promise((resolve, reject) => {
    Axios.get(getUsersURL() + '/get-user-by-email/' + email)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error.response.data))
  })
}

export async function findUsers(keyword) {
  return new Promise((resolve, reject) => {
    Axios.get(getUsersURL() + '/find-users/' + keyword)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error.response.data))
  })
}

export async function sendEmail(userId) { 
  return new Promise((resolve, reject) => { 
    Axios
    .get(getUsersURL() + '/send-mail/unsuscribe-user/' + userId)
    .then(response => { 
      resolve(response.data)
    })
    .catch(error => { 
      reject(error)
    })
  })
}