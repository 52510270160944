import { NavLink } from 'react-router-dom'

import classNames from 'classnames'

import css from './MainHeader.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faUserFriends, faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons'

const MainHeader = ({ 
  counterUsers, 
  counterGroups,
  counterStudentUsers,
  counterStudentsInTeams,
}) => {
  return (
    <>
      <div className={css.root}>
        <div className={css.container}>
          {/* Card stats */}
          <NavLink exact to="/dashboard/users" className={css.card}>
            <div className={classNames(css.cardText, css.cardItem)}>
              <h5 className={css.cardTitle}>
                Usuarios en plataforma
              </h5>
              <span className={css.cardNumber}>{counterUsers}</span>
            </div>
            <div className={css.cardItem}>
              <div className={css.cardIcon}>
                <FontAwesomeIcon icon={faUsers}/>
              </div>
            </div>
          </NavLink>
          <NavLink exact to="/dashboard/groups" className={css.card}>
            <div className={classNames(css.cardText, css.cardItem)}>
              <h5 className={css.cardTitle}>
                Grupos
              </h5>
              <span className={css.cardNumber}>{counterGroups}</span>
            </div>
            <div className={css.cardItem}>
              <div className={css.cardIcon}>
                <FontAwesomeIcon icon={faBriefcase}/>
                </div>
            </div>
          </NavLink>
          <NavLink exact to="#" className={css.card}>
            <div className={classNames(css.cardText, css.cardItem)}>
              <h5 className={css.cardTitle}>
                Aprendedores Totales
              </h5>
              <span className={css.cardNumber}>{counterStudentUsers}</span>
            </div>
            <div className={css.cardItem}>
              <div className={css.cardIcon}>
                <FontAwesomeIcon icon={faUserGraduate}/>
              </div>
            </div>
          </NavLink>
          <NavLink exact to="#" className={css.card}>
            <div className={classNames(css.cardText, css.cardItem)}>
              <h5 className={css.cardTitle}>
                Aprendedores asignados a un grupo
              </h5>
              <span className={css.cardNumber}>{counterStudentsInTeams}</span>
            </div>
            <div className={css.cardItem}>
              <div className={css.cardIcon}>
              <FontAwesomeIcon icon={faUserFriends}/>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default MainHeader
