import React from 'react'
import Swal from 'sweetalert2'

import { useHistory, useParams } from 'react-router-dom'

import Header from '../../components/modules/dashboard/partials/Header'
import RolesForm from '../../components/modules/dashboard/roles/RolesForm'
import { useProfile } from '../../context/MainContext'
import { showRole, updateRol, deleteChallengeRole} from '../../service/module/Roles'
import { getAllModalities, changeChallengeVisibilityStatus } from '../../service/module/Modalities'

import css from './EditRole.module.css'

export default function EditRole() {
  const { id } = useParams()
  const [message, setMessage] = React.useState()
  const [modalityUpdated, setModalityUpdated] = React.useState(false)
  const [role, setRole] = React.useState({})
  const [errors, setErrors] = React.useState()
  const [modalities, setModalities] = React.useState([{}])
  const [roleTemp, setRoleTemp] = React.useState({})
  const [changed, setChanged] = React.useState(true)
  const { userId, userType } = useProfile()
  const history = useHistory()

  
  React.useEffect(() => {
    async function getModalities() {
      await getAllModalities()
        .then(response => {
          setModalities(response.data)
        })
        .catch(() => { })
    }

    getModalities()
  }, [])

  React.useEffect(() => { 
    isEqual(role, roleTemp)
  },[role, roleTemp])
  
  React.useEffect(() => {
    async function getRole(id) {
      await showRole(id)
        .then(response => {
          const roleFilled = response.data
          setRole({
            ...roleFilled,
          })

          setRoleTemp(response.data)
        })
        .catch(() => {})
    }

    getRole(id)
  }, [id])

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function update() {
    role.user_id = userId
    await updateRol(id, role)
      .then(response => {
        if (response.success) {
          swalCustom.fire({
            title: 'Perfil actualizado exitosamente',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: 'Seguir editando',
            confirmButtonText: 'Ver todos los perfiles',
          }).then(result => {
            if (result.isConfirmed) {
              history.push('/dashboard/roles')
            }
            if (result.isDenied) {
              history.push(`/dashboard/roles/${id}/edit`)
            }
          })
        }
        if (!response.success) {
          setErrors(response.errors)
          swalCustom.fire({
            title: '¡Error!',
            text: 'Ha ocurrido un error',
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(_result => {})
        }
      })
      .catch(error => {
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        }).then(_result => {})
      })
  }

  async function deleteChallenge(idChallenge){ 
    await deleteChallengeRole(idChallenge, {"created_by": userId })
    .then(response => { 
      setMessage(response.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  }

  function changeInput(e) {
    setRole({
      ...role,
      [e.target.name]: e.target.value,
    })
  }

  function isEqual(role, roleTemp){
      if(role.name !== roleTemp.name){
    setChanged(false)
  } else {
    setChanged(true)
  } 
  }

  const turnOnOffModality = async (e, challenge_module) => {
    const { name, checked } = e.target

    const data = {
      role_id: id,
      challenge_module,
      modality_id: name,
      visible: checked ? 1 : 0,
    }

    await changeChallengeVisibilityStatus(data)
      .then(response => {
        if (response?.success) {
          setMessage("La visibilidad del reto ha sido actualizada correctamente")
          setModalityUpdated(true)

          setTimeout(() => {
            setMessage(null)
            setModalityUpdated(false)
          }, 5000)
        }
        if (!response?.success) {
          setMessage({
            message: "Ha ocurrido un error al actualizar la visibilidad del reto"
          })
          setTimeout(() => {
            setMessage(null)
          }, 5000)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div>
      <Header />
      {userType === 'operative' && (
        <RolesForm
          title="Editar Perfil"
          role={role}
          changeInput={changeInput}
          errors={errors}
          update={update}
          id={id}
          changed = {changed}
          deleteChallenge = {deleteChallenge}
          modalities={modalities}
          turnOnOffModality={turnOnOffModality}
          message={message}
          setMessage={setMessage}
          modalityUpdated={modalityUpdated}
          setModalityUpdated={setModalityUpdated}
        />
      )}
    </div>
  )
}
