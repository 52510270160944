import React from 'react'

import { useHistory } from 'react-router-dom'

import { Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import _ from 'lodash'

import Editor from '../challenges/Editor'
import Button from '../partials/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { removeExternalResource } from '../../../../service/module/Challenges'
import { deleteModality } from '../../../../service/module/Modalities'
import checkIcon from '../../../../assets/Check.png'
import css from './SprintForm.module.css'
import { Modal } from '../../../../components'
import { AssignNewModality } from '../../../../forms'
import { $insertNodes, $getRoot } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";

export default function SprintFrom({
  message,
  title,
  changeInput,
  register,
  errors,
  update,
  changeCheckbox,
  modalities,
  sprint, // Active Modality
  meta,
  metaResources,
  list,
  setList,
  listResources,
  pages,
  pagesResources,
  setCurrentPage,
  setCurrentPageResources,
  id,
  idSprint,
  isChild,
  editorRef,
  formRef,
  changeActiveModality,
  validateChanges,
  addModality,
  saveModality,
  showNewModalityModal,
  setShowNewModalityModal,
  initialSprint,
  setInitialSprint,
  handleNameCHange,
  enableEditName,
  setEnableEditName,
  modalityTitle,
  setShowDeleteModalityModal,
  realSprint,
  setDeleteResources
}) {

  const history = useHistory()
  const [isChecked, setIsChecked] = React.useState(false)
  const [hasButton, setHasButton] = React.useState(false)
  const [contentTypeSelected, setContentTypeSelected] = React.useState(null)
  const [isSprintDeleted, setIsSprintDeleted] = React.useState()
  const [messageDeleted, setMessageDeleted] = React.useState()

  React.useEffect(() => { 
    if(!hasButton) { 
      if (sprint && sprint?.action_name !== null){
        sprint.action_name = null
        sprint.action_url = null
      }
    }
  }, [hasButton])

  React.useEffect(() => {
    if (initialSprint === null || (update && _.isEmpty(initialSprint))) {
      setInitialSprint(sprint)
    }

    if ((sprint && sprint.allow_survey === "true") || sprint?.allow_survey === 1) {
      setIsChecked(true)
    }

    if (sprint && sprint?.action_name !== null && sprint?.action_name !== 'null'){
      setHasButton(true)
    } else {
      setHasButton(false)
    }

    if (sprint && contentTypeSelected === null) {
      setContentTypeSelected(sprint?.content_type !== 'parent' ? 0 : 1)
    }

  }, [isChild, sprint, realSprint])

  React.useEffect(() => {
    if (initialSprint && editorRef.current) {
      editorRef.current.update((editor) => {
        $getRoot().clear();
        const parser = new DOMParser();
        const dom = parser.parseFromString(`<div>${initialSprint.content}</div>`, "text/html");
        const nodes = $generateNodesFromDOM(editorRef.current, dom);

        try {
          $insertNodes(nodes);
        } catch (error) {
          if (error.message === "rootNode.append: Only element or decorator nodes can be appended to the root node") {
            const dom = parser.parseFromString(`<p>${initialSprint.content}</p>`, "text/html");
            const nodes = $generateNodesFromDOM(editorRef.current, dom);
            $insertNodes(nodes);
          }
        }
      })
    }
  }, [initialSprint, editorRef])

  function add(e) {
    e.preventDefault()
    history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${sprint?.id}/child/add`)
  }

  function addResource(e) {
    e.preventDefault()
    history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/add/${sprint?.id}`)
  }

  function editResouceAction(resourceId) {
    history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/${resourceId}/edit/${sprint?.id}`)
  }

  function editSprintAction(sprintId) {
    history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${sprint?.id}/child/${sprintId}/edit`)
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  function previousResources(e) {
    e.preventDefault()
    if (metaResources.previous_page_url) {
      setCurrentPageResources(metaResources.previous_page_url)
    }
  }

  async function deleteRoleAction(sprintId) {
    await deleteModality(sprintId)
      .then(response => {
        if(response.success){
          setMessageDeleted('Sprint eliminado correctamente')
          setIsSprintDeleted(!isSprintDeleted)
  
          // remove sprint from list
          const index = list.findIndex(sprint => sprint.id === sprintId)
          list.splice(index, 1)
          setList([...list])

          setTimeout(() => { 
            setMessageDeleted('')
          }, 3500)
        }else {
          setMessageDeleted(response.message)
        }
       
      })
      .catch(error => {
        setMessageDeleted(error.message)
      })
  }

  async function deleteResourceAction(resourceId){ 
    await removeExternalResource(resourceId)
    .then(response => { 
      setMessageDeleted("Recurso eliminado correctamente")
      
      setTimeout(() => {
        setMessageDeleted(null)
      }, 5000)
      setDeleteResources(id)
    })
    .catch(error => { 
      setMessageDeleted({
          message: "Ha ocurrido un error al actualizar el orden de los retos"
        })
        setTimeout(() => {
          setMessageDeleted(null)
        }, 5000)
    })
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
      icon: css.warningIcon,
      icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id) {
    swalCustom.fire({
      title: '¿Estás seguro que deseas quitar este sprint?',
      iconHtml: '<div className="' + css.warningIcon + '"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Quitar sprint',
    }).then(_result => {
      if (_result.isDenied) {
        deleteRoleAction(id)
      }
    })
  }

  function toggleModalResource(id){ 
    swalCustom.fire({
      title: "¿Estás seguro que deseas eliminar el recurso?",
      iconHtml: '<div className ="' + css.warningIcon + '"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Eliminar recurso'
    }).then(_result => { 
      if(_result.isDenied){ 
        deleteResourceAction(id)
      }
    })
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage('')
      setCurrentPage(meta.next_page_url)
    }
  }

  function nextResources(e) {
    e.preventDefault()
    if (metaResources.next_page_url) {
      setCurrentPage('')
      setCurrentPage(metaResources.next_page_url)
    }
  }

  return (
    <>
      <div className={css.root}>
        {messageDeleted && typeof messageDeleted === 'object' ? (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt="" />
            <strong>{messageDeleted.message}</strong>
          </Alert>
        ) : messageDeleted ? (
          <Alert className={css.alert}>
            <img className={css.alertIcon} src={checkIcon} alt=""/><strong>{messageDeleted}</strong>
          </Alert>
        ) : null}

        {message && typeof message === 'object' ? (
          <Alert className={css.alert}>
            <strong>{message.message}</strong>
          </Alert>
        ) : message ? (
          <Alert className={css.alert}>
            <strong>{message}</strong>
          </Alert>
        ) : null}
        <div className={classNames(css.tableContainer, css.darkTableContainer)}>
          <div className={css.tableHeader}>
            {update && enableEditName ? (
              <form id="nameForm" onSubmit={handleNameCHange} className={css.nameForm}>
                <input
                  type="text"
                  name="name"
                  defaultValue={isChild && isChild ? sprint?.tittle_modality : sprint?.name}
                  className={css.input}
                  autoFocus
                />
                <Button type="submit" className={css.saveButton} form="nameForm">
                  Guardar
                </Button>
              </form>
            ) : (
              <div>
                <h3 className={css.title}>
                  {title}
                  {update && (
                    <span
                      className={css.editNameButton}
                      onClick={() => setEnableEditName(true)}
                      title="Editar nombre del sprint"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                  )}
                </h3>
                {modalityTitle && (
                  <div className={classNames(css.tab, css.active)}>{modalityTitle}</div>
                )}
              </div>
            )}
          </div>
          <div className={css.tabBar}>
            {modalities &&
              modalities.length > 0 &&
              modalities.map((modality, index) => (
                <div
                  key={index}
                  className={classNames(css.tab, {
                    [css.active]: modality?.modality === sprint?.modality,
                  })}
                  onClick={() => {
                    changeActiveModality(modality)
                  }}
                >
                  {modality.modality}
                </div>
              ))}
            {update && modalities?.length < 3 && contentTypeSelected !== 1 && (
              <div
                className={css.newTab}
                onClick={() => {
                  validateChanges()
                }}
              >
                + Agregar modalidad
              </div>
            )}
          </div>

          {update && !isChild && (
            <div className={css.subTab}>
              <h3 className={css.modalityTitle}>Modalidad {sprint?.modality}</h3>
              <Button
                type="button"
                className={css.deleteModalityButton}
                onClick={() => {
                  setShowDeleteModalityModal(true)
                }}
              >
                Borrar
              </Button>
            </div>
          )}

          <form className={css.form} ref={formRef}>
            {!update && (
              <div className={css.inputsResponsive}>
                <div className={css.inputHolder}>
                  <label className="form-control-label" htmlFor="tittle_modality">
                    Nombre
                  </label>
                  <input
                    invalid={errors && errors.tittle_modality ? true : false}
                    onChange={changeInput}
                    name="tittle_modality"
                    id="tittle_modality"
                    defaultValue={sprint && sprint.tittle_modality}
                    className={css.input}
                    type="text"
                    disabled={update ? true : false}
                  />
                  {errors && errors.tittle_modality && (
                    <div className={css.feedback}>{errors.tittle_modality}</div>
                  )}
                </div>

                <div className={css.inputs}>
                  <div className={css.inputHolder}>
                    <label className="form-control-label" htmlFor="challenge_order">
                      Orden
                    </label>
                    <input
                      invalid={errors && errors.challenge_order ? true : false}
                      onChange={changeInput}
                      name="challenge_order"
                      id="number"
                      defaultValue={sprint && sprint.challenge_order}
                      className={css.input}
                      type="number"
                      step="1"
                      min="0"
                      disabled={update ? true : false}
                    />
                    {errors && errors.challenge_order && (
                      <div className={css.feedback}>{errors.challenge_order}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!isChild && (
              <div className={classNames(css.inputs, css.inputHolder)}>
                <input
                  type="checkbox"
                  checked={contentTypeSelected && contentTypeSelected === 1 ? true : false}
                  onChange={(e) => {
                    var value = e.target.checked === true ? 'parent' : 'research'
                    setContentTypeSelected(value && value === 'parent' ? 1 : 0)
                    changeInput({ target: { name: 'content_type', value: value } })
                  }}
                  name="content_type"
                  id="content_type"
                  disabled={update ? true : false}
                />
                <label
                  className="form-control-label"
                  htmlFor="content-type"
                  style={{ marginLeft: '8px' }}
                >
                  Agregar sprints hijos dentro
                </label>
                {errors && errors.content_type && (
                  <div className={css.feedback}>{errors.content_type}</div>
                )}
              </div>
            )}
            {update && contentTypeSelected !== undefined && parseInt(contentTypeSelected) === 0 ? (
              <>
                <div>
                  <div className={css.inputs}>
                    <div className={css.halfInputHolder}>
                      <label className="form-control-label" htmlFor="minutes">
                        {`Tiempo (hrs)`}
                      </label>
                      <input
                        invalid={errors && errors.number ? true : false}
                        onChange={changeInput}
                        name="minutes"
                        id="minutes"
                        defaultValue={(sprint && sprint.minutes) || '0'}
                        min="0"
                        className={css.input}
                        type="number"
                        autoFocus={true}
                      />
                      {errors && errors.minutes && (
                        <div className={css.feedback}>{errors.minutes}</div>
                      )}
                    </div>
                    <div className={css.halfInputHolder}>
                      <label className="form-control-label" htmlFor="banner">
                        {`Imagen del sprint`}
                      </label>
                      <input
                        invalid={errors && errors.number ? true : false}
                        onChange={changeInput}
                        name="banner"
                        id="banner"
                        defaultValue={sprint && sprint.banner}
                        className={css.input}
                        type="text"
                      />
                      {errors && errors.banner && (
                        <div className={css.feedback}>{errors.banner}</div>
                      )}
                    </div>
                  </div>
                  <div className={css.secondaryInputs}>
                    <div className={css.inputHolder}>
                      <label className="form-control-label" htmlFor="content">
                        Contenido
                      </label>
                      <Editor
                        editorRef={editorRef}
                        name="content"
                        changeInput={(state, editor) => {}}
                      />
                      {errors && errors.content && (
                        <div className={css.feedback}>{errors.content}</div>
                      )}
                    </div>
                  </div>
                </div>
                {listResources && (
                  <div className={css.sprints}>
                    <div className={css.tableContainer}>
                      <div className={css.tableHeader}>
                        <h3 className={css.title}>Recursos Externos</h3>
                        <Button
                          className={css.addButton}
                          type="button"
                          onClick={() => {
                            validateChanges(isChild ? 'childResourses' : 'resources')
                          }}
                        >
                          Agregar
                        </Button>
                      </div>
                      <div className={css.table}>
                        <table className={css.table}>
                          <thead className={css.thead}>
                            <tr className={css.tr}>
                              <th className={css.th} scope="col">
                                Título
                              </th>
                              <th className={css.th} scope="col">
                                Descripción
                              </th>
                              <th className={css.th} scope="col">
                                Url Image
                              </th>
                              <th className={css.th} scope="col">
                                Url
                              </th>
                              <th className={css.th} scope="col">
                                Acciones
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {listResources &&
                              listResources.map((item, key) => {
                                return (
                                  <tr className={css.tr} key={key}>
                                    <th className={classNames(css.td, css.name)} scope="row">
                                      {item.title}
                                    </th>
                                    <td className={css.td}>{item.description}</td>
                                    <td className={css.td}>{item.thumbnail_url}</td>
                                    <td className={css.td}>{item.url}</td>
                                    <td>
                                      <Button
                                        isicon={true}
                                        className={css.edit}
                                        type="button"
                                        title="Editar"
                                        onClick={() => editResouceAction(item.id)}
                                      >
                                        <span>
                                          <FontAwesomeIcon icon={faEdit} />
                                        </span>
                                      </Button>

                                      <Button
                                        isicon={true}
                                        className={css.delete}
                                        type="button"
                                        tittle="Quitar"
                                        onClick={() => toggleModalResource(item.id)}
                                      >
                                        <span>
                                          <FontAwesomeIcon icon={faXmark} />
                                        </span>
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div className={css.tableFooter}>
                        {listResources && listResources.length > 0 ? (
                          <nav aria-label="...">
                            <Pagination
                              className="pagination justify-content-end mb-0"
                              listClassName="justify-content-end mb-0"
                            >
                              {metaResources && (
                                <PaginationItem
                                  className={!metaResources.previous_page_url ? 'disabled' : ''}
                                >
                                  <PaginationLink
                                    href={metaResources.first_page_url}
                                    onClick={previousResources}
                                    tabIndex={!metaResources.previous_page_url ? '-1' : undefined}
                                  >
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                    <span className="sr-only">Previous</span>
                                  </PaginationLink>
                                </PaginationItem>
                              )}
                              {pagesResources &&
                                pagesResources.map((item, key) => {
                                  /*
                                   Always show 9 pagesResources (if there are more than 9)
                                   show 4 pagesResources before and 4 pagesResources after the current page
                                  */
                                  if (metaResources.current_page <= 5 && key < 9) {
                                    return (
                                      <PaginationItem
                                        key={key}
                                        className={
                                          metaResources.current_page === item ? 'active' : ''
                                        }
                                      >
                                        <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                          {item}
                                          {metaResources.current_page === item && (
                                            <span className="sr-only">(current)</span>
                                          )}
                                        </PaginationLink>
                                      </PaginationItem>
                                    )
                                  }

                                  if (metaResources.current_page > 5) {
                                    if (
                                      key > metaResources.current_page - 6 &&
                                      key < metaResources.current_page + 4
                                    ) {
                                      return (
                                        <PaginationItem
                                          key={key}
                                          className={
                                            metaResources.current_page === item ? 'active' : ''
                                          }
                                        >
                                          <PaginationLink
                                            href={`/?page=${item}`}
                                            onClick={clickPage}
                                          >
                                            {item}
                                            {metaResources.current_page === item && (
                                              <span className="sr-only">(current)</span>
                                            )}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )
                                    }
                                  }

                                  if (metaResources.last_page - metaResources.current_page < 5) {
                                    if (key > metaResources.last_page - 10) {
                                      return (
                                        <PaginationItem
                                          key={key}
                                          className={
                                            metaResources.current_page === item ? 'active' : ''
                                          }
                                        >
                                          <PaginationLink
                                            href={`/?page=${item}`}
                                            onClick={clickPage}
                                          >
                                            {item}
                                            {metaResources.current_page === item && (
                                              <span className="sr-only">(current)</span>
                                            )}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )
                                    }
                                  }
                                })}
                              {metaResources && (
                                <PaginationItem
                                  className={!metaResources.next_page_url ? 'disabled' : ''}
                                >
                                  <PaginationLink
                                    href={metaResources.last_page_url}
                                    onClick={nextResources}
                                  >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                    <span className="sr-only">Next</span>
                                  </PaginationLink>
                                </PaginationItem>
                              )}
                            </Pagination>
                          </nav>
                        ) : (
                          <div className={css.noData}>
                            <p>No hay recursos</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={classNames(css.inputs, css.inputHolder)}>
                  <input
                    name="hasButton"
                    id="hasButton"
                    type="checkbox"
                    onChange={(e) => {
                      setHasButton(!hasButton)
                    }}
                    value={hasButton}
                    checked={hasButton}
                  />
                  <label
                    className="form-control-label"
                    htmlFor="hasButton"
                    style={{ marginLeft: '8px' }}
                  >
                    Agregar botón principal
                  </label>
                </div>

                {hasButton && (
                  <div className={css.inputs}>
                    {update &&
                    contentTypeSelected !== undefined &&
                    parseInt(contentTypeSelected) === 0 ? (
                      <>
                        <div className={css.halfInputHolder}>
                          <label className="form-control-label" htmlFor="action_name">
                            {`Botón principal`}
                          </label>
                          <input
                            invalid={errors && errors.number ? true : false}
                            onChange={changeInput}
                            name="action_name"
                            id="action_name"
                            defaultValue={
                              sprint && sprint.action_name && sprint.action_name !== 'null'
                                ? sprint.action_name
                                : ''
                            }
                            className={css.input}
                            type="text"
                          />
                          {errors && errors.action_name && (
                            <div className={css.feedback}>{errors.action_name}</div>
                          )}
                        </div>
                        <div className={css.halfInputHolder}>
                          <label className="form-control-label" htmlFor="action_url">
                            {'Url Botón principal'}
                          </label>
                          <input
                            invalid={errors && errors.number ? true : false}
                            onChange={changeInput}
                            name="action_url"
                            id="action_url"
                            defaultValue={
                              sprint && sprint.action_url && sprint.action_url !== 'null'
                                ? sprint.action_url
                                : ''
                            }
                            className={css.input}
                            type="text"
                          />
                          {errors && errors.action_url && (
                            <div className={css.feedback}>{errors.action_url}</div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
                <div className={classNames(css.inputs, css.inputHolder)}>
                  <input
                    name="allow_survey"
                    id="allow_survey"
                    type="checkbox"
                    onChange={(e) => {
                      setIsChecked(!isChecked)
                      changeCheckbox(e, !isChecked)
                    }}
                    value={isChecked}
                    checked={isChecked}
                  />
                  <label
                    className="form-control-label"
                    htmlFor="allow_survey"
                    style={{ marginLeft: '8px' }}
                  >
                    Permite Encuesta
                  </label>
                </div>
              </>
            ) : null}
            {update && contentTypeSelected !== undefined && parseInt(contentTypeSelected) === 1 && (
              <div className={css.sprints}>
                <div className={css.tableContainer}>
                  <div className={css.tableHeader}>
                    <h3 className={css.title}>Sprints Hijos</h3>
                    <Button className={css.addButton} onClick={add}>
                      Agregar
                    </Button>
                  </div>
                  <div className={css.table}>
                    <table className={css.table}>
                      <thead className={css.thead}>
                        <tr className={css.tr}>
                          <th className={css.th} scope="col">
                            Nombre
                          </th>
                          <th className={css.th} scope="col">
                            Número
                          </th>
                          <th className={css.th} scope="col">
                            Activo
                          </th>
                          <th className={css.th} scope="col">
                            Acciones
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list &&
                          !list.message &&
                          list.map((item, key) => {
                            return (
                              <tr className={css.tr} key={key}>
                                <th className={classNames(css.td, css.name)} scope="row">
                                  {item.tittle_modality}
                                </th>
                                <td className={css.td}>{item.number}</td>
                                <td className={css.td}>{item.active ? 'Sí' : 'No'}</td>
                                <td className={css.td}>
                                  <Button
                                    isicon={true}
                                    className={css.edit}
                                    type="button"
                                    title="Editar"
                                    onClick={() => editSprintAction(item.id)}
                                  >
                                    <span>
                                      <FontAwesomeIcon icon={faEdit} />
                                    </span>
                                  </Button>

                                  <Button
                                    isicon={true}
                                    className={css.delete}
                                    type="button"
                                    title="Quitar"
                                    onClick={() => toggleModal(item.id)}
                                  >
                                    <span>
                                      <FontAwesomeIcon icon={faXmark} />
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className={css.tableFooter}>
                    {list && list.length > 0 ? (
                      <nav aria-label="...">
                        <Pagination
                          className="pagination justify-content-end mb-0"
                          listClassName="justify-content-end mb-0"
                        >
                          {meta && (
                            <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                              <PaginationLink
                                href={meta.first_page_url}
                                onClick={previous}
                                tabIndex={!meta.previous_page_url ? '-1' : undefined}
                              >
                                <FontAwesomeIcon icon={faAngleLeft} />
                                <span className="sr-only">Previous</span>
                              </PaginationLink>
                            </PaginationItem>
                          )}
                          {pages &&
                            pages.map((item, key) => {
                              /*
                               Always show 9 pages (if there are more than 9)
                               show 4 pages before and 4 pages after the current page
                              */
                              if (meta.current_page <= 5 && key < 9) {
                                return (
                                  <PaginationItem
                                    key={key}
                                    className={meta.current_page === item ? 'active' : ''}
                                  >
                                    <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                      {item}
                                      {meta.current_page === item && (
                                        <span className="sr-only">(current)</span>
                                      )}
                                    </PaginationLink>
                                  </PaginationItem>
                                )
                              }

                              if (meta.current_page > 5) {
                                if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                                  return (
                                    <PaginationItem
                                      key={key}
                                      className={meta.current_page === item ? 'active' : ''}
                                    >
                                      <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                        {item}
                                        {meta.current_page === item && (
                                          <span className="sr-only">(current)</span>
                                        )}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )
                                }
                              }

                              if (meta.last_page - meta.current_page < 5) {
                                if (key > meta.last_page - 10) {
                                  return (
                                    <PaginationItem
                                      key={key}
                                      className={meta.current_page === item ? 'active' : ''}
                                    >
                                      <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                        {item}
                                        {meta.current_page === item && (
                                          <span className="sr-only">(current)</span>
                                        )}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )
                                }
                              }
                            })}
                          {meta && (
                            <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                              <PaginationLink href={meta.last_page_url} onClick={next}>
                                <FontAwesomeIcon icon={faAngleRight} />
                                <span className="sr-only">Next</span>
                              </PaginationLink>
                            </PaginationItem>
                          )}
                        </Pagination>
                      </nav>
                    ) : (
                      <div className={css.noData}>
                        <p>No hay datos para mostrar</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </form>
          <div className={css.tableFooter}>
            <nav aria-label="...">
              <div className={css.pagination}>
                <Button
                  className={css.delete}
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancelar
                </Button>
                {register && (
                  <Button color="buttonColor" type="button" onClick={register}>
                    Registrar
                  </Button>
                )}
                {update && !sprint?.new && (
                  <Button
                    color="buttonColor"
                    type="button"
                    onClick={() => {
                      update()
                    }}
                  >
                    Actualizar
                  </Button>
                )}
                {update && sprint?.new && (
                  <Button
                    color="buttonColor"
                    type="button"
                    onClick={() => {
                      saveModality()
                    }}
                  >
                    Guardar modalidad
                  </Button>
                )}
              </div>
            </nav>
          </div>
        </div>
        <Modal
          className={css.modal}
          show={showNewModalityModal || false}
          onClose={() => setShowNewModalityModal(false)}
        >
          <p className={css.modalTitle}>¿A qué modalidad corresponde este contenido?</p>
          <AssignNewModality
            onSubmit={(e) => {
              e.preventDefault()
              setShowNewModalityModal(false)
              addModality(e.target.from.value, e.target.modality.value)
            }}
            onCancel={() => setShowNewModalityModal(false)}
            activeModalities={modalities}
          />
        </Modal>
      </div>
    </>
  )
}