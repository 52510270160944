const Constants = {
  END_POINTS: {
    LOGIN: '/login',
    LOGOUT: '/logout',
    CHECK: '/check',
    ALL: '/all',
    GET: '/get',
    WHOLE: '/whole',
    SURVEY: '/survey',
    CHALLENGES: '/challenges',
    MODULES: '/modules',
    MODULE: '/module',
    CHALLENGE: '/challenge',
    ROLE: '/role',
    REGISTER: '/register',
    USER: '/user',
    MY_MISSIONS: '/my-missions',
    PROJECT: '/project',
    STEPS: '/steps',
    STEP: '/step',
    FIRST: '/first',
    TEAM: '/team',
    GROUP: '/group',
    RECOVERY: '/recovery',
    RESET_PASSWORD: '/reset/password',
    UPDATE_PASSWORD: '/update/password',
    FILE_UPLOAD: '/file/upload',
    FILE_REVIEW_UPLOAD: '/review/upload',
    REVIEWS: '/reviews',
    SPRINTS: '/sprints',
    CHILDS: '/childs',
    EXTERNAL_RESOURCES: '/external/resources',
    TYPEFORM: '/typeform',
    SENIORITY: '/seniority',
    ADD_CHALLENGES: '/add-challenge-to-role',
    CHALLENGES_BY_ROLE: '/challenges-by-role',
    UPDATE_CHALLENGES_BY_ROLE: '/update-challenge-to-role',
    CHALLENGE_FIND: '/find-by-name',
    MODALITIES_BY_SPRINT: '/get-modalities-by-challenge',
  },
  BASE_URLS: {
    AUTH: '/auth',
    USERS: '/users',
    MISSIONS: '/missions',
    TEAMS: '/teams',
    GROUPS: '/groups',
    ROLES: '/roles',
    CHALLENGES: '/challenges/modules',
    MODALITIES: '/modalities',
  },
  API_TOKEN: 'MODS_TOKEN',
}

export function getAuthLoginURL() {

  return Constants.BASE_URLS.AUTH + `/login-user`;
}

export function getAuthLogoutURL() {
  return Constants.BASE_URLS.AUTH + `/logout-user`;
}

export function getAuthCheckURL() {
  return Constants.BASE_URLS.AUTH + `/check-user`;
}

export function getAuthRecoveryURL() {
  return Constants.BASE_URLS.AUTH + `/recovery-user`;
}

export function getAuthResetPasswordURL() {
  return Constants.BASE_URLS.AUTH + `/reset-password`;
}

export function getAuthUpdatePasswordURL() {
  return Constants.BASE_URLS.AUTH + `/update-password`;
}

export function getUsersURL() {
  return Constants.BASE_URLS.USERS;
}

export function getUsersDeleteURL() {
  return Constants.BASE_URLS.USERS + `/delete-user`;
}

export function getUsersIdURL(userId) {
  return Constants.BASE_URLS.USERS + `/get-user-by-id/${userId}`;
}

export function getUsersTypeURL(type, page, keyword) {
  let query = ''

  if (page && keyword) query = `${page}&find=${keyword}`
  else if (page) query = `${page}`
  else if (keyword) query = `?find=${keyword}`

  return Constants.BASE_URLS.USERS + `/get-users-by-type/${type}${query}`;
}

export function getUsersCreateURL() {
  return Constants.BASE_URLS.USERS + `/create-user`;
}

export function getUsersWholeURL() {
  return Constants.BASE_URLS.USERS + `/get-users-profile-by-ids`;

}

export function getUserSurveyURL(challengeId, userId) {
  return (
    Constants.BASE_URLS.USERS + `/get-survey/${challengeId}/${userId}`
  );
}

export function getSurveyURL() {
  return (
    Constants.BASE_URLS.USERS +
    `/save-survey`
  );
}

export function getMissionsWholeURL() {
  return Constants.BASE_URLS.MISSIONS + /get-missions-by-ids/;
}

export function getMissionsURL() {
  return Constants.BASE_URLS.MISSIONS
}

export function getMissionByIdURL(missionId) {
  return Constants.BASE_URLS.MISSIONS + `/get-mission-by-id/${missionId}`;
}

export function getChallengeCompleteURL(userId) {
  return Constants.BASE_URLS.MISSIONS + `/get-challenges-completed/${userId}`;
}

export function getRegisterChallengeURL() {
  return Constants.BASE_URLS.MISSIONS + `/register-challenge`
}

export function getChallengesModulesURL(groupId, roleId) {
  return Constants.BASE_URLS.MISSIONS + `/get-modules-challenge-by-role/${groupId}/${roleId}`
}

export function getChallengesURL(moduleId) {
  return (
    Constants.BASE_URLS.MISSIONS +
    Constants.END_POINTS.CHALLENGES +
    Constants.END_POINTS.MODULE +
    `/${moduleId}`
  )
}

export function getPercentageURL(missionId, roleId, userId) {
  return Constants.BASE_URLS.MISSIONS + `/get-percentaje/${missionId}/${roleId}/${userId}`
}

export function createGroupURL() {
  return Constants.BASE_URLS.GROUPS + '/create-group'
}

export function getGroupsURL() {
  return Constants.BASE_URLS.GROUPS;
}

export function getTeamsCheckURL(missionId, userId) {
  return Constants.BASE_URLS.TEAMS + `/get-team-by-mission/${missionId}/${userId}`;
}

export function getTeamMyMissionURL(userId) {
  return Constants.BASE_URLS.TEAMS + Constants.END_POINTS.MY_MISSIONS + `/${userId}`
}

export function getMissionsAllURL() {
  return Constants.BASE_URLS.MISSIONS + Constants.END_POINTS.ALL
}

export function getRolesURL(page) {
  return Constants.BASE_URLS.ROLES + `/get-roles${page && page}`;
}

export function getAllRolesURL() {
  return Constants.BASE_URLS.ROLES + `/get-all-roles`;
}

export function getRolesCreateURL() {
  return Constants.BASE_URLS.ROLES + `/create-role`;
}

export function getRolesUpdateURL(rolId) {
  return Constants.BASE_URLS.ROLES + `/delete-role/${rolId}`
}

export function getRolesUpdateRoleURL(rolId) {
  return Constants.BASE_URLS.ROLES + `/update-role/${rolId}`
}

export function getRolesByIdURL(roleId) {
  return Constants.BASE_URLS.ROLES + `/get-role-by-id/${roleId}`;
}

export function getTypeformURL(rolId) {
  return Constants.BASE_URLS.ROLES + `/get-typeform/${rolId}`
}

export function getTeamsURL() {
  return Constants.BASE_URLS.TEAMS + `/get-teams`;
}

export function getReviewURL(userId) {
  return Constants.BASE_URLS.MISSIONS + `/${userId}` + Constants.END_POINTS.REVIEWS
}


export function getTeamURL(teamId) {
  return Constants.BASE_URLS.TEAMS + `/get-team-by-id/${teamId}`;
}

export function getTeamUpdateURL(teamId) {
  return Constants.BASE_URLS.TEAMS + `/${teamId}`
}

export function getTeamDeleteURL(teamId) {
  return Constants.BASE_URLS.TEAMS + `/delete-team/${teamId}`
}

export function getGroupUpdateURL(groupId) {
  return Constants.BASE_URLS.GROUPS + `/update-group/${groupId}`
}

export function getGroupDeleteURL(groupId) {
  return Constants.BASE_URLS.GROUPS + `/remove-group/${groupId}`
}

export function getGroupBaseURL(groupId) {
  return Constants.BASE_URLS.GROUPS + `/get-assessment/${groupId}`
}

export function getGroupURL(groupId) {
  return Constants.BASE_URLS.GROUPS + `/get-group/${groupId}`
}

export function getTeamCreateURL() {
  return Constants.BASE_URLS.TEAMS + `/create-team`
}

export function getMissionProjectURL(missionId) {
  return Constants.BASE_URLS.MISSIONS + `/get-project-by-mission-id/${missionId}`
}

export function getMissionProjectStepsURL(missionId) {
  return Constants.BASE_URLS.MISSIONS + `/get-steps-project/${missionId}`
}

export function getMissionProjectFirstStepURL(missionId) {
  return Constants.BASE_URLS.MISSIONS + `/get-first-step/${missionId}`
}

export function getPercentageStepsURL(teamId, missionId) {
  return Constants.BASE_URLS.MISSIONS + `/get-percentaje-projects-complete/${missionId}/${teamId}`
}

export function getRegisterStepURL() {
  return Constants.BASE_URLS.MISSIONS
}

export function getStepCompleteURL(teamId, missionId) {
  return Constants.BASE_URLS.MISSIONS + `/get-steps-complete/${teamId}/${missionId}`
}

export function getProjectFileUploadURL(teamId,missionId,leader, stepId,coach,file) {
  return Constants.BASE_URLS.MISSIONS + `/project-upload-file/${teamId}/${missionId}/${leader}/${stepId}/${coach}/${file}`
}

export function projectFileReviewUploadURL(url, reviewId) {
  return Constants.BASE_URLS.MISSIONS + `/project-upload-review/${url}/${reviewId}`
}

export function getAllChallengesURL() {//
  return Constants.BASE_URLS.CHALLENGES
}

export function getChallengeByIdURL(challengeId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-challenge-module-by-id/${challengeId}`
}

export function getChallengesCreateURL() {
  return Constants.BASE_URLS.CHALLENGES + `/create-challenge-module`
}

export function getAllChallengesModuleURL(page) {
  return Constants.BASE_URLS.CHALLENGES + `/get-all-challenges-module${page && page}`;
}

export function getSprintsChallengeURL(challengeId, groupId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-all-sprints-by-challenge-id/${challengeId}/${groupId}`
}

export function getSprintsChallengeByIdURL(challengeId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-challenges-by-challenge-id/${challengeId}`
}

export function getLastChallengeCompleteURL(groupId, userId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-last-challenge/${userId}/${groupId}`
}

export function getSprintsURL(sprintId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-sprints-by-id/${sprintId}`
}

export function getSprintsCreateURL() {
  return Constants.BASE_URLS.CHALLENGES  + `/add-sprint`
}

export function getModalitiesCreateURL() {
  return Constants.BASE_URLS.CHALLENGES + `/add-modality`
}

export function getModalitiesUpdateURL(modalityId) {
  return Constants.BASE_URLS.MODALITIES + `/update-modality-content/${modalityId}`
}

export function getModalitiesDeleteURL(modalityId) {
  return Constants.BASE_URLS.MODALITIES + `/remove-modality-content/${modalityId}`
}

export function getResourceURL(resourceId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-external-resource-by-id/${resourceId}`
}

export function getResourceCreateURL() {
  return Constants.BASE_URLS.CHALLENGES + `/add-external-resources`
}

export function getSprintsAllURL() { 
  return Constants.BASE_URLS.CHALLENGES
}

export function getSprintsUpdateURL(idSprint) {
  return Constants.BASE_URLS.CHALLENGES + `/update-sprint/${idSprint}`
}

export function getResourceUpdateURL(idSprint) {
  return Constants.BASE_URLS.CHALLENGES + `/update-external-resource/${idSprint}`
}

export function getSprintsChildStoreURL() {
  return Constants.BASE_URLS.CHALLENGES + `/add-child-sprint`
}

export function getSprintsChildsURL(page, parentId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-child-sprint-by-id/${parentId}${page && page}`
}

export function getSprintsResourcesURL(parentId, page) { 
  return Constants.BASE_URLS.CHALLENGES + `/get-external-resources-by-challenge/${parentId}${page && page}`
}

export function getSprintsResourcesAllURL(modalityId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-all-external-resources-by-modality/${modalityId}` 
}

export function getChallengeUpdateURL(challengeId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-challenges-by-challenge-id/${challengeId}`
}

export function getChallengeUpdateByIdURL(challengeId) {
  return Constants.BASE_URLS.CHALLENGES + `/update-challenge-module/${challengeId}`
}

export function getChallengeModuleByIdIdURL(challengeId) {
  return Constants.BASE_URLS.CHALLENGES + `/get-challenge-module-by-id/${challengeId}`
}

export function getSeniorityURL() {
  return Constants.BASE_URLS.USERS + `/get-seniorities`;
}

export function getAddChallengesRoleURL() {
  return Constants.BASE_URLS.ROLES + `/add-challenge-to-role`
}

export function getChallengesByRoleURL(id) {
  return Constants.BASE_URLS.ROLES + `/challenges-by-role/${id}`
}

export function getUpdateChallengesURL() {
  return Constants.BASE_URLS.ROLES + `/update-challenge-to-role`
}

export function getUpdateSprintsOrderURL() {
  return Constants.BASE_URLS.CHALLENGES + '/update-order-challenge'
}

export function getFindChallengeURL(id,page) {
  return Constants.BASE_URLS.CHALLENGES + `/find-by-name/${id}${page ? page : ''}`
}

export function getUsersByGroupURL(id){
  return Constants.BASE_URLS.GROUPS + `/get-users-by-group/${id}`
}

export function getDeleteChallengeURL(id) { 
  return Constants.BASE_URLS.ROLES + `/delete-challenge-in-role/${id}`
}

export function duplicateChallengeURL(id){ 
  return Constants.BASE_URLS.CHALLENGES + `/duplicate-challenge/${id}`
}

export function getDeleteSprintURL(id){ 
  return Constants.BASE_URLS.CHALLENGES + `/delete-sprint-in-challenge/${id}`
}

export function deleteUserURL(id){ 
  return Constants.BASE_URLS.GROUPS + `/delete-user-to-group/${id}`
}


export function getDeleteChallengeModuleURL(id){ 
  return Constants.BASE_URLS.CHALLENGES + `/remove-challenge/${id}`
}

export function getRemoveRoleURL(id){ 
  return Constants.BASE_URLS.ROLES + `/remove-role/${id}`
}

export function getFindProfileURL(id, page){ 
  return Constants.BASE_URLS.ROLES + `/find-role/${id}${page ? page : ''}`
}

export function removeGroupURL(id){
  return Constants.BASE_URLS.GROUPS + `/remove-group/${id}`
}

export function getFindGroupURL(id, page){ 
  return Constants.BASE_URLS.GROUPS + `/find-groups/${id}${page ? page : ''}`
}

export function getSendEmailURL(userId){ 
  return Constants.BASE_URLS.USERS + `/send-mail/unsuscribe-user/${userId}`
}

export function getAllModalitiesURL() {
  return Constants.BASE_URLS.MODALITIES +  Constants.END_POINTS.ALL;
}

export function getModalitiesByIdURL(id) {
  return Constants.BASE_URLS.MODALITIES + `/get-modality-content/${id}`;
}

export function getModalityChildrenURL(sprintId, modalityId) {
  return Constants.BASE_URLS.MODALITIES + `/get-modality-children/${sprintId}/${modalityId}`;
}

export function getSprintModalitiesURL(sprintId) {
  return Constants.BASE_URLS.MODALITIES +  Constants.END_POINTS.MODALITIES_BY_SPRINT + `/${sprintId}`;
}

export function changeChallengeVisibilityStatusURL() {
  return Constants.BASE_URLS.MODALITIES + `/challenge-visibilty-modality`;
}

export function deleteExternalResourceURL(resourceId){ 
  return Constants.BASE_URLS.CHALLENGES + `/delete-external-resource/${resourceId}`
}

export function getUpdateTitleModalityURL(modalityId, title) {
  return Constants.BASE_URLS.MODALITIES + `/update-title-modality/${modalityId}/${title}`
}

export default Constants;
