import axios from 'axios'

import Constantes from '../const/Constants'

export function setToken(token) {
  localStorage.setItem(Constantes.API_TOKEN, token)
}

export function getToken() {
  return localStorage.getItem(Constantes.API_TOKEN)
}

export function removeToken() {
  localStorage.removeItem(Constantes.API_TOKEN)
}

export function initAxiosInterceptor() {
  axios.defaults.baseURL = `${process.env.REACT_APP_USERS_API}api/v2`
  axios.interceptors.request.use(config => {
    const token = getToken()
    
    if (token) {
      config.headers.Authorization = `bearer ${token}`
    }

    return config
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 404 && error.response.status === 401) {
        removeToken()
      } else {
        return Promise.reject(error)
      }
    }
  )
}
