import React from "react";

import logo from "../../assets/logo-nao.png";
import maintainance from "../../assets/maintainance.png";

import css from "./MaintainancePage.module.css";

const MaintainancePage = () => {
    return (
        <div className={css.root}>
            <img src={logo} alt="Digital NAO" className={css.logo}/>
            <img src={maintainance} alt="Maintainance" className={css.maintainance}/>

            <h1 className={css.title}>
                Estamos trabajando para mejorar el servicio.
            </h1>
            <p className={css.description}>
                En cuento terminemos las mejoras te notificaremos para que puedas volver a disfrutar de nuestros tus trayecorias de aprendizaje.
            </p>
            <p className={css.description}>
                Disculpa las molestias.
            </p>
        </div>
    )
}

export default MaintainancePage;