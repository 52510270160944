import React, { useState } from 'react'

import { useParams, useLocation, useHistory } from 'react-router-dom'
import { $generateHtmlFromNodes } from '@lexical/html'
import Swal from 'sweetalert2'
import _ from 'lodash'

import Header from '../../components/modules/dashboard/partials/Header'
import SprintForm from '../../components/modules/dashboard/sprints/SprintForm'
import Button from '../../components/modules/dashboard/partials/Button'
import { useProfile } from '../../context/MainContext'
import {
  getSprintsChild,
  getSprintsResources,
  showSprint,
  updateSprint,
  storeResource,
  deleteSprintChallenge
} from '../../service/module/Challenges'
import { getSprintModalities, getAllModalities, storeModality, updateModality, deleteModality, getModalityChildren } from '../../service/module/Modalities'
import { Modal } from '../../components'

import css from './EditSprint.module.css'

export default function EditSprint() {
  const { id, idSprint } = useParams()
  const [message, setMessage] = React.useState()
  const [allModalities, setAllModalities] = React.useState([{}])
  const [realSprint, setRealSprint] = React.useState()
  const [sprint, setSprint] = React.useState([]) // TODO: Refractor sprint to modalities
  const [activeModality, setActiveModality] = React.useState()
  const [errors, setErrors] = React.useState({})
  const [meta, setMeta] = React.useState()
  const [metaResources, setMetaResources] = React.useState()
  const [list, setList] = React.useState()
  const [listResources, setListResources] = React.useState()
  const [pages, setPages] = React.useState()
  const [pagesResources, setPagesResources] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const [currentPageResources, setCurrentPageResources] = React.useState('')
  const [isParent, setIsParent] = React.useState(false)
  const { userType, userId } = useProfile()
  const history = useHistory()
  const editorRef = React.useRef(null)
  const formRef = React.useRef(null)
  const location = useLocation()
  const [titleSprint, setTitleSprint] = React.useState('')

  // Show modal to alert user about changes
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [modalityToChange, setModalityToChange] = useState(null)
  const [initialSprint, setInitialSprint] = React.useState(null)

  // Show modal to create new modality
  const [showNewModalityModal, setShowNewModalityModal] = React.useState(false)

  // Edit sprint name
  const [enableEditName, setEnableEditName] = React.useState(false)

  // Delete modality modal confirmation
  const [showDeleteModalityModal, setShowDeleteModalityModal] = React.useState(false)

  const [deleteResources, setDeleteResources] = React.useState('')
  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
    }
  }, [location])

  async function getSprint(id) {
    let challenge_order = 0
    let name = ''

    await getAllModalities()
      .then(response => {
        setAllModalities(response.data)
      })
      .catch(() => { })

    await showSprint(id)
      .then(response => {
        challenge_order = response.data.challenge_order
        name = response.data.name
        setRealSprint(response.data)
        setTitleSprint(response.data.name)
      })
      .catch(() => { })

    await getSprintModalities(id)
      .then(response => {
        setSprint(response.data)
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].challenge_order = challenge_order
          response.data[i].name = name
        }

        var activeModalityIndex = 0

        if (activeModality !== undefined && activeModality !== null) {
          //Get active modality from response.data
          activeModalityIndex = response.data.getIndex(modality => modality.modality_id === activeModality?.modality_id)
        }

        if (response.data[activeModalityIndex]?.content_type === 'parent') {
          setIsParent(true)
        }

        setActiveModality(response.data[activeModalityIndex])
        setInitialSprint(response.data[activeModalityIndex])
      })
      .catch(() => { })
  }

  React.useEffect(() => {
    getSprint(idSprint)
  }, [idSprint])

  // Get sprints from parents
  React.useEffect(() => {
    async function getSprints() {
      if (isParent && activeModality?.id) {
        await getModalityChildren(idSprint, activeModality?.id)
          .then(response => {
            setList(response.data)
            setMeta(response.meta)
            setPages(range(response.meta.first_page, response.meta.last_page))
          })
          .catch(() => { })
      }
    }
    getSprints()
  }, [currentPage, activeModality, isParent])

  async function getResources(current, modalityId) {
    await getSprintsResources(current, modalityId)
      .then(response => {
        setListResources(response.data)
        setMetaResources(response.meta)
        setPagesResources(range(response.meta.first_page, response.meta.last_page))
      })
      .catch(() => { })
  }

  // Get resources from sprints
  React.useEffect(() => {
    if (activeModality?.id) {
      getResources(currentPageResources, activeModality?.id)
    }
  }, [currentPageResources, activeModality, deleteResources])

  const generateHtml = () => {
    const editor = editorRef.current;
    if (editor) {
      let _html = null;
      let _json = null;

      editor.update(() => {
        _html = $generateHtmlFromNodes(editor, null);
        const editorState = editor.getEditorState();
        _json = JSON.stringify(editorState);
      });

      if (_html === '<p class="editor-paragraph"><br></p>') {
        return null;
      }

      return {
        _html: _html,
        _json: _json.replaceAll("'", '"'),
      };
    } else {
      return null;
    }
  };

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
      container: css.container,
      popup: css.popup,
    },
    buttonsStyling: false,
  })

  const getValues = () => {
    var sprintKeys = Object.keys(activeModality)
    var newSprint = {}
    for (var i = 0; i < sprintKeys.length; i++) {
      const input = document.getElementById(`${sprintKeys[i]}`)
      if (input) {
        newSprint[sprintKeys[i]] = input.value

        if (input.name === 'content_type') {
          if (input.checked) {
            newSprint[sprintKeys[i]] = 'parent'
          } else {
            newSprint[sprintKeys[i]] = 'research'
          }
        }

        if (input.name === 'action_url') {
          if (/(http(s?)):\/\//i.test(input.value)) {
            newSprint[sprintKeys[i]] = input.value
          } else {
            newSprint[sprintKeys[i]] = `https://${input.value}`
          }
        }

        if (sprintKeys[i] === 'allow_survey') {
          newSprint[sprintKeys[i]] = activeModality[sprintKeys[i]] === 'true' || activeModality[sprintKeys[i]] === 1 ? 1 : 0
        }
      } else {
        newSprint[sprintKeys[i]] = activeModality[sprintKeys[i]]

        if (sprintKeys[i] === 'allow_survey') {
          newSprint[sprintKeys[i]] = activeModality[sprintKeys[i]] === 'true' || activeModality[sprintKeys[i]] === 1 ? 1 : 0
        }
      }
    }

    return newSprint
  }

  async function update(modalityToChange) {
    const newModality = getValues()
    const generatedEditorContent = generateHtml()
    newModality.content = generatedEditorContent?._html || sprint.content || ''
    await updateModality(newModality.id, newModality)
      .then(response => {
        if (response.success) {
          if (modalityToChange && modalityToChange === 'none') {
            swalCustom.fire({
              title: '¡Modalidad actualizada exitosamente!',
              text: 'Se ha modificado la modalidad correctamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar',
            }).then(result => {
              setShowNewModalityModal(true)
              getSprint(idSprint)
            })
          }

          if (modalityToChange && modalityToChange === 'resources') {
            swalCustom.fire({
              title: '¡Modalidad actualizada exitosamente!',
              text: 'Se ha modificado la modalidad correctamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar',
            }).then(result => {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/add/${newModality.id}`)
            })
          }

          if (!modalityToChange || (modalityToChange !== 'none' && modalityToChange !== 'resources')) {
            swalCustom
              .fire({
                title: '¡Modalidad actualizada exitosamente!',
                icon: 'success',
                showDenyButton: true,
                showConfirmButton: true,
                denyButtonText: 'Seguir editando',
                confirmButtonText: 'Regresar al reto',
              })
              .then(result => {
                if (result.isConfirmed) {
                  history.push(`/dashboard/challenges/${id}/edit`)
                }
                if (result.isDenied) {
                  getSprint(idSprint)
                }
              })
          }
        } else {
          swalCustom.fire({
            title: 'Error!',
            text: "No se ha podido modificar la modalidad.",
            icon: 'error',
            confirmButtonText: 'Aceptar',
          })
        }
      })
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: error.errors,
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(result => {
          history.push('/dashboard/challenges')
        })
      })
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  function changeInput(e) {
    if (e.target.type === 'number') {
      if (e.target.value < 0) {
        errors[e.target.name] = 'El valor no puede ser negativo.'
        e.target.value = 0

        sleep(5000).then(() => {
          errors[e.target.name] = null
          // Set e.target.value to null to avoid the error message to be shown but mantain the other error messages
          setErrors({ ...errors, [e.target.name]: null })
        })
      }

      e.target.value = Math.round(e.target.value)
    }
    setActiveModality({ ...activeModality, [e.target.name.replace('', '')]: e.target.value })
    //TODO: CHECK HOW TO INSERT VALUE TO TEXT EDITOR ON FIRST RENDER
  }

  function changeCheckbox(e, isChecked) {
    e.target.value = isChecked
    changeInput(e)
  }
  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function addModality(from, type) {

    const type_name = allModalities?.find(modality => parseInt(modality.id) === parseInt(type))?.modality

    const emptyModality = {
      action_name: '',
      action_url: '',
      allow_survey: 0,
      banner: '',
      challenge_id: idSprint,
      challenge_order: realSprint.challenge_order,
      content: '',
      content_type: 'research',
      minutes: 0,
      modality_id: type,
      modality: type_name,
      new: true,
    }
    if (from === 'none') {
      setSprint([...sprint, emptyModality])
    }

    if (from !== 'none') {
      const copy = sprint.find((modality, index) => {
        return parseInt(modality.id) === parseInt(from)
      })

      if (copy?.id !== activeModality?.id) {
        getResources(0, copy.id)
      }

      if (copy) {
        setSprint([...sprint, { ...copy, id: null, modality_id: type, modality: type_name, new: true, content_type: 'research' }])
        setActiveModality({ ...copy, id: null, modality_id: type, modality: type_name, new: true, content_type: 'research' })
      } else {
        setSprint([...sprint, emptyModality])
        setActiveModality(emptyModality)
      }
    }
  }

  async function saveModality(modalityToChange) {

    const newSprint = getValues()
    const generatedEditorContent = generateHtml()
    newSprint.content = generatedEditorContent?._html.replaceAll('"', "'") || sprint.content || ''

    await storeModality(newSprint)
      .then(response => {
        if (response.success) {
          const modalityContentId = response.data
          listResources.forEach(resource => {
            resource.modality_content_id = response.data
            storeResource(resource).then(response => {
            })
          })
          swalCustom.fire({
            title: '¡Modalidad guardada exitosamente!',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: modalityToChange === 'resources' ? 'Continuar' : 'Regresar',
          }).then(result => {
            if (response.success) {

              if (modalityToChange && modalityToChange === 'resources') {
                history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/add/${modalityContentId}`)
              }

              newSprint.id = response.data

              // Delete the sprint with the null id and replace it with the new sprint
              const newSprintIndex = sprint.findIndex(modality => modality.id === null)
              const newSprintList = sprint
              newSprintList.splice(newSprintIndex, 1, newSprint)
              setSprint(newSprintList)

              setActiveModality(newSprint)
              setInitialSprint(newSprint)
              formRef.current.querySelectorAll('input, select').forEach((el) => {
                if (el.name === 'content_type') {
                  if (newSprint[el.name] === 'research') {
                    el.checked = false
                  }
                  if (newSprint[el.name] === 'parent') {
                    el.checked = true
                  }
                } else {
                  el.value = newSprint[el.name]
                }
              });
            }
          })
        }
        if (!response.success) {
          swalCustom.fire({
            title: 'Error!',
            text: 'Ocurrion un error al guardar la modalidad',
            icon: 'error',
            confirmButtonText: 'Regresar',
          })
        }
      }
      )
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: error.errors,
          icon: 'error',
          confirmButtonText: 'Regresar',
        })
      })
  }

  const compareChanges = (usedModality, activeCopy) => {
    // Check if the modality has been changed

    // Remove updated_at and created_at from the modality to compare
    delete usedModality?.updated_at
    delete usedModality?.created_at
    delete usedModality?.updated_by

    const parser = new DOMParser();
    const doc = parser.parseFromString(usedModality.content, 'text/html');

    // Function to remove attributes and classes recursively
    function removeAttributesAndClasses(element) {
      const attributes = Array.prototype.slice.call(element.attributes)
      if (attributes?.length > 0) {
        attributes.forEach((attr) => {
          if (attr.name.toLowerCase() === 'class' || attr.name.toLowerCase() === 'value' || attr.name.toLowerCase() === 'dir') {
            element.removeAttribute(attr.name);
          }
        })
      }

      for (const child of element.children) {
        removeAttributesAndClasses(child);
      }
    }

    // Remove attributes and classes from the HTML content
    removeAttributesAndClasses(doc.body);

    // Get the innerHTML of the modified content
    const modifiedContent = doc.body.innerHTML;

    usedModality.content = modifiedContent

    const stringifiedUsedModality = JSON.stringify(usedModality) // Stringify the modality in the sprint
    const generatedEditorContent = generateHtml() // Generate the html of the editor
    // Remove updated_at and created_at from the modality copy to compare
    delete activeCopy.updated_at
    delete activeCopy.created_at
    delete activeCopy.updated_by

    const docActive = parser.parseFromString(generatedEditorContent._html, 'text/html');
    // Remove attributes and classes from the HTML content
    removeAttributesAndClasses(docActive.body);

    // Get the innerHTML of the modified content
    const modifiedContentActive = docActive.body.innerHTML;

    activeCopy.content = modifiedContentActive
    
    if (activeCopy?.id !== null) {
      delete activeCopy.new
    }

    return usedModality.content !== activeCopy.content // Check if the modality has changed
  }

  const validateChanges = (source) => {
    
    let activeCopy = {} // Copy the active modality
    let usedModality = sprint.find(moda => moda.modality === activeModality.modality) // Find the modality in the sprint
    activeCopy = { ...activeModality }
    let modalityHasChanged = false
    const modalityContentHasChanged = compareChanges(usedModality, activeCopy)

    if(modalityContentHasChanged){ 
      modalityHasChanged = true;
    }else if(parseInt(usedModality.minutes) && parseInt(usedModality.minutes) !== parseInt(activeCopy.minutes)){ 
      modalityHasChanged = true
    }else if(usedModality.allow_survey !== activeCopy.allow_survey){
      modalityHasChanged = true
    }else if(usedModality.action_url !== activeCopy.action_url){
      modalityHasChanged = true
    }else if(usedModality.banner !== activeCopy.banner && usedModality.banner !== ''){
      modalityHasChanged = true
    }else if(usedModality.action_name !== activeCopy.action_name){
      modalityHasChanged = true
    }

    if (source === 'resources' && modalityHasChanged) {
      setShowAlertModal(true)
      setModalityToChange("resources")
      return
    }

    if (source === 'resources' && !modalityHasChanged) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/add/${activeModality?.id}`)
      return
    }

    if (source === 'childResources' && modalityHasChanged) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${activeModality?.modality_id}/child/${activeModality?.id}/resource/add`)
    }

    if (modalityHasChanged) {
      setShowAlertModal(true)
      setModalityToChange("none")
    }

    if (!modalityHasChanged) {
      setShowNewModalityModal(true)
    }
  }

  const handleNameCHange = (e) => {
    e.preventDefault()
    const name = e.target.name.value
    const data = {
      name,
      updated_by: userId,
    }

    updateSprint(idSprint, data)
      .then(response => {
        if (response.success || (!response.success && response.message === 'API-EVENT Failed')) {
          setMessage('Nombre actualizado correctamente')
          getSprint(idSprint)
          setEnableEditName(false)
          setTimeout(() => {
            setMessage(null)
          }, 5000)
        }
        if (!response.success && response.message !== 'API-EVENT Failed') {
          setMessage({ message: 'No se pudo actualizar el nombre' })
          setEnableEditName(false)
          setTimeout(() => {
            setMessage(null)
          }, 5000)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleDeleteModality = (modality) => {
    //Agregar modal.
    deleteModality(modality.id)
      .then(response => {
        if (response.success || (!response.success && response.message === 'API-EVENT Failed')) {
          setMessage('Modalidad borrada con éxito')
          setTimeout(() => {
            setMessage(null)
          }, 5000)

          //Delete the modality from the sprint array
          const modalityIndex = sprint.findIndex(sprint => sprint.id === modality.id)
          const sprintCopy = sprint
          sprintCopy.splice(modalityIndex, 1)
          setSprint(sprintCopy)
          setActiveModality(sprintCopy[sprintCopy.length - 1])
          setInitialSprint(sprintCopy[sprintCopy.length - 1])
        }
        if (!response.success && response.message !== 'API-EVENT Failed') {
          setMessage({ message: 'La modalidad no pudo ser borrada' })
          setTimeout(() => {
            setMessage(null)
          }, 5000)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleDeleteSprint = (sprintId) => {
    deleteSprintChallenge(sprintId)
      .then(response => {
        history.push(`/dashboard/challenges/${id}/edit`)
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <div>
      <Header />
      {(userType === 'operative' || userType === 'manager') && (
        <SprintForm
          title={titleSprint}
          idSprint={idSprint}
          id={id}
          setCurrentPage={setCurrentPage}
          setCurrentPageResources={setCurrentPageResources}
          meta={meta}
          metaResources={metaResources}
          listResources={listResources}
          list={list}
          setList={setList}
          pages={pages}
          pagesResources={pagesResources}
          message={message}
          modalities={sprint}
          sprint={activeModality}
          changeCheckbox={changeCheckbox}
          changeInput={changeInput}
          errors={errors}
          update={update}
          editorRef={editorRef}
          formRef={formRef}
          validateChanges={validateChanges}
          changeActiveModality={(modality) => {

            let activeCopy = {} // Copy the active modality
            let usedModality = sprint.find(moda => moda.modality === activeModality.modality) // Find the modality in the sprint
            activeCopy = { ...activeModality }
            let modalityHasChanged = false
            const modalityContentHasChanged = compareChanges(usedModality, activeCopy)
        
            if(modalityContentHasChanged){ 
              modalityHasChanged = true;
            }else if(parseInt(usedModality.minutes) && parseInt(usedModality.minutes) !== parseInt(activeCopy.minutes)){ 
              modalityHasChanged = true
            }else if(usedModality.allow_survey !== activeCopy.allow_survey){
              modalityHasChanged = true
            }else if(usedModality.action_url !== activeCopy.action_url){
              modalityHasChanged = true
            }else if(usedModality.banner !== activeCopy.banner && usedModality.banner !== ''){
              modalityHasChanged = true
            }else if(usedModality.action_name !== activeCopy.action_name){
              modalityHasChanged = true
            }
            
            if (modalityHasChanged || (activeModality?.new && activeModality?.id === null)) {
              setShowAlertModal(true)
              setModalityToChange(modality)
            }

            if (!modalityHasChanged && !(activeModality?.new && activeModality?.id === null)) {
              setActiveModality(modality)
              setInitialSprint(modality)
              // Reset each form input to the new modality values
              formRef.current.querySelectorAll('input, select').forEach((el) => {
                if (el.name === 'content_type') {
                  if (modality[el.name] === 'research') {
                    el.checked = false
                  }
                  if (modality[el.name] === 'parent') {
                    el.checked = true
                  }
                } else {
                  el.value = modality[el.name]
                }
              });
            }

          }}
          addModality={addModality}
          saveModality={saveModality}
          showNewModalityModal={showNewModalityModal}
          setShowNewModalityModal={setShowNewModalityModal}
          initialSprint={initialSprint}
          setInitialSprint={setInitialSprint}
          handleNameCHange={handleNameCHange}
          enableEditName={enableEditName}
          setEnableEditName={setEnableEditName}
          handleDeleteModality={handleDeleteModality}
          showDeleteModalityModal={showDeleteModalityModal}
          setShowDeleteModalityModal={setShowDeleteModalityModal}
          realSprint={realSprint}
          setDeleteResources={setDeleteResources}
        />
      )}
      <Modal
        className={css.modal}
        show={showAlertModal}
        onClose={() => setShowAlertModal(false)}
      >
        <p className={css.modalTitle}>
          ¿Deseas guardar cambios antes de continuar?
        </p>

        <p>
          De lo contrario, tus últimos cambios no se guardaran al cambiar de vista.
        </p>

        <div className={css.buttons}>
          <Button
            onClick={() => setShowAlertModal(false)}
            className={css.none}
          >
            Cancelar
          </Button>
          <div className={css.buttons}>
            <Button
              onClick={() => {

                if (modalityToChange === "resources") {
                  history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/add/${sprint?.id}`)
                }

                if (modalityToChange === "none") {
                  setShowAlertModal(false)
                  setShowNewModalityModal(true)
                }

                if (modalityToChange !== "none") {
                  setActiveModality(modalityToChange)
                  setInitialSprint(modalityToChange)
                  formRef.current.reset()
                  setShowAlertModal(false)
                }

              }}
              className={css.confirmButton}
            >
              No guardar
            </Button>
            <Button
              onClick={() => {
                setShowAlertModal(false)

                if (activeModality.new && activeModality.id === null) {
                  saveModality(modalityToChange)
                } else {
                  update(modalityToChange)
                }
              }}
              className={css.cancelButton}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className={css.modal}
        show={showDeleteModalityModal}
        onClose={() => setShowDeleteModalityModal(false)}
      >
        <p className={css.modalTitle}>
          ¿Estás seguro que deseas borrar esta modalidad?
        </p>

        {sprint.length > 1 && activeModality?.content_type !== 'parent' && (
          <p>
            El contenido del sprint <b>{activeModality?.name}</b> de la modalidad <b>{activeModality?.modality}</b> se perderá.
          </p>
        )}

        {sprint.length === 1 && activeModality?.content_type !== 'parent' && (
          <p>
            El contenido del sprint <b>{activeModality?.name}</b> de la modalidad <b>{activeModality?.modality}</b> se perderá, y se borrará el sprint.
          </p>
        )}

        {activeModality?.content_type === 'parent' && (
          <p>
            El contenido del sprint <b>{activeModality?.name}</b> de la modalidad <b>{activeModality?.modality}</b> se perderá, y se borrará el sprint hijo.
          </p>
        )}

        <p>
          Esta acción no podrá deshacerse.
        </p>
        <div className={css.buttons}>
          <div className={css.buttons}>
            <Button
              onClick={() => setShowDeleteModalityModal(false)}
              className={css.none}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setShowDeleteModalityModal(false)
                handleDeleteModality(activeModality)
                if (sprint.length === 1 && activeModality?.content_type !== 'parent') {
                  handleDeleteSprint(realSprint?.id)
                }
              }}
              className={css.delete}
            >
              Continuar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
