import { createTheme } from "@material-ui/core/styles";

const Theme = createTheme({
  typography: {
    fontFamily: [
      'Hind Madurai',
      'Baskerville',
      'Baskerville BT',
      'serif',
    ].join(','),
  },
  palette: {
    background: {
      default: "#FFF",
    },
    primary: {
      main: "#FFF"
    },
  },
  mixins: {
    toolbar: {
      minHeight: 85,
    },
  },
  shape: {
    borderRadius: 20,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          height: "100%",
        },
        html: {
          height: "100%",
        },
      },
    },
  },
});
export default Theme;
