import Axios from "axios";
import {
  getTeamsURL,
  getTeamsCheckURL,
  getTeamMyMissionURL,
  getTeamURL,
  getTeamUpdateURL,
  getTeamDeleteURL,
} from "../../const/Constants";
import Constantes from "../../const/Constants";

let instance = Axios.create({
  baseURL: `${process.env.REACT_APP_CORE_API}api/v2`,
});

instance.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return config;
});

export function getToken() {
  return localStorage.getItem(Constantes.API_TOKEN);
}

export async function storeTeam(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getTeamsURL(), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function deleteTeam(teamId) {
  return new Promise((resolve, reject) => {
    instance.delete(getTeamDeleteURL(teamId))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}



export async function updateTeam(teamId, data) {
  return new Promise((resolve, reject) => {
    instance.put(getTeamUpdateURL(teamId), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function getMissionsFor(userId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getTeamMyMissionURL(userId))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function getAllTeams(page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getTeamsURL() + page)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getTeam(missionId, userId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getTeamsCheckURL() + `/${missionId}/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function getTeamBy(teamId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getTeamURL(teamId))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
