import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import MainLayout from "../layouts/Main";
import DashboardLayout from "../layouts/Dashboard";
import { MainProvider } from "../context/MainContext";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <MainProvider>
          <Switch>
            <Route
              path="/dashboard"
              render={(props) => <DashboardLayout {...props} />}
            />
            <Route path="/" render={(props) => <MainLayout {...props} />} />
          </Switch>
        </MainProvider>
      </BrowserRouter>
    );
  }
}

export default Router;
