import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'

import Button from './Button'

import css from './Discord.module.css'

export default function Discord() {
  return (
    <div className={css.background}>
      <FontAwesomeIcon icon={faDiscord} className={css.icon} />
      <h6 className={css.title}>
        ¡Conecta con el grupo!
      </h6>
      <h6 className={css.subtitle}>
        Entra al foro en discord y resuelve tus dudas, ayuda a los demás con los retos
      </h6>
      <Button
        newButton={true}
        discord="true"
        className={css.button}
        title="Ir al grupo"
        onClick={e => {
          e.preventDefault()
          window.open('https://discord.gg/FUTnmPqg2W')
        }}
      />
    </div>
  )
}
