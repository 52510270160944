import Axios from "axios";
import {
  getAuthLoginURL,
  getAuthCheckURL,
  getAuthLogoutURL,
  getAuthRecoveryURL,
  getAuthResetPasswordURL,
  getAuthUpdatePasswordURL,
} from "../../const/Constants";
import { setToken } from "../HelperServices";

export async function login(token) {
  return new Promise((resolve, reject) => {
    Axios.post(getAuthLoginURL(), {
      token: token,
    })
      .then((response) => {
        setToken('TOKEN-No se utiliza');
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function logoutApi() {
  return new Promise((resolve, reject) => {
    Axios.post(getAuthLogoutURL())
      .then((response) => {
        setToken(response.data.token);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkUser() {
  return new Promise((resolve, reject) => {
    Axios.get(getAuthCheckURL())
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function recovery(email, birthdate) {
  return new Promise((resolve, reject) => {
    Axios.post(getAuthRecoveryURL(), {
      email: email,
      birthdate: birthdate,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function resetPassword(password, confirm, token) {
  return new Promise((resolve, reject) => {
    Axios.put(getAuthResetPasswordURL(), {
      password: password,
      password_confirmation: confirm,
      token: token,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function updatePassword(password, newPassword, confirm, userId) {
  return new Promise((resolve, reject) => {
    Axios.put(getAuthUpdatePasswordURL(), {
      user_id: userId,
      old_password: password,
      password: newPassword,
      password_confirmation: confirm,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
