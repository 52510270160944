import React from 'react'
import { useLocation, Switch } from 'react-router-dom'

import '../assets/plugins/nucleo/css/nucleo.css'
//import '@fortawesome/fontawesome-free/css/all.min.css'
import '../assets/scss/argon-dashboard-react.scss'

import NavBar from '../components/modules/dashboard/partials/NavBar'
import Footer from '../components/modules/dashboard/partials/Footer'
import SideBar from '../components/modules/dashboard/partials/SideBar'
import routes, { breadCrumbRoutes } from '../const/Routes'

import DashboardHomePage from '../views/dashboard/Home'
import UsersPage from '../views/dashboard/Users'
import AddUsersPage from '../views/dashboard/AddUsers'
import EditUsersPage from '../views/dashboard/EditUsers'
import AssignUserPage from '../views/dashboard/AssignUsers'
import TeamsPage from '../views/dashboard/Teams'
import AddTeamPage from '../views/dashboard/AddTeam'
import EditTeamsPage from '../views/dashboard/EditTeam'
import GroupsPage from '../views/dashboard/Groups'
import AddGroupsPage from '../views/dashboard/AddGroup'
import EditGroupsPage from '../views/dashboard/EditGroup'
import RolesPage from '../views/dashboard/Roles'
import AddRolePage from '../views/dashboard/AddRole'
import EditRolePage from '../views/dashboard/EditRole'
import ChallengesPage from '../views/dashboard/Challenges'
import AddChallengesPage from '../views/dashboard/AddChallenge'
import EditChallengesPage from '../views/dashboard/EditChallenge'
import AddSprintsPage from '../views/dashboard/AddSprint'
import EditSprintsPage from '../views/dashboard/EditSprint'
import AddSprintChildPage from '../views/dashboard/AddSprintChild'
import EditSprintChildPage from '../views/dashboard/EditSprintChild'
import AddResourcePage from '../views/dashboard/AddResource'
import EditResourcePage from '../views/dashboard/EditResource'
import ProtectedRoute from '../hooks/ProtectedRouteDashboard'
import AddChallengeRolePage from '../views/dashboard/AddChallengeRole'

import css from './Dashboard.module.css'

export default function Dashboard(props) {
  const mainContent = React.useRef(null)
  const location = useLocation()

  React.useEffect(() => {
    document.documentElement.scrollTo = 0
    document.scrollingElement.scrollTo = 0
    mainContent.current.scrollTo = 0
  }, [location])

  
  function isNan(v) {
    return /\d/.test(v);
  }

  const getBreadcrumb = path => {
    const breadCrumb = [];
    const pathArray = path.split('/')
    const pathWithoutFirstSlash = pathArray.slice(2)
    const pathArrayWithoutNumbers = pathWithoutFirstSlash.filter(item => isNaN(item))
    const pathWithoutNumbers = pathArrayWithoutNumbers.join('/')
    var breadCrumbRoute = breadCrumbRoutes.find(item => item.path === pathWithoutNumbers)
    if (!breadCrumbRoute) {
      return null
    }

    do {
      breadCrumb.push(breadCrumbRoute)
      breadCrumbRoute = breadCrumbRoutes.find(item => item.path === breadCrumbRoute.parent)
    } while (!!breadCrumbRoute)
    return breadCrumb.reverse()
  }

  return (
    <div>
      <SideBar
        {...props}
        routes={routes}
        logo={{
          innerLink: '/dashboard',
          imgSrc: require('../assets/logo-nao.png').default,
          imgAlt: 'logo',
        }}
      />
      <div className={css.main} ref={mainContent}>
        <NavBar {...props} brandText={getBreadcrumb(props.location.pathname)} />
        <Switch>
          <ProtectedRoute exact path="/dashboard" component={DashboardHomePage} />
          <ProtectedRoute exact path="/dashboard/users" component={UsersPage} />
          <ProtectedRoute exact path="/dashboard/users/add" component={AddUsersPage} />
          <ProtectedRoute exact path="/dashboard/users/:id/edit" component={EditUsersPage} />
          <ProtectedRoute exact path="/dashboard/users/:id/assign" component={AssignUserPage} />
          <ProtectedRoute exact path="/dashboard/teams" component={TeamsPage} />
          <ProtectedRoute exact path="/dashboard/teams/add" component={AddTeamPage} />
          <ProtectedRoute exact path="/dashboard/teams/:id/edit" component={EditTeamsPage} />

          <ProtectedRoute exact path="/dashboard/groups" component={GroupsPage} />
          <ProtectedRoute exact path="/dashboard/groups/add" component={AddGroupsPage} />
          <ProtectedRoute exact path="/dashboard/groups/:id/edit" component={EditGroupsPage} />

          <ProtectedRoute exact path="/dashboard/roles" component={RolesPage} />
          <ProtectedRoute exact path="/dashboard/roles/add" component={AddRolePage} />
          <ProtectedRoute exact path="/dashboard/roles/:id/edit" component={EditRolePage} />
          <ProtectedRoute exact path="/dashboard/challenges" component={ChallengesPage} />
          <ProtectedRoute exact path="/dashboard/challenges/add" component={AddChallengesPage} />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/edit"
            component={EditChallengesPage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/resource/add/:modalityId"
            component={AddResourcePage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/resource/:idResource/edit/:modalityId"
            component={EditResourcePage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/modality/:modalityId/child/:idChild/resource/add"
            component={AddResourcePage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/modality/:modalityId/child/:idChild/resource/:idResource/edit"
            component={EditResourcePage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/add"
            component={AddSprintsPage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/edit"
            component={EditSprintsPage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/modality/:modalityId/child/add"
            component={AddSprintChildPage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/challenges/:id/sprint/:idSprint/modality/:modalityId/child/:idChild/edit"
            component={EditSprintChildPage}
          />
          <ProtectedRoute
            exact
            path="/dashboard/roles/:id/edit/addchallenges"
            component={AddChallengeRolePage}
          />
        </Switch>{' '}
        <div className={css.footerContainer}>
          <Footer />
        </div>
      </div>
    </div>
  )
}
