import React from 'react'

import Router from './routes/Router'
import { initAxiosInterceptor } from './service/HelperServices'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Auth0Provider } from '@auth0/auth0-react'
import { getConfig } from './service/module/Config'

let clientID = process.env.REACT_APP_OAUTH0_CLIENT_ID
let host = process.env.REACT_APP_OAUTH0_HOST
let audience = process.env.REACT_APP_OAUTH0_AUDIENCE
let server = process.env.REACT_APP_HOST

initAxiosInterceptor()

export default function App() {
  if (process.env.REACT_APP_DEV === 'true') {
  } else {
    server = window.location.hostname
    getConfig(server).then(data => {
      clientID = data.OAUTH0_CLIENT_ID
      host = data.OAUTH0_HOST
      audience = data.OAUTH0_AUDIENCE
      localStorage.setItem('container', data.STORAGE_CONTAINER_NAME)
    })
  }

  return (
    <Auth0Provider
      domain={host}
      clientId={clientID}
      redirectUri={window.location.origin + '/challenges'}
      audience={audience}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router />
      </MuiPickersUtilsProvider>
    </Auth0Provider>
  )
}
