import Axios from "axios";
import {
  getGroupsURL,
  createGroupURL,
  getGroupURL,
  getGroupUpdateURL,
  getGroupDeleteURL,
  getGroupBaseURL,
  getUsersByGroupURL,
  deleteUserURL,
  removeGroupURL,
  getFindGroupURL
} from "../../const/Constants";
import Constantes from "../../const/Constants";

let instance = Axios.create({
  baseURL: `${process.env.REACT_APP_CORE_API}api/v2`,
});

instance.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return config;
});

export function getToken() {
  return localStorage.getItem(Constantes.API_TOKEN);
}

export async function storeGroup(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(createGroupURL(), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function deleteGroupURL(groupId, data) {
  return new Promise((resolve, reject) => {
    instance
    .put(getGroupDeleteURL(groupId), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function updateGroup(groupId, data) {
  return new Promise((resolve, reject) => {
    instance.put(getGroupUpdateURL(groupId), data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function getAllGroups(page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getGroupsURL() + "/get-groups/" + page)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getGroupsNoPaginate() {
  return new Promise((resolve, reject) => {
    instance
      .get(getGroupsURL() + "/get-groups" + '?paggination=false')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getGroupBy(groupId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getGroupURL(groupId))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export async function getAllGroupsCount() {
  return new Promise((resolve, reject) => {
    instance
      .get(getGroupsURL() + '/count-all-users-in-groups')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getGroupAssessment (groupId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getGroupBaseURL(groupId))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getUsersByGroup(groupId) { 
  return new Promise((resolve, reject) => { 
    instance
    .get(getUsersByGroupURL(groupId))
    .then((response) => { 
      resolve(response.data) 
    })
    .catch((error) => {
      reject(error)
    })
  })
}

export async function deleteUser(id, data){
  return new Promise((resolve, reject) => { 
    instance
    .delete(deleteUserURL(id), data)
    .then((response) => { 
      resolve(response.data)
    })
    .catch((error) => { 
      reject(error)
    })
  })
}


export async function removeGroupById(id, data){
  return new Promise((resolve, reject) => { 
    instance
    .put(removeGroupURL(id), data)
    .then((response) => { 
      resolve(response.data)
    })
    .catch((error) => { 
      reject(error)
    })
  })
}

export async function getFindGroup(id, page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getFindGroupURL(id, page))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}