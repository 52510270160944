import React from 'react'

import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { Pagination, PaginationItem, PaginationLink, Alert, Modal } from 'reactstrap'
import Swal from 'sweetalert2'

import Button from '../../components/modules/dashboard/partials/Button'
import SearchInput from '../../components/modules/dashboard/partials/SearchInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faAngleRight, faAngleLeft, faSearch } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/modules/dashboard/partials/Header'
import { getRoles, removeRoleById, getFindProfile } from '../../service/module/Roles'
import { useProfile } from '../../context/MainContext'
import classNames from 'classnames'
import css from './Roles.module.css'

export default function Roles() {
  const [message, setMessage] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const [notification, setNotification] = React.useState()
  const [meta, setMeta] = React.useState()
  const [list, setList] = React.useState()
  const [pages, setPages] = React.useState()
  const [isRoleDeleted, setIsRoleDeleted] = React.useState()
  const { userId, userType } = useProfile()
  const history = useHistory()
  const location = useLocation()

  const [word, setWord] = React.useState('')

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
      setColorMessage('success')
      setNotification('Éxito')
    }
  }, [location])

  React.useEffect(() => {
    async function getRolesFor(current) {
      await getRoles(current)
        .then(response => {
          const roles = response.data
          for (let u = 0; u < roles.length; u++) {
            let role = roles[u]
            if (role.challenges_role && role.challenges_role.data) {
              let challenges = roles[u].challenges_role.data || []
              for (let i = 0; i < challenges.length; i++) {
                let sprints = challenges[i].challenges || []
                for (let j = 0; j < sprints.length; j++) {
                  let modalities = sprints[j].modalities || []
                  for (let k = 0; k < modalities.length; k++) {
                    if (!role.modalities || role.modalities.length === 0) {
                      role.modalities = [modalities[k]]
                    }

                    if (role.modalities && role.modalities.length > 0) {
                      let isRepeated = false
                      for (let l = 0; l < role.modalities.length; l++) {
                        if (role.modalities[l].id === modalities[k].id) {
                          isRepeated = true
                        }
                      }

                      if (!isRepeated) {
                        role.modalities = [...role.modalities, modalities[k]]
                      }
                    }
                    
                    if (role.modalities?.length > 3) {
                      break;
                    }
                  }
                  if (role.modalities?.length > 3) {
                    break;
                  }
                }
                if (role.modalities?.length > 3) {
                  break;
                }
              }
            }
            if (role.modalities?.length > 3) {
              break;
            }
          }
          setList(roles)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    async function getFindProfiles(word) {
      await getFindProfile(word, currentPage)
        .then(response => {
          const roles = response.data
          for (let u = 0; u < roles.length; u++) {
            let role = roles[u]
            if (role.challenges_role && role.challenges_role.data) {
              let challenges = roles[u].challenges_role.data || []
              for (let i = 0; i < challenges.length; i++) {
                let sprints = challenges[i].challenges || []
                for (let j = 0; j < sprints.length; j++) {
                  let modalities = sprints[j].modalities || []
                  for (let k = 0; k < modalities.length; k++) {
                    if (!role.modalities || role.modalities.length === 0) {
                      role.modalities = [modalities[k]]
                    }

                    if (role.modalities && role.modalities.length > 0) {
                      let isRepeated = false
                      for (let l = 0; l < role.modalities.length; l++) {
                        if (role.modalities[l].id === modalities[k].id) {
                          isRepeated = true
                        }
                      }

                      if (!isRepeated) {
                        role.modalities = [...role.modalities, modalities[k]]
                      }
                    }
                    
                    if (role.modalities?.length > 3) {
                      break;
                    }
                  }
                  if (role.modalities?.length > 3) {
                    break;
                  }
                }
                if (role.modalities?.length > 3) {
                  break;
                }
              }
            }
            if (role.modalities?.length > 3) {
              break;
            }
          }
          setList(roles)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }

    if (!word || word === '') {
      getRolesFor(currentPage)
    } else {
      getFindProfiles(word, currentPage)
    }

  }, [currentPage, isRoleDeleted])

  function range(start, end) {
    let foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function add(e) {
    e.preventDefault()
    history.push('/dashboard/roles/add')
  }

  function editRoleAction(roleId) {
    history.push(`/dashboard/roles/${roleId}/edit`)
  }

  async function removeRole(roleId) {
    await removeRoleById(roleId, { "created_by": userId })
      .then(response => {
        setMessage('Perfil eliminado correctamente')
        setColorMessage('success')
        setNotification('Éxito')
        setIsRoleDeleted(!isRoleDeleted)
      })
      .catch(error => {
        setMessage(error.message)
        setColorMessage('danger')
      })
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
      icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id) {
    swalCustom.fire({
      title: '¿Estás seguro que deseas eliminar este perfil?',
      iconHtml: '<div className="' + css.warningIcon + '"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Eliminar perfil',
    }).then(_result => {
      if (_result.isDenied) {
        removeRole(id)
      }
    })
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage(meta.next_page_url)
    }
  }

  function search() {
    async function getFindProfiles(word) {
      await getFindProfile(word)
        .then(response => {
          const roles = response.data
          for (let u = 0; u < roles.length; u++) {
            let role = roles[u]
            if (role.challenges_role && role.challenges_role.data) {
              let challenges = roles[u].challenges_role.data || []
              for (let i = 0; i < challenges.length; i++) {
                let sprints = challenges[i].challenges || []
                for (let j = 0; j < sprints.length; j++) {
                  let modalities = sprints[j].modalities || []
                  for (let k = 0; k < modalities.length; k++) {
                    if (!role.modalities || role.modalities.length === 0) {
                      role.modalities = [modalities[k]]
                    }

                    if (role.modalities && role.modalities.length > 0) {
                      let isRepeated = false
                      for (let l = 0; l < role.modalities.length; l++) {
                        if (role.modalities[l].id === modalities[k].id) {
                          isRepeated = true
                        }
                      }

                      if (!isRepeated) {
                        role.modalities = [...role.modalities, modalities[k]]
                      }
                    }
                    
                    if (role.modalities?.length > 3) {
                      break;
                    }
                  }
                  if (role.modalities?.length > 3) {
                    break;
                  }
                }
                if (role.modalities?.length > 3) {
                  break;
                }
              }
            }
            if (role.modalities?.length > 3) {
              break;
            }
          }
          setList(roles)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }

    getFindProfiles(word)
  }

  React.useEffect(() => {
    async function getRolesFor(current) {
      await getRoles(current)
        .then(response => {
          const roles = response.data
          for (let u = 0; u < roles.length; u++) {
            let role = roles[u]
            if (role.challenges_role && role.challenges_role.data) {
              let challenges = roles[u].challenges_role.data || []
              for (let i = 0; i < challenges.length; i++) {
                let sprints = challenges[i].challenges || []
                for (let j = 0; j < sprints.length; j++) {
                  let modalities = sprints[j].modalities || []
                  for (let k = 0; k < modalities.length; k++) {
                    if (!role.modalities || role.modalities.length === 0) {
                      role.modalities = [modalities[k]]
                    }

                    if (role.modalities && role.modalities.length > 0) {
                      let isRepeated = false
                      for (let l = 0; l < role.modalities.length; l++) {
                        if (role.modalities[l].id === modalities[k].id) {
                          isRepeated = true
                        }
                      }

                      if (!isRepeated) {
                        role.modalities = [...role.modalities, modalities[k]]
                      }
                    }
                    
                    if (role.modalities?.length > 3) {
                      break;
                    }
                  }
                  if (role.modalities?.length > 3) {
                    break;
                  }
                }
                if (role.modalities?.length > 3) {
                  break;
                }
              }
            }
            if (role.modalities?.length > 3) {
              break;
            }
          }
          setList(roles)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    if (word.length > 1) {
      search()
    }

    if (word.length === 0) {
      getRolesFor('/?page=1')
    }
  }, [word])

  return (
    <div>
      <Header />
      {/* Page content */}
      <div className={classNames(css.root)}>
        {message && colorMessage && (
          <Alert color={colorMessage}>
            <strong>¡{notification}!</strong> {message}
          </Alert>
        )}
        <div className={css.tableContainer}>
          <div className={css.tableHeader}>
            <h3 className={css.title}>Lista de Perfiles</h3>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              <SearchInput onSearch={setWord} placeholder="Buscar nombre o seniority" />
            </div>
            {userType === 'operative' && (
              <Button href="/dashboard/users/add" className={css.addButton} onClick={add}>
                Agregar
              </Button>
            )}
          </div>
          <div className={css.table}>
            <table className={css.table}>
              <thead className={css.thead}>
                <tr className={css.tr}>
                  <th scope="col" className={css.th}>
                    Nombre
                  </th>
                  <th scope="col" className={css.th}>
                    Modalidad
                  </th>
                  <th scope="col" className={css.th}>
                    Seniority
                  </th>
                  {userType === 'operative' && (
                    <th scope="col" className={css.th}>
                      Acciones
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className={css.tbody}>
                {list &&
                  list.map((item, key) => {
                    return (
                      <tr className={css.tr} key={key}>
                        <td className={css.td}>
                          <span className={css.name}>{item.name}</span>
                        </td>
                        <td className={classNames(css.td, css.modailities)}>
                          {item?.modalities?.length > 0 && item.modalities.map((item, key) => {
                            return (
                              <div className={classNames(css.tag, {
                                [css.tagActive]: item.visible === 1,
                              })} key={key}>
                                <span className={css.tagText}>{item.modality}</span>
                              </div>
                            )
                          })}
                        </td>
                        <td className={css.td}>{item.seniority}</td>
                        {userType === 'operative' && (
                          <td className={classNames(css.td, css.actionButtons)}>
                            <Button
                              isicon={true}
                              className={css.edit}
                              type="button"
                              title="Editar"
                              onClick={() => editRoleAction(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Button>

                            <Button
                              isicon={true}
                              className={css.delete}
                              type="button"
                              title="Eliminar"
                              onClick={() => { toggleModal(item.id) }}
                              disabled={item && item.groups_in_role >= 1 || item.users_in_role >= 1 ? true : false}
                            >
                              <span>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </Button>
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div className={css.tableFooter}>
            {list && list.length > 0 ? (
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  {meta && (
                    <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                      <PaginationLink
                        href={meta.first_page_url}
                        onClick={previous}
                        tabIndex={!meta.previous_page_url ? '-1' : undefined}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  {pages &&
                    pages.map((item, key) => {
                      /*
                       Always show 9 pages (if there are more than 9)
                       show 4 pages before and 4 pages after the current page
                      */
                      if (meta.current_page <= 5 && key < 9) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }

                      if (meta.current_page > 5) {
                        if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      }

                      if (meta.last_page - meta.current_page < 5) {
                        if (key > meta.last_page - 10) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      }
                    })}
                  {meta && (
                    <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                      <PaginationLink href={meta.last_page_url} onClick={next}>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                </Pagination>
              </nav>
            ) : (
              <div className={css.noData}>
                <span>No hay datos para mostrar</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
