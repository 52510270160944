import React from "react";

import {
  Grid,
  Typography,
  makeStyles,
  Box,
  Avatar,
  withStyles,
  Card,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faBook,
  faVolumeUp,
  faVideo,
  faFileText
} from "@fortawesome/free-solid-svg-icons";

import css from "./Resources.module.css";

function getType(type) {
  switch (type) {
    case "reading": {
      return (
        <>
          <FontAwesomeIcon icon={faBook} style={{ color: "#fcafbf" }} /> Lectura
        </>
      );
    }
    case "video": {
      return (
        <>
          <FontAwesomeIcon icon={faVideo} style={{ color: "#fcafbf" }} /> Video
        </>
      );
    }
    case "audio": {
      return (
        <>
          <FontAwesomeIcon icon={faVolumeUp} style={{ color: "#fcafbf" }} />{" "}
          Audio
        </>
      );
    }

    case "file": { 
      return (
        <>
        <FontAwesomeIcon icon={faFileText} style={{color: "#fcafbf"}}/> Archivo
        </>
      )
    }
    default: {
      return (
        <>
          <FontAwesomeIcon icon={faBook} style={{ color: "#fcafbf" }} /> Lectura
        </>
      );
    }
  }
}

export default function Resources({ items }) {

  const ChallengeImage = withStyles(() => ({
    root: {
      padding: 10,
      width: "auto",
      minHeight: 200,
      maxHeight: 200,
      borderRadius: 0,
    },
    img: {
      width: "auto",
    },
  }))(Avatar);

  return (
    <div className={css.challengesContainer}>
      {items &&
        items.map((item, key) => {
          return (
            <div
              className={css.card}
              key={key}
              onClick={(e) => {
                e.preventDefault();
                window.open(item.url);
              }}
            >
              <div className={css.imageContainer}>
                <ChallengeImage
                  alt=""
                  src={item.thumbnail_url}
                ></ChallengeImage>
              </div>
              <h6
                className={css.challengeTitle}
              >
                {item.title}
              </h6>
              <div className={css.row}>
                <h6 className={css.typeContainer}>
                  {getType(item.type)}
                </h6>
                {(item.time && parseInt(item.time) !== 0 ) && (
                  <h6 className={css.timeContainer}>
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ color: "#fcafbf" }}
                    />{" "}
                    {(item.time && parseInt(item.time) !== 0 )  ? `${item.time} hrs` : 'hrs'}
                  </h6>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}
