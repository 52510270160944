import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/modules/main/partials/Footer'

import css from './TermsAndConditions.module.css'

export default function TermsAndConditions() {

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <div className={css.article}>
        <h2 className={css.title}>TÉRMINOS Y CONDICIONES</h2>
        <ol>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>TÉRMINOS DE USO.</h3>
          </li>
          <p className={css.text}>
            Los términos que a continuación se presentan, regulan el uso del sitio web denominado
            NAO la cual ha sido puesta a disposición por ENSEÑANZA E INVESTIGACION SUPERIOR A.C. en
            adelante UNIVERSIDAD TECMILENIO, a través del sitio{' '}
            <a href="https://www.digital.nowisnao.com/" target="_blank" rel="noreferrer">
              https://www.digital.nowisnao.com/
            </a>{' '}
            en adelante SITIO.
          </p>
          <p className={css.text}>
            La utilización del SITIO por parte de cualquier persona, le atribuye la calidad de
            USUARIO lo cual implica su aceptación plena e incondicional a los términos y condiciones
            presentes; en consecuencia, es indispensable que el USUARIO los lea y evalúe antes de
            acceder al SITIO, de tal manera que esté consciente de que se sujeta a los mismos y a
            las modificaciones que pudiera sufrir, cada vez que accede al SITIO.{' '}
          </p>
          <p className={css.text}>
            Si en algún momento el USUARIO no estuviera de acuerdo total o parcialmente con los
            términos deberá abstenerse inmediatamente de usar el SITIO, en cualquiera de sus partes
            o secciones. El USUARIO está de acuerdo en que en diversas partes del SITIO podrá haber
            estipulaciones especiales que, según sea el caso, sustituirán, complementarán y/o
            modificarán los TÉRMINOS DE USO (en lo sucesivo las CONDICIONES PARTICULARES), por tal
            razón también resulta indispensable que el USUARIO las lea previamente en cada caso y de
            no estar total o parcialmente de acuerdo con ella o ellas, se abstenga de usar la parte
            o sección del SITIO regulada bajo esa CONDICIÓN o CONDICIONES PARTICULAR(ES). En caso de
            que el USUARIO utilice la parte del SITIO regulada por una o más CONDICIONES
            PARTICULARES, se entenderá que se somete total e incondicionalmente a ellas.
          </p>
          <p className={css.text}>
            Las faltas de cumplimiento a los siguientes términos y condiciones tendrán como
            consecuencia negar el acceso al SITIO al USUARIO sin perjuicio de las acciones legales
            que correspondan.
          </p>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>ACCESO Y UTILIZACIÓN DEL SITIO</h3>
          </li>
          <p className={css.text}>
            El acceso y utilización del SITIO por parte de LA UNIVERSIDAD TECMILENIO tiene carácter
            gratuito para los USUARIOS, no obstante, algunas de las funcionalidades del SITIO
            requieren de un pago, como es el caso de los “Servicios”, el USUARIO reconoce que deberá
            de cumplir con las políticas establecidas en dichos Servicios para poder tener acceso a
            los mismos. El USUARIO estará obligado a registrarse o inscribirse en el SITIO,
            únicamente cuando se le solicite específicamente hacerlo.
          </p>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>OBJETO</h3>
          </li>
          <p className={css.text}>
            A través del SITIO, LA UNIVERSIDAD TECMILENIO facilita a los USUARIOS el acceso a
            información diversa proporcionada por LA UNIVERSIDAD TECMILENIO o por personas
            vinculadas a dicha información de manera directa o indirecta (en lo sucesivo los
            CONTENIDOS).
          </p>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>CURSOS</h3>
          </li>
          <p className={css.text}>
            El término "Servicio" se refiere a los servicios proporcionados por nuestro SITIO,
            cualquier software asociado, aplicaciones y servicios de Internet bajo nuestro control,
            ya sea parcial o no, utilizado en relación con el suministro de los Servicios prestados
            por nosotros. El Servicio proporciona una plataforma en línea que le permite ver
            información en línea y a través de otros dispositivos conectados sobre clases, cursos,
            talleres, videos y otros programas y eventos educativos que ofrecemos (colectivamente,
            “Cursos”). Estos cursos pueden estar disponibles por una tarifa, a nuestro exclusivo
            criterio. Junto con nuestros instructores, asistentes de enseñanza, consejeros
            profesionales, consejeros, gerentes de éxito estudiantil, líderes de opinión y
            profesionales experimentados (colectivamente, "Instructores"), ofrecemos un currículo
            sólido enfocado en el desarrollo de habilidades en áreas de alta demanda. El Servicio
            también permitirá que la comunidad de usuarios del Servicio participe en discusiones y
            comunicaciones entre sí y con los Instructores.
          </p>
          <p className={css.text}>
            Para poder acceder a los Cursos el USUARIO deberá inscribirse/registrarse en un Curso.
            No podemos prometer la disponibilidad del Curso.
          </p>
          <p className={css.text}>
            El USUARIO reconoce que el uso del SITIO no le implica ningún derecho de propiedad sobre
            el mismo, cualquiera de sus elementos o CONTENIDOS.
          </p>
          <p className={css.text}>
            LA UNIVERSIDAD TECMILENIO se reserva el derecho a modificar en cualquier momento y sin
            aviso previo la presentación, configuración, información, CONTENIDOS y en general
            cualquier parte o aspecto relacionado directa o indirectamente con el SITIO.
          </p>
          <ol>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>CURSOS CON COSTO</h3>
            </li>
            <p className={css.text}>
              Es posible que le cobremos tarifas para asistir a un curso. LA UNIVERSIDAD TECMILENIO
              podrá revisar la cantidad de cualquier tarifa de vez en cuando y variar de una región
              a otra y de un curso a otro. EL USUARIO acepta pagar para poder cursar el Curso. Solo
              los métodos de pago aceptados por nuestro procesador de pagos de terceros se pueden
              utilizar para adquirir cursos.
            </p>
          </ol>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>CONTENIDOS Y LOS SERVICIOS</h3>
          </li>
          <ol>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>
                Obligación de hacer uso correcto del sitio y de los contenidos
              </h3>
            </li>
            <p className={css.text}>
              El USUARIO se compromete a utilizar el SITIO y los CONTENIDOS conforme a las leyes
              aplicables, a lo dispuesto en estos TÉRMINOS DE USO y con respeto al orden público. El
              USUARIO se obliga utilizar el SITIO y cualquier CONTENIDO o aspecto relacionado con
              él, de una manera en que no lesione derechos o intereses de LA UNIVERSIDAD TECMILENIO,
              de personas vinculadas a éste directa o indirectamente o de terceros. El USUARIO
              utilizará el SITIO y/o los CONTENIDOS de una manera en la que no los dañe, inutilice,
              deteriore o menoscabe total o parcialmente.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Medios para obtención de contenidos</h3>
            </li>
            <p className={css.text}>
              l USUARIO deberá abstenerse de obtener o intentar obtener información, mensajes,
              gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software y
              en general, cualquier clase de material accesible a través del SITIO o de los
              CONTENIDOS.
            </p>
            <p className={css.text}>
              Se podrá hacer uso de los CONTENIDOS y los elementos que se encuentran en el SITIO
              empleando para ello únicamente los medios o procedimientos establecidos e indicados
              dentro del mismo.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Uso correcto de los contenidos</h3>
            </li>
            <p className={css.text}>
              El USUARIO se obliga a usar los CONTENIDOS y los elementos utilizados en el SITIO de
              forma diligente, correcta y lícita, y en particular, se compromete a abstenerse de:
              (a) utilizar los CONTENIDOS de forma, con fines o efectos contrarios a la ley, al
              orden público y a lo establecido por LA UNIVERSIDAD TECMILENIO para el uso de este
              SITIO; (b) copiar, difundir, modificar, reproducir, distribuir o utilizar de cualquier
              forma con o sin fines de lucro los CONTENIDOS y los elementos utilizados en el SITIO,
              a menos que se cuente con la autorización expresa y por escrito de LA UNIVERSIDAD
              TECMILENIO; (c) modificar o manipular las marcas, logotipos, avisos comerciales,
              nombres comerciales y signos distintivos en general de LA UNIVERSIDAD TECMILENIO, del
              SITIO o de las personas vinculadas directa o indirectamente con LA UNIVERSIDAD
              TECMILENIO (salvo que cuente con su autorización por escrito), (d) suprimir, eludir o
              modificar los CONTENIDOS y los elementos utilizados en el SITIO, así como los
              dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido
              en el SITIO.
            </p>
            <p className={css.text}>
              LA UNIVERSIDAD TECMILENIO declara que todos los CONTENIDOS y los elementos utilizados
              en el SITIO se encuentran debidamente registrados y protegidos bajo las autoridades y
              leyes correspondientes en México. El USUARIO se obliga a respetar todos los derechos
              de la Propiedad Intelectual sobre los CONTENIDOS y los elementos utilizados en el
              SITIO de los que es titular LA UNIVERSIDAD TECMILENIO.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>
                Utilización del sitio, de los contenidos y de los contenidos bajo la exclusiva
                responsabilidad del usuario
              </h3>
            </li>
            <p className={css.text}>
              Por el sólo hecho de acceder al SITIO, el USUARIO reconoce y acepta que el uso del
              mismo y de los CONTENIDOS, es bajo su exclusiva y estricta responsabilidad, por lo que
              LA UNIVERSIDAD TECMILENIO no será en ningún momento y bajo ninguna circunstancia,
              responsable por cualquier desperfecto o problema que se presentara en el equipo de
              cómputo (hardware) o programas de cómputo (software) que utilice el USUARIO para
              acceder o navegar en cualquier parte del SITIO.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Cuenta</h3>
            </li>
            <p className={css.text}>
              Para utilizar algunas partes del Servicio, el USUARIO debe crear una cuenta. El
              USUARIO declara y garantiza que la información que nos proporciona al momento del
              registro y en cualquier otro momento será verdadera, precisa, actual y completa.
              También declara y garantiza que se asegurará de que esta información se mantenga
              exacta y actualizada en todo momento.
            </p>
            <p className={css.text}>
              El USUARIO es responsable de mantener la confidencialidad de sus credenciales de
              inicio de sesión y es totalmente responsable de todas las actividades que se realicen
              mediante el uso de sus credenciales. Para mayor información sobre los datos recabados
              para crear su cuenta favor de consultar nuestro Aviso de Privacidad en{' '}
              <Link to={`/aviso-de-privacidad`}>
                https://www.digital.nowisnao.com/aviso-de-privacidad.
              </Link>
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Exclusión de garantías y de responsabilidad</h3>
            </li>
            <p className={css.text}>
              Exclusión de Garantías y de Responsabilidad por el Funcionamiento del SITIO y de los
              CONTENIDOS, virus y otros elementos dañinos. LA UNIVERSIDAD TECMILENIO no controla y
              no garantiza la ausencia de virus en los CONTENIDOS, ni la ausencia de otros elementos
              en los CONTENIDOS que puedan producir alteraciones en el sistema informático del
              USUARIO (software y/o hardware) o en los documentos electrónicos y ficheros
              almacenados en su sistema informático.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>
                Sitios y contenidos ajenos al SITIO y a los CONTENIDOS
              </h3>
            </li>
            <p className={css.text}>
              Al ser una institución de carácter privado, sin fines de lucro, independiente y ajena
              a partidarismos políticos y religiosos, LA UNIVERSIDAD TECMILENIO no permite la
              publicación de contenidos que contravengan a este carácter y se reserva el derecho de
              aceptar publicar o sugerir sitios, subsitios o material ajenos al SITIO y a LA
              UNIVERSIDAD TECMILENIO que considere de interés para los USUARIOS. En caso de hacerlo,
              LA UNIVERSIDAD TECMILENIO no es responsable en forma alguna de dichos sitios y
              materiales, así como del acceso o uso por parte del USUARIO de los mismos.
            </p>
            <p className={css.text}>
              De la misma forma cualquier sitio y/o subsitio en Internet, ajeno al SITIO, puede
              tener una liga (vínculo, hipervínculo, "link") al SITIO o CONTENIDOS con o sin
              autorización de LA UNIVERSIDAD TECMILENIO. Por lo que LA UNIVERSIDAD TECMILENIO no es
              responsable del origen, los usos y destinos de dichos vínculos. De la misma manera, el
              responsable de los sitios y/o subsitios que mencionan o manejan un vínculo al SITIO
              y/o los CONTENIDOS, no está necesariamente autorizado para manejar dichos vínculos, ni
              para promover al LA UNIVERSIDAD TECMILENIO, el SITIO o los CONTENIDOS. LA UNIVERSIDAD
              TECMILENIO no asume ninguna responsabilidad de dichos sitios, ni de sus contenidos
              ajenos al SITIO, ni acepta cargo alguno por publicidad, a menos que se cuente con un
              contrato previamente firmado por las partes.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>
                Negación y retiro del acceso al sitio y/o a los contenidos
              </h3>
            </li>
            <p className={css.text}>
              LA UNIVERSIDAD TECMILENIO se reserva el derecho a negar o retirar el acceso al SITIO
              y/o a los CONTENIDOS en cualquier momento y sin de previo aviso, por iniciativa propia
              o a petición de cualquier persona, a cualquier USUARIO por cualquier motivo,
              incluyendo sin limitación a aquellos USUARIOS que den un uso indebido al SITIO, a
              cualquiera de sus partes o secciones o a los CONTENIDOS, o que incumplan total o
              parcialmente estos TÉRMINOS DE USO o las CONDICIONES PARTICULARES que resulten
              aplicables.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Duración y terminación</h3>
            </li>
            <p className={css.text}>
              El SITIO tiene una duración indefinida, mientras que los Servicios y los CONTENIDOS
              podrán tener una duración definida de acuerdo a las políticas de pagos y de cada
              servicio o curso al que se inscriba el USUARIO. Sin embargo, LA UNIVERSIDAD TECMILENIO
              podrá dar por terminada, suspender o interrumpir en cualquier momento y sin necesidad
              de previo aviso el SITIO y/o cualquiera de los CONTENIDOS.
            </p>
          </ol>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>OBLIGACIÓN DE OBSERVAR LAS INSTRUCCIONES</h3>
          </li>
          <p className={css.text}>
            El USUARIO se compromete a seguir al pie de la letra cualquier instrucción impartida por
            LA UNIVERSIDAD TECMILENIO o por personal autorizado por LA UNIVERSIDAD TECMILENIO que se
            encuentre relacionado con el SITIO, en referencia con cualquier mecanismo o
            procedimiento establecido para usar el SITIO y los CONTENIDOS. El USUARIO únicamente
            deberá seguir instrucciones impartidas por LA UNIVERSIDAD TECMILENIO.
          </p>
          <ol>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Responsabilidad por daños y perjuicios</h3>
            </li>
            <p className={css.text}>
              El USUARIO es responsable de cualquier daño y/o perjuicio de cualquier naturaleza que
              ocasionara por incumplir estos TÉRMINOS DE USO o cualquier normatividad aplicable, por
              lo que deslinda al LA UNIVERSIDAD TECMILENIO de toda responsabilidad civil, penal,
              administrativa o de cualquier otra índole.
            </p>
            <p className={css.text}>
              Sin perjuicio de lo establecido en el párrafo anterior, en el supuesto de que LA
              UNIVERSIDAD TECMILENIO fuera sancionado o condenado por autoridad competente en
              cualquier procedimiento relacionado con responsabilidad civil, penal, administrativa o
              de cualquier otra índole, notificará al USUARIO responsable, quien deberá pagar al LA
              UNIVERSIDAD TECMILENIO la cantidad a que haya sido condenado a cubrir, así como las
              costas, honorarios y demás gastos en que se hubiera incurrido, dentro de los 15 días
              naturales siguientes a la notificación. En caso de retraso del pago en el término
              indicado, EL USUARIO se obliga a pagar al LA UNIVERSIDAD TECMILENIO por concepto de
              pena convencional, una cantidad equivalente a la suerte principal.
            </p>
          </ol>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>DATOS DE CARÁCTER PERSONAL</h3>
          </li>
          <p className={css.text}>
            Para utilizar o gozar de algunos de los CONTENIDOS es necesario que el USUARIO
            proporcione previamente al LA UNIVERSIDAD TECMILENIO ciertos datos de carácter personal
            (en adelante DATOS PERSONALES) que LA UNIVERSIDAD TECMILENIO podrá administrar
            automatizadamente o no. El USUARIO, al acceder al SITIO o a cualquiera de los CONTENIDOS
            en que los DATOS PERSONALES son requeridos, está autorizando al LA UNIVERSIDAD
            TECMILENIO a realizar análisis y estudios con base en ellos.
          </p>

          <p className={css.text}>
            El USUARIO se obliga a proporcionar DATOS PERSONALES verdaderos y fidedignos, y en caso
            de que deseara no darlos en las partes del SITIO que se soliciten, deberá abstenerse de
            usar esa o esas partes o secciones del SITIO. En caso de que el USUARIO diera
            información falsa o confusa, LA UNIVERSIDAD TECMILENIO podrá negarle el acceso al SITIO
            y los CONTENIDOS dentro de él, sin perjuicio de que pueda requerirle las indemnizaciones
            a que hubiere lugar.
          </p>

          <p className={css.text}>
            Por el hecho de proporcionar sus DATOS PERSONALES en el SITIO, el USUARIO está
            autorizando a LA UNIVERSIDAD TECMILENIO a dar a conocer a cualquier autoridad competente
            la información respectiva, en caso de que ésta sea solicitada por los medios jurídicos
            adecuados. LA UNIVERSIDAD TECMILENIO no compartirá información personal alguna que haya
            sido proporcionada, con terceras personas, a menos que sea requerido para proporcionar
            un servicio o un producto requerido por el USUARIO.
          </p>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>LICENCIA Y PROPIEDAD INTELECTUAL</h3>
          </li>
          <p className={css.text}>
            LA UNIVERSIDAD TECMILENIO autoriza al USUARIO a utilizar el SITIO exclusivamente en los
            TÉRMINOS Y CONDICIONES aquí expresados, sin que ello implique que concede al USUARIO
            licencia o autorización alguna o algún tipo de derecho distinto al uso antes mencionado
            respecto a la "Propiedad Intelectual" de LA UNIVERSIDAD TECMILENIO. Entendiéndose como
            “Propiedad Intelectual” todas las marcas registradas y/o usadas en México o en el
            extranjero por LA UNIVERSIDAD TECMILENIO, así como todo derecho sobre invenciones
            (patentadas o no), diseños industriales, modelos de utilidad, información confidencial,
            nombres comerciales, avisos comerciales, reservas de derechos, nombres de dominio, así
            como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por
            derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que
            lleguen a reconocer las leyes correspondientes.
          </p>
          <li className={css.subtitle}>
            <h3 className={css.subtitle}>OTRAS DISPOSICIONES</h3>
          </li>
          <p className={css.text}>
            Forma. El USUARIO acepta que una versión impresa de éstos TERMINOS Y CONDICIONES, y de
            cualquier comunicación enviada y/o recibida en forma electrónica será admisible como
            medio probatorio en cualquier procedimiento judicial y/o administrativo.
          </p>
          <p className={css.text}>
            Para la interpretación, cumplimiento y ejecución de los presentes Términos y
            Condiciones, el usuario está de acuerdo en que serán aplicables las leyes y tribunales
            competentes de la Ciudad de Monterrey Nuevo León, renunciando expresamente a cualquier
            otro fuero o jurisdicción que pudiera corresponderles debido a sus domicilios presentes
            o futuros o por cualquier otra causa.
          </p>
          <ol>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Disposiciones ineficaces</h3>
            </li>
            <p className={css.text}>
              En caso de que alguno de los TÉRMINOS Y CONDICIONES aquí expresados sea considerado no
              ejecutable o se declare nulo o inválido de acuerdo con la legislación aplicable, éste
              será sustituido por un término o condición que sea válido y que pueda cumplir con
              mayor rigor el objetivo de la disposición no ejecutable o que haya sido declarada nula
              o inválida. Los demás términos y condiciones continuarán en plena vigencia.
            </p>
            <li className={css.subtitle}>
              <h3 className={css.subtitle}>Derechos</h3>
            </li>
            <p className={css.text}>
              Cualquier derecho que no se haya conferido expresamente en éste documento se entiende
              reservado al LA UNIVERSIDAD TECMILENIO.
            </p>
            <p className={css.text}>
              Para cualquier asunto relacionado con estos términos y condiciones contacte a
              UNIVERSIDAD TECMILENIO en el correo:
              <a href="mailto:jr.garciag@tecmilenio.mx">jr.garciag@tecmilenio.mx</a>
            </p>
            <p className={css.text}>
              Se prohíbe la reproducción total o parcial de este documento por cualquier medio sin
              previo y expreso consentimiento por escrito de Enseñanza e Investigación Superior AC,
              a cualquier persona y actividad que sean ajenas al mismo. CONOZCO Y ACEPTO LOS
              TERMINOS Y CONDICIONES DE USO DEL SITIO{' '}
              <Link to="/">https://www.digital.nowisnao.com/</Link>
            </p>
          </ol>
        </ol>
      </div>
      <Footer />
    </>
  )
}
