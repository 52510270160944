import React from 'react'
import Footer from '../../components/modules/main/partials/Footer'
import { showUser } from '../../service/module/Users'
import { useProfile } from '../../context/MainContext'

import { useHistory, useLocation } from 'react-router-dom'

import css from './Community.module.css'

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Community() {
    // Custom hooks
    const history = useHistory()
    const query = useQuery()
    if (query.get('error') === 'access_denied') {
        history.push('/verify-email')
    }
    const { userId, setImageProfile } = useProfile()
    // States
    const [user, setUser] = React.useState(null)
    const [stepsStyles, setStepStyles] = React.useState(null)
    // Refs
    const iframeRef = React.useRef(null)
    const rootRef = React.useRef(null)

    React.useEffect(() => {
        async function getUser(id) {
            if (id) {
                await showUser(id).then(response => {
                    const userFilled = response.data[0]
                    setImageProfile(userFilled.profile?.image_profile)
                    setUser(userFilled)
                    localStorage.setItem('role_id', userFilled.role_id)
                })
            }
        }
        getUser(userId)

        if(rootRef.current.clientWidth > 768) {
            setStepStyles({ maxHeight: `${iframeRef.current.clientHeight}px` })
        }

    }, [userId])

  return (
    <div ref={rootRef} className={css.root}>
        {/* Container */}
        <div className={css.container}>
            <>
                {/* Intro:  */}
                <h6 className={css.title}>
                    <b>¡Hola {user && user.names}!</b>
                </h6>
                <h5 className={css.descriptionText}>
                    En esta sección podrás encontrar un video con la explicación de tu ingreso a la Comunidad NAO.
                </h5>
                <h5 className={css.descriptionText}>
                    Nuestra comunidad es un espacio diseñado para conectar virtualmente y hacer coincidir a los aprendedores Digital NAO que se encuentran en lugares distintos y distantes; en ella se generan y promueven conversaciones, discusiones, críticas constructivas, consejos, mejores prácticas y, sobre todo, se fomenta el aprendizaje entre pares.
                </h5>
                <h5 className={css.descriptionText}>
                    Además, la comunidad tiene una función vertebral para tu experiencia de aprendizaje, pues es el medio de enlace con Digital NAO. Es a través de la comunidad que podrás informar sobre tus avances en el desarrollo de los Retos y mantenerte actualizado respecto a noticias, fechas y procesos relacionados con tu experiencia de aprendizaje. La Comunidad NAO también cuenta con un canal de soporte técnico, que resultará de gran utilidad. Recuerda que es importante que te integres, participes y permanezcas atento a las publicaciones y comunicados.
                </h5>
                {/* Row */}
                <div className={css.row}>
                    {/* Video Iframe */}
                    <iframe
                        ref={iframeRef} 
                        className={css.iframe}
                        width="560" 
                        height="315" 
                        src="https://www.youtube.com/embed/FM85AMGJODw?si=-5whVnEOi8JgDr51" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen>
                    </iframe>
                    {/* Steps */}
                    <ul style={stepsStyles} className={css.steps}>
                        {/* Step 1 */}
                        <li className={css.step}>
                            <h6 className={css.stepTitle}>Únete al servidor de Discord</h6>
                            <p className={css.stepContent}>Al terminar tu proceso de compra, sigue las instrucciones indicadas en la tienda. Da clic al enlace de <b>Discord</b> para poder entrar a la <b>Comunidad NAO</b>.</p>
                            <small className={css.stepNote}><b>Nota:</b> Es importante dar clic en el botón que dice "Copiar folio", pues se utilizará en pasos próximos.</small>
                        </li>
                        {/* Step 2 */}
                        <li className={css.step}>
                            <h6 className={css.stepTitle}>Inicia sesión o crea una cuenta</h6>
                            <p className={css.stepContent}>Si ya tienes una cuenta de Discord, <b>inicia sesión</b> para entrar al servidor. De lo contrario, <b>crea una cuenta</b> siguiendo los pasos indicados por Discord.</p>
                        </li>
                        {/* Step 3 */}
                        <li className={css.step}>
                            <h6 className={css.stepTitle}>Entra al chat de NaoBot</h6>
                            <p className={css.stepContent}>Al aceptar la invitación, recibirás un mensaje de NaoBot. Da clic a la notificación para entrar al chat.</p>
                            <small className={css.stepNote}><b>Nota:</b> Si no visualizas la notificación, puedes dar clic al logo de Discord (en la esquina superior izquierda). Encontrarás el mensaje en la lista de Mensajes Directos.</small>
                        </li>
                        {/* Step 4 */}
                        <li className={css.step}>
                            <h6 className={css.stepTitle}>Escribe el comando /verificar</h6>
                            <p className={css.stepContent}>Dentro del chat de NaoBot, escribe el comando <b>/verificar</b>. Al ingresarlo, se pedirá el folio de compra que se obtuvo en el primer paso. Cópialo, pégalo en el campo que se lo solicita y presiona la tecla <b>ENTER</b>.</p>
                        </li>
                        {/* Step 5 */}
                        <li className={css.step}>
                            <h6 className={css.stepTitle}>Valida tus datos personales</h6>
                            <p className={css.stepContent}>Después de usar el comando, NaoBot devolverá un mensaje con tu <b>información personal</b>. Verifica que los datos sean correctos y da clic al botón "Confirmar datos".</p>
                            <small className={css.stepNote}><b>Nota:</b> En caso de tener algún error, da clic en el botón "Corregir datos" y llena el formulario que te aparecerá en el chat.</small>
                        </li>
                        {/* Step 6 */}
                        <li className={css.step}>
                            <h6 className={css.stepTitle}>Comienza a interactuar</h6>
                            <p className={css.stepContent}>¡Listo! Has realizado tu verificación. Da clic en el enlace para regresar al servidor e interactuar en tus nuevos canales habilitados.</p>
                            <small className={css.stepNote}><b>Nota:</b> Los canales que se te habilitarán dependen de tu trayectoria y modalidad.</small>
                        </li>
                    </ul>
                </div>
                {/* Discord Invite Button */}
                <div className={css.buttonNextContainer}>
                    <a
                        className={css.buttonContinue}
                        href={"https://discord.gg/FUTnmPqg2W"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Ingresar a Discord
                    </a>
                </div>
            </>
        </div>
        <Footer />
    </div>
  )
}
