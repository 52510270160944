import React from 'react'

import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import Swal from 'sweetalert2'

import { Pagination, PaginationItem, PaginationLink, Alert, Modal } from 'reactstrap'
import Button from '../../components/modules/dashboard/partials/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faAngleRight, faAngleLeft, faClone, faSearch } from '@fortawesome/free-solid-svg-icons'
import Header from '../../components/modules/dashboard/partials/Header'
import SearchInput from '../../components/modules/dashboard/partials/SearchInput'

import { getAllChallenges, deleteChallengeModule, duplicateChallenge, getFindChallenge } from '../../service/module/Challenges'
import { useProfile } from '../../context/MainContext'
import css from './Challenges.module.css'

import copyIcon from '../../assets/bt-copiar.svg'
import loadingIcon from '../../assets/rolling.svg'

export default function Challenges() {
  const [message, setMessage] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')
  const [meta, setMeta] = React.useState()
  const [list, setList] = React.useState()
  const [pages, setPages] = React.useState()
  const [copyModal, setCopyModal] = React.useState(false)
  const [confirmCopyModal, setConfirmCopyModal] = React.useState(null)
  const { userType, userId } = useProfile()
  const [changeStatusModal, setChangeStatusModal] = React.useState(null)
  const [notification, setNotification] = React.useState()
  const history = useHistory()
  const location = useLocation()
  const [isChallengeDeleted, setIsChallengeDeleted] = React.useState()
  
  const [word, setWord] = React.useState('')

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
      setColorMessage('success')
      setNotification('Éxito')
    }
  }, [location])

  React.useEffect(() => {
    async function getChallenges(current) {
      await getAllChallenges(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    async function getFindChallenges(word, currentPage) {
      await getFindChallenge(word, currentPage)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }  

    if (!word || word === '') {
      getChallenges(currentPage)
    } else {
      getFindChallenges(word, currentPage)
    }

  }, [currentPage, isChallengeDeleted])

  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  function add(e) {
    e.preventDefault()
    history.push('/dashboard/challenges/add')
  }

  function editChallengeAction(challengeId) {
    history.push(`/dashboard/challenges/${challengeId}/edit`)
  }

  async function deleteChallenge(challengeId) {
    await deleteChallengeModule(challengeId, { created_by: userId })
      .then(response => {
        setMessage('Reto eliminado correctamente')
        setColorMessage('success')
        setNotification('Éxito')
        setIsChallengeDeleted(!isChallengeDeleted)
      })
      .catch(error => {
        setMessage(error.message)
        setColorMessage('danger')
        setNotification('Error')
      })
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.cancelButton,
      denyButton: css.confirmButton,
      icon: css.warningIcon,
    },
    buttonsStyling: false,
  })

  function toggleModal(id) {
    swalCustom.fire({
      title: '¿Estás seguro que deseas eliminar este reto?',
      iconHtml: '<div className="' + css.warningIcon + '"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Cancelar',
      denyButtonText: 'Eliminar reto',
    }).then(_result => {
      if (_result.isDenied) {
        deleteChallenge(id)
      }
    })
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage('')
      setCurrentPage(meta.next_page_url)
    }
  }

  const duplicateSwalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.duplicateConfirmButton,
      denyButton: css.duplicateCancelButton,
    },
    buttonsStyling: false,
  })

  const startCopy = async (id) => {
    setConfirmCopyModal(null)
    setCopyModal(true)
    await duplicateChallenge(id, { created_by: userId })
      .then(response => {
        setCopyModal(false)
        if (response.success) {
          duplicateSwalCustom.fire({
            title: 'Reto duplicado exitosamente',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: 'Editar reto',
            confirmButtonText: 'Regresar',
          }).then(result => {
            if (result.isConfirmed) {

              history.push('/dashboard/challenges')
      getChallenges('/?page=1')
            }
            if (result.isDenied) {
              history.push(`/dashboard/challenges/${response.data[0].challengeCreated}/edit`)
            }
          })
        }

        if (!response.success) {
          duplicateSwalCustom.fire({
            title: 'Error al duplicar el reto',
            text: response.errors,
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          }).then(result => {
            if (result.isConfirmed) {
              history.push('/dashboard/challenges')

            }
          })
        }
      })
      .catch(error => {
        setCopyModal(false)
        duplicateSwalCustom.fire({
          title: 'Error al duplicar el reto',
          text: error.message,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'Regresar',
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/dashboard/challenges')
          }
        })
      })
  }
  

  function search() {
    async function getFindChallenges(word) {
      await getFindChallenge(word)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(error => {
          console.error(error)
        })
    }
  
    getFindChallenges(word)
  }

  React.useEffect(() => {
    async function getChallenges(current) {
      await getAllChallenges(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    if (word.length > 2) {
      console.log('entro search')
      search()
    }

    if (word.length === 0) {
      console.log('entro')
      getChallenges('/?page=1')
    }
  }, [word])

    async function getChallenges(current) {
      await getAllChallenges(current)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
      }

  return (
    <div>
      <Header />
      {/* Page content */}
      <div className={classNames(css.root)}>
        {message && colorMessage && (
          <Alert color={colorMessage}>
            <strong>¡{notification}! {' '}</strong>{message}
          </Alert>
        )}
        <div className={css.tableContainer}>
          <div className={css.tableHeader}>
            <h3 className={css.title}>Lista de Retos</h3>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
              <SearchInput onSearch={setWord} placeholder="Buscar nombre" />
            </div>
            {(userType === 'operative' || userType === 'manager') && (
              <Button href="/dashboard/users/add" className={css.addButton} onClick={add}>
                Agregar
              </Button>
            )}
          </div>
          <div className={css.tableHolder}>
            <div className={css.table}>
              <div className={css.thead}>
                <div className={css.tr}>
                  <div scope="col" className={classNames(css.th, css.titleTh)}>
                    <p>Nombre</p>
                  </div>
                  <div scope="col" className={css.th} style={{ textAlign: 'center' }}>
                    <p>Modalidades</p>
                  </div>
                  <div scope="col" className={css.th} style={{ textAlign: 'center' }}>
                    <p>Perfiles en uso</p>
                  </div>
                  {(userType === 'operative' || userType === 'manager') && (
                    <div scope="col" className={classNames(css.th, css.actionButtons)}>
                      <p>Acciones</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={css.tbody}>
                {list &&
                  list.map((item, key) => {
                    return (
                      <div key={key} className={css.tr}>
                        <div className={css.titleCol}>
                          <span className={css.name}>{item.name}</span>
                          <p className={css.description}>{item.description}</p>
                        </div>
                        <div className={(css.td, css.roleCount)}>
                          <p>{item.modalities_count || 0}</p>
                        </div>
                        <div className={(css.td, css.roleCount)}>
                          <p>{item.roles_count}</p>
                        </div>
                        {(userType === 'operative' || userType === 'manager') && (
                          <div className={classNames(css.td, css.actionButtons)}>
                            <Button
                              isicon={true}
                              type="button"
                              title="Editar"
                              className={classNames(css.icon, css.edit)}
                              onClick={() => setChangeStatusModal(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Button>

                          {/*
                            <Button
                              isicon={true}
                              type="button"
                              title="Copiar"
                              className={classNames(css.icon, css.copy)}
                              onClick={() => setConfirmCopyModal(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faClone} />
                              </span>
                            </Button> */}
                            <Button
                              isicon={true}
                              type="button"
                              title="Eliminar"
                              className={classNames(css.icon, css.delete)}
                              disabled={item && item.roles_count >= 1 ? true : false}
                              onClick={() => toggleModal(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </Button>
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
          <div className={css.tableFooter}>
            {list && list.length > 0 ? (
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  {meta && (
                    <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                      <PaginationLink
                        href={meta.first_page_url}
                        onClick={previous}
                        tabIndex={!meta.previous_page_url ? '-1' : undefined}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  {pages &&
                    pages.map((item, key) => {
                      /*
                           Always show 9 pages (if there are more than 9)
                           show 4 pages before and 4 pages after the current page
                          */
                      if (meta.current_page <= 5 && key < 9) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }

                      if (meta.current_page > 5) {
                        if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      }

                      if (meta.last_page - meta.current_page < 5) {
                        if (key > meta.last_page - 10) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      }
                    })}
                  {meta && (
                    <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                      <PaginationLink href={meta.last_page_url} onClick={next}>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                </Pagination>
              </nav>
            ) : (
              <div className={css.noData}>
                <p>Sin retos</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className={css.modal}
        contentClassName={css.modalContent}
        isOpen={changeStatusModal !== null}
        toggle={() => setChangeStatusModal(null)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setChangeStatusModal(null)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className={css.modalBody}>
          <h6 className={css.modalTitle} id="modal-title-notification">
            ¿Editar?
          </h6>
          <div className={css.modalText}>
            <p>
              Si editas este reto impactaras a los <b>perfiles</b> que lo tengan asignado.
            </p>
            <p>
              Esta acción no podrá deshacerse.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button className={css.cancelar} light={true} type="button" onClick={() => setChangeStatusModal(null)}>
            Cancelar
          </Button>
          <Button className={css.delete} type="button" onClick={() => editChallengeAction(changeStatusModal)}>
            Continuar
          </Button>
        </div>
      </Modal>
      <Modal
        className={css.modal}
        contentClassName={css.modalContent}
        isOpen={copyModal}
        toggle={() => { }}
      >
        <div className="modal-header">
        </div>
        <div className={css.modalBody}>
          <div className="py-3 text-center">
            <img src={loadingIcon} alt="loading" width="80px" />
            <h4 className="heading mt-4">Duplicando recurso</h4>
            <p>Esto podría tomar algunos minutos</p>
          </div>
        </div>
        <div className="modal-footer">
        </div>
      </Modal>
      <Modal
        className={css.modal}
        contentClassName={css.modalContent}
        isOpen={confirmCopyModal !== null}
        toggle={() => setConfirmCopyModal(null)}
      >
        <div className="modal-header">
          <h6 className={css.modalTitle} id="modal-title-notification">
            ¿Deseas duplicar este reto?
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setConfirmCopyModal(null)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className={css.modalBody}>
          <div className={css.modalText}>
            {confirmCopyModal && (
              <p>
                Se creará una copia del reto <b>
                  {list && list.find(item => item.id === confirmCopyModal).name}
                  </b> con el mismo contenido.
              </p>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button className={css.cancelar} light={true} type="button" onClick={() => setConfirmCopyModal(null)}>
            Cancelar
          </Button>
          <Button className={css.confirm} type="button" onClick={() => startCopy(confirmCopyModal)}>
            Sí, duplicar
          </Button>
        </div>
      </Modal>
    </div>
  )
}
