import React from 'react'
import { useHistory } from 'react-router-dom'
import ReactDatetime from 'react-datetime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { Alert } from 'reactstrap'

import Button from '../partials/Button'

import { makeStyles } from '@material-ui/core'

import css from './UserForm.module.css'
import classNames from 'classnames'

/*
const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  logo: {
    maxHeight: 65,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 12,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 20,
    },
  }
}))
*/

const useStyles = makeStyles(theme => ({
  h6: {
    color: '#696A6D',
  },
}))

export default function UserForm({
  register,
  errors,
  changeInput,
  changeDate,
  title,
  user,
  update,
  message,
  changed
}) {
  const history = useHistory()


  const hiddenElements = false
  return (
    <div className={css.root}>
      {message && typeof message === 'object' ? (
          <Alert color="danger">
            <strong>¡Ocurrio un error! |</strong>
          </Alert>
        ) : message ? (
          <Alert color="success">
            <strong>{update ? "¡Actualización exitosa!" : "Registro exitoso"}</strong>
          </Alert>
        ) : null}
      <div className={classNames(css.tableContainer, css.darkTableContainer)}>
        <div className={css.tableHeader}>
          <h3 className={css.title}>{title}</h3>
        </div>
        <form className={css.form}>
          <h6 className={css.subtitle}>Inicio de Sesión</h6>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-email">
                Email
              </label>
              <input
                invalid={errors && errors.email ? true : false}
                onChange={changeInput}
                name="email"
                id="input-email"
                defaultValue={user && user.email ? user.email : ''}
                className={css.input}
                placeholder="example@example.com"
                type="text"
              />
              {errors && errors.email && <div className={css.feedback}>{errors.email}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-user-type">
                Tipo de Usuario
              </label>
              <select
                name="user_type"
                onChange={changeInput}
                value={user && user.user_type}
                id="input-user-type"
                className={css.input}
              >
                <option value="learner">Aprendedor</option>
                <option value="operative">Operativo</option>
                <option value="manager">Manager</option>
              </select>
            </div>
          </div>
          <hr hidden={hiddenElements} className="my-4" />
          <h6 hidden={hiddenElements} className={css.subtitle}>
            Información
          </h6>
          <div
            className={classNames(css.inputs, {
              [css.hidden]: hiddenElements,
            })}
          >
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-names">
                Nombre(s)
              </label>
              <input
                invalid={errors && errors.names ? true : false}
                onChange={changeInput}
                id="input-names"
                name="names"
                defaultValue={user && user.names ? user.names : ''}
                className={css.input}
                type="text"
                readOnly
              />
              {errors && errors.names && <div className={css.feedback}>{errors.names}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-last-name">
                Apellido(s)
              </label>
              <input
                invalid={errors && errors.last_name ? true : false}
                id="input-last-name"
                onChange={changeInput}
                name="last_name"
                defaultValue={user && user.last_name ? user.last_name : ''}
                className={css.input}
                type="text"
                readOnly
              />
              {errors && errors.last_name && <div className={css.feedback}>{errors.last_name}</div>}
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="Fecha de Nacimiento">
                Fecha de Nacimiento
              </label>
              <div className={css.inputGroup}>
                <div className={css.iconHolder}>
                  <div className={css.icon}>
                    <i className="ni ni-calendar-grid-58" />
                  </div>
                </div>
                <ReactDatetime
                  className={css.datePickerContainer}
                  onChange={changeDate}
                  title="Fecha de nacimiento"
                  value={
                    user && user.birthdate
                      ? new Date(
                          user.birthdate.split('-')[0],
                          user.birthdate.split('-')[1].replace('0', '') - 1,
                          user.birthdate.split('-')[2]
                        )
                      : ''
                  }
                  inputProps={{
                    placeholder: 'Clic para abrir el calendario',
                    readOnly: true,
                    className: classNames(css.input, css.iconInput),
                  }}
                  closeOnSelect={true}
                  timeFormat={false}
                />
              </div>
              <input
                className={css.hidden}
                invalid={errors && errors.birthdate ? true : false}
                onChange={changeInput}
                value={user && user.birthdate ? user.birthdate : ''}
                type="text"
              />
              {errors && errors.birthdate && <div className={css.feedback}>{errors.birthdate}</div>}
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-gender">
                Genero
              </label>
              <select
                name="gender"
                type="select"
                value={user && user.gender}
                id="input-gender"
                className={css.input}
                readOnly
              >
                <option value="female">Femenino</option>
                <option value="male">Másculino</option>
              </select>
            </div>
          </div>
          <hr hidden={hiddenElements} className="my-4" />
          <h6 hidden={hiddenElements} className={css.subtitle}>
            Acerca de Mí
          </h6>
          <div className={css.inputHolder}>
            <label>Bio</label>
            <textarea
              name="bio"
              onChange={changeInput}
              defaultValue={user && user.bio ? user.bio : ''}
              className={classNames(css.input, css.textArea)}
              placeholder="Cuéntanos sobre tí..."
              rows="5"
              readOnly
            />
          </div>
          <div
            className={classNames(css.inputs, {
              [css.hidden]: hiddenElements,
            })}
          >
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-linked-in">
                LinkedIn (URL)
              </label>
              <div className={css.inputGroup}>
                <div className={css.iconHolder}>
                  <div className={css.icon}>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </div>
                </div>
                <input
                  name="linkedin"
                  onChange={changeInput}
                  className={classNames(css.input, css.iconInput)}
                  defaultValue={user && user.linkedin ? user.linkedin : ''}
                  id="input-linked-in"
                  type="text"
                  readOnly
                />
              </div>
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-twitter">
                Twitter (URL)
              </label>
              <div className={css.inputGroup}>
                <div className={css.iconHolder}>
                  <div className={css.icon}>
                    <FontAwesomeIcon icon={faTwitter} />
                  </div>
                </div>
                <input
                  className={classNames(css.input, css.iconInput)}
                  name="twitter"
                  onChange={changeInput}
                  defaultValue={user && user.twitter ? user.twitter : ''}
                  id="input-twitter"
                  type="text"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-facebook">
                Facebook (URL)
              </label>
              <div className={css.inputGroup}>
                <div className={css.iconHolder}>
                  <div className={css.icon}>
                    <FontAwesomeIcon icon={faFacebook} />
                  </div>
                </div>
                <input
                  name="facebook"
                  onChange={changeInput}
                  defaultValue={user && user.facebook ? user.facebook : ''}
                  className={classNames(css.input, css.iconInput)}
                  id="input-facebook"
                  type="text"
                  readOnly
                />
              </div>
            </div>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-instagram">
                Instagram (URL)
              </label>
              <div className={css.inputGroup}>
                <div className={css.iconHolder}>
                  <div className={css.icon}>
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                </div>
                <input
                  name="instagram"
                  onChange={changeInput}
                  defaultValue={user && user.instagram ? user.instagram : ''}
                  className={classNames(css.input, css.iconInput)}
                  id="input-instagram"
                  type="text"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="Otro">
                Otro (URL)
              </label>
              <input
                name="other"
                onChange={changeInput}
                defaultValue={user && user.other ? user.other : ''}
                className={css.input}
                id="Otro"
                type="text"
                readOnly
              />
            </div>
          </div>
        </form>
        <div className={css.tableFooter}>
          <nav aria-label="...">
            <div className={css.pagination}>
              <Button
                className={css.delete}
                type="button"
                onClick={() => {
                  history.push('/dashboard/users')
                }}
              >
                Cancelar
              </Button>
              {register && (
                <Button color="buttonColor" type="button" onClick={register}>
                  Registrar
                </Button>
              )}
              {update && (
                <Button color="buttonColor" type="button" onClick={update} disabled={changed}>
                  Actualizar
                </Button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
