import axios from 'axios'

const containerName = localStorage.getItem('container')

// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return true
}

const uploadFileToBlob = async file => {
  let fileUploaded = ''
  const token = localStorage.getItem('token')

  if (!file) return null
  const formData = new FormData()
  formData.append('container', containerName)
  formData.append('file', file)
  formData.append('token', token)
  const config = {
    Headers: { 'Content-Type': 'multipart/form-data' },
  }
  await axios
    .post('https://' + window.location.hostname + '/api/files/create', formData, config)
    .then(res => {
      fileUploaded = res.data.files[0]
    })
    .catch(err => {
      console.log(err)
      return null
    })

  return fileUploaded
}

export default uploadFileToBlob
