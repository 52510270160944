import React from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../../components/modules/dashboard/partials/Header'
import ChallengesForm from '../../components/modules/dashboard/challenges/ChallengesForm'
import Swal from 'sweetalert2'
import { getAllRoles } from '../../service/module/Roles'
import { storeChallenge } from '../../service/module/Challenges'
import { useProfile } from '../../context/MainContext'

import css from './AddChallenge.module.css'

export default function AddChallenge() {
  const [errors, setErrors] = React.useState()
  const [challenge, setChallenge] = React.useState({})
  const [roles, setRoles] = React.useState()
  const { userType, userId } = useProfile()
  const history = useHistory()
  const [urlImage, setUrlImage] = React.useState("https://concepto.de/wp-content/uploads/2018/09/lenguaje-de-programación-e1537466880796-800x400.jpg")

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    async function getRoles() {
      await getAllRoles()
        .then(response => {
          setRoles(response)
        })
        .catch(err => {
          console.log(err.message)
        })
    }

    getRoles()
  }, [])


  function changeInput(e) {
    
    if(e.target.name === 'url_image'){ 
      setUrlImage(e.target.value)
    }

    console.log('urlImage =>', urlImage)

    setChallenge({
      ...challenge,
      [e.target.name]: e.target.value,
      created_by: userId,
      url_image: urlImage,
    })
  }

  function selectedOption(e) {
    changeInput(e)
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function register() {
    //Aquí se agrega el sprint
    await storeChallenge(challenge)
      .then(response => {
        if (response.success) {
          swalCustom.fire({
            title: 'Reto creado exitosamente',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: 'Seguir editando',
            confirmButtonText: 'Ver todos los retos',
          }).then(result => {
            if (result.isConfirmed) {
              history.push('/dashboard/challenges')
            }
            if (result.isDenied) {
              history.push(`/dashboard/challenges/${response.data.data}/edit`)
            }
          })
        }
        if (!response.success) {
          setErrors(response.errors)
          swalCustom.fire({
            title: 'Error al agregar el reto',
            text: response.errors,
            icon: 'error',
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: 'Regresar',
          })
        }
      })
      .catch(error => {
        setErrors(error.errors)
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        })
      })
  }

  return (
    <div>
      <Header />
      {(userType === 'operative' || userType === 'manager') && (
        <ChallengesForm
          changeInput={changeInput}
          errors={errors}
          register={register}
          title="Agregar Reto"
          roles={roles}
          selectedOption={selectedOption}
        />
      )}
    </div>
  )
}
