import React from 'react'
import { withStyles, makeStyles, Avatar, LinearProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import css from './MyChallengesTab.module.css'
import { ChallengeCard } from '../../..'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#000',
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
    },
    margin: 0,
  },
  subTitle: {
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px 0',
    },
    fontSize: theme.typography.pxToRem(16),
    color: '#898A8D',
    margin: 0,
  },
  challengeTitle: {
    color: '#000',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    paddingTop: 10,
    paddingLeft: 10,
    maxHeight: 60,
    height: 40,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    margin: 0,
  },
  challengeSubtitle: {
    color: '#000',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    paddingLeft: 10,
    maxHeight: 44,
    height: 44,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    marginBottom: 5,
  },
  finishedText: {
    fontSize: theme.typography.pxToRem(14),
    color: '#004DFF',
    fontWeight: 600,
    paddingLeft: 10,
    paddingBottom: 6,
    margin: 0,
  },
  pendingText: {
    fontSize: theme.typography.pxToRem(14),
    color: '#c8c6bf!important',
    fontWeight: 600,
    paddingLeft: 10,
    paddingBottom: 6,
    margin: 0,
  },
  inProgressText: {
    fontSize: theme.typography.pxToRem(14),
    color: '#004DFF',
    fontWeight: 600,
    paddingLeft: 10,
    paddingBottom: 6,
    margin: 0,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
  },
  imageContainer: {
    padding: 5,
    height: 90,
    width: 90,
  },
}))

const BlueProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 10,
    marginTop: 0,
  },
  colorPrimary: {
    backgroundColor: '#dddcdd',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#004DFF',
  },
}))(LinearProgress)

export default function MyChallengesTab({
  user,
  challenges,
  challengesComplete,
  percentageCurrent,
  currentChallenge,
  complete,
  percentage
}) {
  
  const classes = useStyles()

  const history = useHistory()

  return (
    <div className={css.root}>
      <div className={css.header}>
        <h6 className={classes.title}>
          Mis Tarjetas
        </h6>
        <div className={css.progress}>
          <p className={css.advancement}>Has completado <span className={css.blueText}>{complete && complete.length}</span> de los <span className={css.blueText}>{challenges && challenges.length}</span> tarjetas</p>
          <div className={css.progressBarContainer}>
            <BlueProgress variant="determinate" value={percentage ? percentage : 0} />
          </div>
        </div>
      </div>
      <h6 className={classes.subTitle}>
        Aquí podrás ver el avance general de tus tarjetas, y una vez que termines todos, podrás
        realizar la certificación final y descargarla para impresión o mostrar en tus redes
        sociales.
      </h6>
      <div className={css.challengesContainer}>
        {challenges &&
          challenges.map((item, index) => {
            const pastChallengeComplete = index === 0 || challenges[index - 1]?.status === 'complete' ? true : false

            return (
              <ChallengeCard 
                index={index} 
                challenge={item} 
                className={css.cardHolder}
                disabled={!pastChallengeComplete}
                onClick={() => {
                  if (!pastChallengeComplete) return
                  history.push(`/challenges/${item.challenge_id}/sprints?role_id=${user.role_id}&force_show_continue=true`)
                }}
              />
            )
          })}
      </div>
    </div>
  )
}
