import React, { useEffect, useState } from 'react';
import { getAllModalities } from '../../service/module/Modalities';

import Button from '../../components/modules/dashboard/partials/Button'

import css from './AssignNewModality.module.css';

const AssignNewModality = props => {
    const {
        className,
        onSubmit,
        onCancel,
        activeModalities,
    } = props;

    const [modalities, setModalities] = useState([]);
    const [formData, setFormData] = useState({ from: '', modality: '' });//[from, modality]
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        async function getModalities() {
            await getAllModalities()
                .then(response => {
                    // Delete the active modalities from the list
                    response.data = response.data.filter(modality => {
                        return !activeModalities.some(activeModality => activeModality.modality === modality.modality);
                    });

                    setModalities(response.data);
                })
                .catch(() => {
                    console.error('Error al obtener las modalidades')
                });
        }

        getModalities();
    }, [])

    const handleChange = event => {
        event.preventDefault();
        
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        if (formData.from && formData.modality) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [formData])

    return (
        <div className={css.root}>
            <form className={css.form} onSubmit={onSubmit}>

                <label className={css.label} htmlFor="modality">Copiar de</label>
                <select
                    className={css.input}
                    name="from"
                    id="from"
                    onChange={handleChange}
                >
                    <option value="" hidden>
                        Selecciona una modalidad
                    </option>
                    {activeModalities.map(modality => (
                        <option
                            key={"from" + modality.id}
                            value={modality.id}
                        >
                            {modality.modality}
                        </option>
                    ))}
                </select>

                <label className={css.label} htmlFor="modality">Nombre de la nueva modalidad</label>
                <select
                    className={css.input}
                    name="modality"
                    id="modality"
                    onChange={handleChange}
                >
                    <option value="" hidden>
                        Selecciona una modalidad
                    </option>
                    {modalities.map(modality => (
                        <option
                            key={"modality" + modality.id}
                            value={modality.id}
                        >
                            {modality.modality}
                        </option>
                    ))}
                </select>
                
                <div className={css.buttons}>
                    <Button
                        className={css.cancel}
                        type="button"
                        onClick={onCancel}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={css.button}
                        type="submit"
                        disabled={isDisabled}
                    >
                        Agregar
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default AssignNewModality;