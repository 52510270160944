import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useProfile } from "../context/MainContext";

export default function ProtectedRouteDashboard({ component: Component, ...rest }) {
  const { isAuthenticated, userType } = useProfile();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (userType !== "learner") {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
}