import React from 'react'

import classNames from 'classnames'

import ThumbUpAltIcon from '../../../../assets/thumb-up.png'
import ThumbDownAltIcon from '../../../../assets/thumb-down.png'
import Button from '../partials/Button'

import css from './Feedback.module.css'

export default function Feedback({ saveSurvey, surveyIsVisible }) {

  const [showAnimation, setShowAnimation] = React.useState(false)

  return (
    <div className={css.root}>
      {surveyIsVisible ? (
        <>
          <h5 className={css.textNext}>¿Qué opinas del contenido en esta sección?</h5>
          <div className={css.buttonsContainer}>
            <Button
              newButton={true}
              isicon={true}
              onClick={() => {
                setShowAnimation('yes')
                saveSurvey('yes')

                setTimeout(() => {
                  setShowAnimation('show')
                }, 1000)
              }}
              className={css.actionButton}
            >
              <img src={ThumbUpAltIcon} alt="Me gusta" className={classNames(css.icon, {
                [css.wobble]: showAnimation === 'yes',
              })}/>
              Me gusta
            </Button>
            <Button
              newButton={true}
              isicon={true}
              onClick={() => {
                setShowAnimation('no')
                saveSurvey('no')

                setTimeout(() => {
                  setShowAnimation('show')
                }, 1000)
              }}
              className={css.actionButton}
            >
              <img src={ThumbDownAltIcon} alt="No me gusta" className={classNames(css.icon, {
                [css.wobbleBack]: showAnimation === 'no',
              })} />
              No me gusta
            </Button>
          </div>
        </>
      ) : (
        <div className={css.content}>
          <h5 className={classNames(css.textNow)}>¡Ahora es NAO!</h5>
        </div>
      )}
    </div>
  )
}
