import React from 'react'

import { KeyboardArrowDown } from '@material-ui/icons'
import MenuIcon from '@material-ui/icons/Menu'
import { NavLink, useRouteMatch } from 'react-router-dom'

import { useProfile } from '../../../../context/MainContext'

import logo from '../../../../assets/logo-nao.png'
import defaultAvatar from '../../../../assets/default-user-profile.png'
import NavBar from './NavBar'
import Button from './Button'

import css from './TopBar.module.css'

export default function TopBar() {
  const { isAuthenticated, user, userId, imageProfile, handleDrawerToggle } = useProfile()

  const matchSprint = useRouteMatch('/challenges/:id/sprints')

  return (
    <div>
      <header className={css.appBar}>
        <div className={css.topContainer}>
          {matchSprint && (
            <Button
              isicon={true}
              newButton={true}
              className={css.menuButton}
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </Button>
          )}
          <NavLink to="/">
            <img src={logo} alt="logo" className={css.logo} />
          </NavLink>
          <div className={css.tabsContainer}>
            {!matchSprint && <NavBar isLogin={isAuthenticated} />}
          </div>
          {!matchSprint && (
            <div className={css.enterContainer}>
              {isAuthenticated && (
                <nav aria-label="Perfil" className={css.horizontalList}>
                  <NavLink className={css.buttonNav} to={`/profile/${userId}/`}>
                    <div
                      className={css.large}
                    >
                      <img alt="Remy Sharp" src={imageProfile != null ? imageProfile : defaultAvatar} />
                    </div>
                  </NavLink>
                  <NavLink to={`/profile/${userId}/`} className={css.buttonNav}>
                    <div
                      
                      className={css.profileName}
                    >
                      <div
                        
                        className={css.profileName}
                      >{user.given_name}
                      </div>
                    </div>
                    <div className={css.rightIcon}>
                      <KeyboardArrowDown />
                    </div>
                  </NavLink>
                </nav>
              )}
            </div>
          )}
          {matchSprint && (
            <div className={css.enterContainer}>
              {isAuthenticated && (
                <nav aria-label="Perfil" className={css.horizontalList}>
                  <NavLink className={css.buttonNav} to={`/profile/${userId}/`}>
                    <div
                      className={css.large}
                    >
                      <img alt="Remy Sharp" src={imageProfile != null ? imageProfile : defaultAvatar} />
                    </div>
                  </NavLink>
                  <NavLink to={`/profile/${userId}/`} className={css.buttonNav}>
                    <div
                      
                      className={css.profileName}
                    >{user.given_name}
                    </div>
                    <div className={css.rightIcon}>
                      <KeyboardArrowDown />
                    </div>
                  </NavLink>
                </nav>
              )}
            </div>
          )}
        </div>
      </header>
      <div className={css.offset} />
    </div>
  )
}
