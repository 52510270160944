import Axios from "axios";
import {
    getAllModalitiesURL,
    getModalitiesByIdURL,
    getSprintModalitiesURL,
    getModalitiesCreateURL,
    getModalitiesUpdateURL,
    getModalitiesDeleteURL,
    changeChallengeVisibilityStatusURL,
    getModalityChildrenURL,
    getUpdateTitleModalityURL
} from "../../const/Constants";

let instance = Axios.create({
    baseURL: `${process.env.REACT_APP_CMS_API}api/v2`,
  });

export async function getAllModalities() {
    return new Promise((resolve, reject) => {
        instance
            .get(getAllModalitiesURL())
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function getModalityById(id) {
    return new Promise((resolve, reject) => {
        instance
            .get(getModalitiesByIdURL(id))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function getSprintModalities(sprintId) {
    return new Promise((resolve, reject) => {
        instance
            .get(getSprintModalitiesURL(sprintId))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function storeModality(data) {
    return new Promise((resolve, reject) => {
        instance
            .post(getModalitiesCreateURL(), data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export async function updateModality(id, data) {
    return new Promise((resolve, reject) => {
        instance
            .put(getModalitiesUpdateURL(id), data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}

export async function deleteModality(id) {
    return new Promise((resolve, reject) => {
        instance
            .put(getModalitiesDeleteURL(id))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response.data);
            });
    });
}


export async function changeChallengeVisibilityStatus(data) {
    return new Promise((resolve, reject) => {
      instance
        .put(changeChallengeVisibilityStatusURL(), data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  }

export async function getModalityChildren(sprintId, modalityId) {
    return new Promise((resolve, reject) => {
        instance
            .get(getModalityChildrenURL(sprintId, modalityId))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export async function updateTitleModality(modalityId, title) { 
    return new Promise((resolve, reject) => { 
        instance.put(getUpdateTitleModalityURL(modalityId, title))
        .then (response => { 
            resolve(response.data)
        })
        .catch(error => { 
            reject(error)
        })
    })
}