import React from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Alert,
  Modal,
} from 'reactstrap'
import Swal from 'sweetalert2'

import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/modules/dashboard/partials/Header'
import Button from '../../components/modules/dashboard/partials/Button'
import SearchInput from '../../components/modules/dashboard/partials/SearchInput'
import { getUsersFor, deleteUser } from '../../service/module/Users'
import { useProfile } from '../../context/MainContext'

import css from './Users.module.css'

export default function Users() {
  const [list, setList] = React.useState()
  const [meta, setMeta] = React.useState()
  const [pages, setPages] = React.useState()
  const [currentPage, setCurrentPage] = React.useState('')

  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const [userId, setUserId] = React.useState()
  const [isUserDeleted, setIsUserDeleted] = React.useState()
  const [message, setMessage] = React.useState()
  const [colorMessage, setColorMessage] = React.useState()
  const { userType } = useProfile()
  const [userTypeSelected, setUserTypeSelected] = React.useState("learner")
  const [keyword, setKeyword] = React.useState(null)

  const ableToDelete = false;

  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    if (location.message) {
      setMessage(location.message.message)
      setColorMessage('success')
    }
  }, [location])

  React.useEffect(() => {
    async function getUsers(current) {
      await getUsersFor(userTypeSelected, current, keyword)
        .then(response => {
          setList(response.data)
          setMeta(response.meta)
          setPages(range(response.meta.first_page, response.meta.last_page))
        })
        .catch(() => { })
    }

    getUsers(currentPage)
  }, [currentPage, isUserDeleted, userTypeSelected, keyword])

  function range(start, end) {
    var foo = []
    for (var i = start; i <= end; i++) {
      foo.push(i)
    }
    return foo
  }

  async function getUsers(current) {
    await getUsersFor(userTypeSelected, current)
      .then(response => {
        setList(response.data)
        setMeta(response.meta)
        setPages(range(response.meta.first_page, response.meta.last_page))
      })
      .catch(() => { })
  }

  function previous(e) {
    e.preventDefault()
    if (meta.previous_page_url) {
      setCurrentPage(meta.previous_page_url)
    }
  }

  function clickPage(e) {
    e.preventDefault()
    const page = e.target.getAttribute('href')
    setCurrentPage(page)
  }

  function next(e) {
    e.preventDefault()
    if (meta.next_page_url) {
      setCurrentPage(meta.next_page_url)
    }
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  function toggleModal() {
    setIsOpenModal(!isOpenModal)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  function prepareDeleteUser(userId) {
    setUserId(userId)
    swalCustom.fire({
      title: '¿Estás seguro que deseas eliminar este usuario?',
      icon: 'success',
      showDenyButton: true,
      showConfirmButton: false,
      denyButtonText: 'Regresar',
    }).then(_result => {
    })
  }

  function editUserAction(userId) {
    history.push(`/dashboard/users/${userId}/edit`)
  }

  async function deleteUserAction() {
    await deleteUser(userId)
      .then(response => {
        setMessage('Usuario eliminado correctamente.')
        setColorMessage('success')
        setIsUserDeleted(!isUserDeleted)
        getUsers(currentPage)
      })
      .catch(error => {
        setMessage(error.message)
        setColorMessage('danger')
      })

    toggleModal()
  }

  return (
    <div>
      <Header />
      {/* Page content */}
      <div className={classNames(css.root)}>
        {message && colorMessage && (
          <Alert color={colorMessage}>
            <strong>¡{colorMessage.charAt(0).toUpperCase() + colorMessage.slice(1)}!</strong>{' '}
            {message}
          </Alert>
        )}
        <div className={css.tableContainer}>
          <div className={css.tableHeader}>
            <div className={css.firstHodler}>
              <h3 className={css.title}>Lista de Usuarios</h3>
              <p className={css.subtitle}>
                Resultados de búsqueda: Se encontraron <b>{meta?.total}</b> coincidencias
              </p>
              <div className={classNames(css.switch, {
                [css.left]: userTypeSelected === "learner",
                [css.right]: userTypeSelected === "operative",
              })}>
                <button
                  className={classNames(css.switchTab, {
                    [css.active]: userTypeSelected === "learner",
                  })}
                  type="button"
                  onClick={() => {
                    setUserTypeSelected("learner")
                  }}
                >
                  <p>
                    Aprendedor
                  </p>
                </button>
                <button
                  className={classNames(css.switchTab, {
                    [css.active]: userTypeSelected === "operative",
                  })}
                  type="button"
                  onClick={() => {
                    setUserTypeSelected("operative")
                  }}
                >
                  <p>
                    Administrador
                  </p>
                </button>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <SearchInput onSearch={setKeyword} placeholder="Buscar email o nombre"/>
            </div>
          </div>
          <div className={css.table}>
            <table className={css.table}>
              <thead className={css.thead}>
                <tr className={css.tr}>
                  <th scope="col" className={css.th}>Email</th>
                  <th scope="col" className={css.th}>Nombre(s)</th>
                  <th scope="col" className={css.th}>Apellido(s)</th>
                  {userTypeSelected === 'learner' && (
                    <th scope="col" className={css.th}>Perfil completo</th>
                  )}
                  {userTypeSelected === 'learner' && (
                    <th scope="col" className={css.th}>Grupo</th>
                  )}
                  {userTypeSelected === 'operative' && (
                    <th scope="col" className={css.th}>Tipo</th>
                  )}
                  {userType === 'operative' && <th scope="col" className={css.th}>Acciones</th>}
                </tr>
              </thead>
              <tbody className={css.tbody}>
                {list &&
                  list.map((item, key) => {
                    return (
                      <tr className={css.tr} key={key}>
                        <td className={css.td}>
                          <span className={css.name}>{item.email}</span>
                        </td>
                        <td className={css.td}>{item.names}</td>
                        <td className={css.td}>{item.last_name}</td>
                        {userTypeSelected === 'learner' && (
                          <td className={css.td}>{item.profile || "-"}</td>
                        )}
                        {userTypeSelected === 'learner' && (
                          <td className={css.td}>{item.group_name || "-"}</td>
                        )}
                        {userTypeSelected === 'operative' && (
                          <td className={css.td}>
                            {item.user_type === 'operative' ? 'Operativo' : 'Administrador'}
                          </td>
                        )}
                        {userType === 'operative' && (
                          <td className={classNames(css.td, css.actionButtons)}>
                            <Button
                              isicon={true}
                              className={css.edit}
                              type="button"
                              title='Ver más'
                              onClick={() => editUserAction(item.id)}
                            >
                              <span>
                                <FontAwesomeIcon icon={faEye} />
                              </span>
                            </Button>
                            {item.user_type === 'learner' && (
                              <Button
                                isicon={true}
                                className={css.assign}
                                type="button"
                                title='Asignar'
                                onClick={() => {
                                  history.push(`/dashboard/users/${item.id}/assign`)
                                }}
                              >
                                <span>
                                  <FontAwesomeIcon icon={faUser} />
                                </span>
                              </Button>
                            )}
                            {ableToDelete && (
                              <Button
                                isicon={true}
                                className={css.delete}
                                type="button"
                                title='Eliminar'
                                onClick={() => prepareDeleteUser(item.id)}
                              >
                                <span>
                                  <FontAwesomeIcon icon={faTrash} />
                                </span>
                              </Button>
                            )}
                          </td>
                        )}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div className={css.tableFooter}>
            {list && list.length > 0 ? (
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  {meta && (
                    <PaginationItem className={!meta.previous_page_url ? 'disabled' : ''}>
                      <PaginationLink
                        href={meta.first_page_url}
                        onClick={previous}
                        tabIndex={!meta.previous_page_url ? '-1' : undefined}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  {pages &&
                    pages.map((item, key) => {
                      /*
                       Always show 9 pages (if there are more than 9)
                       show 4 pages before and 4 pages after the current page
                      */
                      if (meta.current_page <= 5 && key < 9) {
                        return (
                          <PaginationItem
                            key={key}
                            className={meta.current_page === item ? 'active' : ''}
                          >
                            <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                              {item}
                              {meta.current_page === item && (
                                <span className="sr-only">(current)</span>
                              )}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }

                      if (meta.current_page > 5) {
                        if (key > meta.current_page - 6 && key < meta.current_page + 4) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      }

                      if (meta.last_page - meta.current_page < 5) {
                        if (key > meta.last_page - 10) {
                          return (
                            <PaginationItem
                              key={key}
                              className={meta.current_page === item ? 'active' : ''}
                            >
                              <PaginationLink href={`/?page=${item}`} onClick={clickPage}>
                                {item}
                                {meta.current_page === item && (
                                  <span className="sr-only">(current)</span>
                                )}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      }

                    })}
                  {meta && (
                    <PaginationItem className={!meta.next_page_url ? 'disabled' : ''}>
                      <PaginationLink href={meta.last_page_url} onClick={next}>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  )}
                </Pagination>
              </nav>
            ) : (
              <div className={css.noData}>
                <p>Sin usuarios</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={isOpenModal}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            ¡Atención!
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">¿Estás seguro de eliminar el usuario?</h4>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            onClick={() => deleteUserAction()}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
    </div>
  )
}
