import { useState } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
// nodejs library to set properties for components
import { PropTypes } from 'prop-types'

import classNames from 'classnames'

import { logoutApi } from '../../../../service/module/Auth'
import { removeToken } from '../../../../service/HelperServices'
import { useProfile } from '../../../../context/MainContext'

import css from './SideBar.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

const SideBar = props => {
  const history = useHistory()

  const [openDropdown, setOpenDropdown] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)

  const { logo } = props
  let navbarBrandProps
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      href: logo.innerLink,
      tag: Link,
    }
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    }
  }

  async function userLogout() {
    await logoutApi()
      .then(response => {
        if (response.success) {
          removeToken()
          history.push('/')
        }
      })
      .catch(err => {
        console.log(err.message)
      })
  }
  const { user } = useProfile()

  return (
    <nav className={css.root} id="sidenav-main">
      <div className={css.container}>
        {/* Toggler */}
        <button
          className={css.menuButton}
          type="button"
          onClick={() => {
            setCollapseOpen(!collapseOpen)
          }}
        >
          <span className={css.menuIcon} />
        </button>
        {/* Brand */}
        {logo ? (
          <a className={css.brand} {...navbarBrandProps}>
            <img alt={logo.imgAlt} className={css.brandImg} src={logo.imgSrc} />
          </a>
        ) : null}
        {/* User */}
        <ul className={css.dropdown}>
          <li className={css.dropdownContainer}>
            <div
              className={css.dropToggle}
              onClick={() => {
                setOpenDropdown(!openDropdown)
              }}
            >
              <span className={css.avatar}>
                <img
                  alt="..."
                  className={css.avatarImg}
                  src={
                    user != null && user.picture != null
                      ? user.picture
                      : require('../../../../assets/default-user-profile.png').default
                  }
                />
              </span>
            </div>
            <div
              className={classNames(css.dropdownMenu, {
                [css.show]: openDropdown,
              })}
            >
              <div className={css.dropdownHeader}>
                <h6 className={css.dropTitle}>¡Bienvenido!</h6>
              </div>
              {/*
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Mi Perfil</span>
              </DropdownItem>
              <DropdownItem divider />
              */}
              <button href="#logout" className={css.dropdownItem} onClick={() => userLogout()}>
                <i className={classNames(css.icon, 'ni ni-user-run')} />
                <span>Logout</span>
              </button>
            </div>
          </li>
        </ul>
        {/* Collapse */}
        <div
          className={classNames(css.collapse, {
            [css.show]: collapseOpen,
          })}
        >
          {/* Collapse header */}
          <div className={css.collapseHeader}>
            {logo ? (
              logo.innerLink ? (
                <a href={logo.innerLink}>
                  <img alt={logo.imgAlt} src={logo.imgSrc} className={css.logo} />
                </a>
              ) : (
                <a href={logo.outterLink}>
                  <img alt={logo.imgAlt} src={logo.imgSrc} className={css.logo} />
                </a>
              )
            ) : null}
            <button
              className={css.collapseToggler}
              type="button"
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              <span />
              <span />
            </button>
          </div>
          {/* Navigation */}
          <div className={css.collapseContainer}>
            <NavLink
              exact
              to="/dashboard"
              activeClassName={css.activeLink}
              className={css.collapseLink}
              onClick={() => setCollapseOpen(false)}
            >
              <i className="ni ni-tv-2" />
              Dashboard
            </NavLink>
            <NavLink
              exact
              to="/dashboard/challenges"
              className={classNames(css.collapseLink, {
                [css.activeLink]: history.location.pathname.includes('/dashboard/challenges'),
              })}
              onClick={() => setCollapseOpen(false)}
            >
              <i className="ni ni-briefcase-24" />
              Tarjetas
            </NavLink>
            <NavLink
              exact
              to="/dashboard/roles"
              className={classNames(css.collapseLink, {
                [css.activeLink]: history.location.pathname.includes('/dashboard/roles'),
              })}
              onClick={() => setCollapseOpen(false)}
            >
              <i className="ni ni-user-run" />
              Perfiles
            </NavLink>
            <NavLink
              exact
              to="/dashboard/groups"
              className={classNames(css.collapseLink, {
                [css.activeLink]: history.location.pathname.includes('/dashboard/groups'),
              })}
              onClick={() => setCollapseOpen(false)}
            >
              <FontAwesomeIcon icon={faUsers} style={{marginRight:16}}/>
              Grupos
            </NavLink>
            <NavLink
              exact
              to="/dashboard/users"
              className={classNames(css.collapseLink, {
                [css.activeLink]: history.location.pathname.includes('/dashboard/users'),
              })}
              onClick={() => setCollapseOpen(false)}
            >
              <i className="ni ni-single-02" />
              Usuarios
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  )
}

SideBar.defaultProps = {
  routes: [{}],
}

SideBar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
}

export default SideBar
