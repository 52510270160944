import React from 'react'
import { useParams } from 'react-router'

import PropTypes from 'prop-types'

import { Hidden } from '@material-ui/core'
import Footer from '../../components/modules/main/partials/Footer'
import AsideDrawer from '../../components/modules/main/profile-page/AsideDrawer'
import TopDrawer from '../../components/modules/main/profile-page/TopDrawer'
import ProfileTab from '../../components/modules/main/profile-page/ProfileTab'

import { showUser } from '../../service/module/Users'

import { useProfile } from '../../context/MainContext'
import { getTeam } from '../../service/module/Teams'
import { getRoles } from '../../service/module/Roles'
import {
  getChallengesComplete,
  getChallengesModules,
  getPercentageComplete,
} from '../../service/module/Missions'

import css from './TeamMember.module.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function TeamMember() {
  const { id } = useParams()
  const { userId, setImageProfile } = useProfile()

  const [userApi, setUserApi] = React.useState()
  const [challenges, setChallenges] = React.useState()
  const [challengesComplete, setChallengesComplete] = React.useState()
  const [percentageChallenges, setPercentageChallenges] = React.useState()
  const [member, setMember] = React.useState()
  const [role, setRole] = React.useState()

  React.useEffect(() => {
    document.getElementById('root').classList.add('withoutHand')
  }, [])

  React.useEffect(() => {
    if (!userApi) {
      async function getUser(_id) {
        var userFilled = {}
        if (userId && _id) {
          await showUser(_id)
            .then(response => {
              userFilled = response.data[0]
              if (_id === userId) {
                setImageProfile(response.data[0].profile?.image_profile)
              }
              setUserApi(userFilled)
            })
            .catch()

          /*await getTeam(1, userId)
            .then(response => {
              const member = response.team.members.find(_member => _member.user_id === userFilled.id)
              setMember(member)

              getChallengesModules(1, member.role_id)
                .then(responseChallenge => {
                  let modules = responseChallenge.modules
                  setChallenges(responseChallenge.modules)

                  getChallengesComplete(userFilled.id)
                    .then(resChallengeComplete => {
                      let completeList = []
                      modules.forEach(item => {
                        const completes = resChallengeComplete.challenges.filter(
                          o => o.challenges_module === item.id
                        )

                        const challengesList = item.challenges.filter(
                          o => o.content_type !== 'parent'
                        )
                        if (completes.length === challengesList.length) {
                          completeList.push(item)
                        }
                      })

                      setChallengesComplete(completeList)
                    })
                    .catch()
                })
                .catch()

              getPercentageComplete(1, member.role_id, userFilled.id)
                .then(responsePercentage => {
                  setPercentageChallenges(responsePercentage.percentage)
                })
                .catch()
            })
            .catch()*/
        }
      }

      getUser(id)
    }
  }, [])

  React.useEffect(() => {
    if (member) {
      async function role() {
        await getRoles(`/${member?.role_id}`)
        .then(response => {
          setRole(response.data.name)
        })
      }
      role()
    }
  }, [member])

  return (
    <div className={css.grid}>
      <Hidden smDown>
        <AsideDrawer
          challenges={challenges}
          complete={challengesComplete}
          percentage={percentageChallenges}
          user={userApi}
          id={id}
          userId={userId}
          role={role}
        />
      </Hidden>
      <main
        lang='es'
        className={css.content}
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '100%' }}>
          <Hidden mdUp>
            <TopDrawer
              challenges={challenges}
              complete={challengesComplete}
              percentage={percentageChallenges}
              user={userApi}
            />
          </Hidden>
            <ProfileTab user={userApi} />
        </div>
        <div style={{ width: '100%' }}>
          <Footer style={{ position: 'fixed', bottom: 0 }} />
        </div>
      </main>
    </div>
  )
}
