import React, { useEffect, useState } from 'react';
import { getAllModalities } from '../../service/module/Modalities';

import Button from '../../components/modules/dashboard/partials/Button'

import css from './AssignSprintModality.module.css';

const AssignSprintModality = props => {
    const {
        className,
        onSubmit,
        onCancel
    } = props;

    const [modalities, setModalities] = useState([]);
    const [selected, setSelected] = useState('2');

    useEffect(() => {
        async function getModalities() {
            await getAllModalities()
                .then(response => {
                    setModalities(response.data);
                })
                .catch(() => {
                    console.error('Error al obtener las modalidades')
                });
        }

        getModalities();
    }, [])

    const handleChange = event => {
        event.preventDefault();
        setSelected(event.target.value);
    }

    return (
        <div className={css.root}>
            <form className={css.form} onSubmit={onSubmit}>
                <label className={css.label} htmlFor="modality">Modalidad</label>
                <select
                    className={css.input}
                    name="modality"
                    id="modality"
                    value={selected}
                    onChange={handleChange}
                >
                    <option value="" hidden>
                        Selecciona una modalidad
                    </option>
                    {modalities.map(modality => (
                        <option
                            key={modality.id}
                            value={modality.id}
                        >
                            {modality.modality}
                        </option>
                    ))}
                </select>
                
                <div className={css.buttons}>
                    <Button
                        className={css.cancel}
                        type="button"
                        onClick={onCancel}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={css.button}
                        type="submit"
                        disabled={selected === ''}
                    >
                        Agregar
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default AssignSprintModality;