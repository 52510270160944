import React from 'react'
import { useParams } from 'react-router'

import PropTypes from 'prop-types'

import { Hidden, withStyles, Tab, Tabs } from '@material-ui/core'
import Footer from '../../components/modules/main/partials/Footer'
import AsideDrawer from '../../components/modules/main/profile-page/AsideDrawer'
import TopDrawer from '../../components/modules/main/profile-page/TopDrawer'
import ProfileTab from '../../components/modules/main/profile-page/ProfileTab'
import MyChallengesTab from '../../components/modules/main/profile-page/MyChallengesTab'

import { showUser } from '../../service/module/Users'

import { useProfile } from '../../context/MainContext'
import {
  getChallengesComplete,
  getChallengesModules,
  getPercentageComplete,
} from '../../service/module/Missions'
import { getRolesById } from '../../service/module/Roles'
import { getAllModalities } from '../../service/module/Modalities'
import { getGroupBy } from '../../service/module/Groups'

import css from './Profile.module.css'

const StyledTabs = withStyles(theme => ({
  root: {
    minHeight: 60,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 5,
    '& > span': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 100,
      },
      width: '100%',
      backgroundColor: '#004DFF',
    },
  },
  flexContainer: {
    height: '100%',
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#676767',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    '&$selected': {
      opacity: 1,
      fontWeight: 700,
      color: '#004DFF',
    },
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Profile() {
  const { id } = useParams()
  const { logout, userId, setImageProfile } = useProfile()

  const [userApi, setUserApi] = React.useState()
  const [value, setValue] = React.useState(0)
  const [updateUser, setUpdateUser] = React.useState(true)
  const [challenges, setChallenges] = React.useState()
  const [challengesComplete, setChallengesComplete] = React.useState()
  const [percentageCurrent, setPercentageCurrent] = React.useState()
  const [percentageChallenges, setPercentageChallenges] = React.useState()
  const [currentChallenge, setCurrentChallenge] = React.useState()
  const [member, setMember] = React.useState()
  const [role, setRole] = React.useState()
  const [modalities, setModalities] = React.useState()
  const [modality, setModality] = React.useState(null)

  React.useEffect(() => {
    document.getElementById('root').classList.add('withoutHand')

    async function getModalities() {
      await getAllModalities()
        .then(response => {
          setModalities(response.data)
        })
        .catch()
    }

    getModalities()
  }, [])

  React.useEffect(() => {
    if (updateUser) {
      async function getUser(_id) {
        if (userId && _id) {
          await showUser(_id)
            .then(response => {
              const userFilled = response.data[0]
              if (_id === userId) {
                setImageProfile(response.data[0].profile?.image_profile)
              }
              setUserApi(userFilled)
              setUpdateUser(false)
            })
            .catch()
        }
      }

      getUser(id)
    }
  }, [id, userId, updateUser, setImageProfile])

  React.useEffect(() => {
    if (userApi && userApi.role_id) {
      async function role() {
        await getRolesById(`${userApi?.role_id}`)
          .then(response => {
            setRole(response?.data?.name)
          })
      }
      role()
    }
  }, [userApi])

  React.useEffect(() => {
    if (userApi) {
      async function userInfo() {
        //cambiar por grupo id -> here
        await getChallengesModules(userApi.profile.group_id, userApi.role_id)
          .then(responseModules => {
            if(responseModules.success){ 
              var modules = responseModules.data
              setChallenges(responseModules.data)
  
              getChallengesComplete(userId).then(response => {
                let completeList = []
                modules.forEach(item => {
                  const completes = response.success === true ? response.data.filter(
                    o => o.challenges_module === item.challenge_id
                  ) : []
  
                  let challenges = []
  
                    for(const challenge of item?.challenges){ 
                      if(challenge.content_type !== 'parent'){
                        challenges.push(challenge)
                      }else{ 
                        for(const modality of challenge.modalityContent){ 
                          if(modality.content_type !== 'parent'){ 
                            challenges.push(challenge)
                          }
                        }
                      }
                    }
  
                  if (completes.length === challenges.length) {
                    item['status'] = 'complete'
                    completeList.push(item)
                  } else {
                    item['status'] = 'pending'
                  }
                })
  
                //ChallengesComplete: Array con los retos completados
                setChallengesComplete(completeList)
                //barra de arrriba.
  
                setPercentageChallenges((completeList.length / modules.length) * 100)
              })
            }
            
          })
          .catch()

        getPercentageComplete(1, userApi.role_id, id)
          .then(responsePercent => {
            setPercentageCurrent(responsePercent.percentage_current)
            setCurrentChallenge(responsePercent.current)
          })
          .catch()

        getGroupBy(userApi.profile?.group_id)
          .then(response => {
            if (response.success) {
              const modality = modalities.find(modality => modality.id === response.data[0].modality_id)
              setModality(modality)
            } else {
              setModality(null)
            }
          })
      }
      userInfo()
    }
  }, [userApi])

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={css.grid}>
      <Hidden smDown>
        <AsideDrawer
          logout={logout}
          user={userApi}
          id={id}
          userId={userId}
          setImage={setImageProfile}
          role={role}
          modalities={modalities}
          modality={modality}
        />
      </Hidden>
      <main
        lang='es'
        className={css.content}
      >
        <div style={{ width: '100%' }}>
          <Hidden mdUp>
            <TopDrawer
              logout={logout}
              user={userApi}
              id={id}
              userId={userId}
              setImage={setImageProfile}
              role={role}
              modality={modality}
            />
          </Hidden>
          <div className={css.tabsContainer}>
            {userId && `${userId}` === `${id}` && (
              <StyledTabs value={value} onChange={handleChange} aria-label="profile tabs">
                <StyledTab label="Mi Perfil" {...a11yProps(0)} />
                <StyledTab label="Mis Tarjetas" {...a11yProps(1)} />
              </StyledTabs>
            )}
          </div>
          {userId && `${userId}` === `${id}` ? (
            <>
              <TabPanel value={value} index={0}>
                <ProfileTab user={userApi} userId={userId} setUpdateUser={setUpdateUser} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MyChallengesTab
                  challenges={challenges}
                  challengesComplete={challengesComplete}
                  percentageCurrent={percentageCurrent}
                  currentChallenge={currentChallenge}
                  complete={challengesComplete}
                  percentage={percentageChallenges}
                  user={userApi}
                />
              </TabPanel>
            </>
          ) : (
            <ProfileTab user={userApi} userId={userId} setUpdateUser={setUpdateUser} />
          )}
        </div>
        <div style={{ width: '100%' }}>
          <Footer style={{ position: 'fixed', bottom: 0 }} />
        </div>
      </main>
    </div>
  )
}
