import React from 'react'

import classNames from 'classnames'

import { NavLink } from 'react-router-dom'

import css from './NavBar.module.css'

export default function NavBar({ isLogin }) {
  return (
    <div className={css.muiBox}>
      <nav className={css.horizontalList}>
        {isLogin && (
          <>
            <NavLink 
              className={classNames(css.listItem, {
                [css.active]: window.location.pathname === '/community'
              })}
              to="/community"
            >
              <div className={css.text}>
                Ingresa a la Comunidad NAO
              </div>
            </NavLink>
            <NavLink 
              className={classNames(css.listItem, {
                [css.active]: window.location.pathname === '/challenges'
              })}
              to="/challenges"
            >
              <div className={css.text}>
              Tarjetas
              </div>
            </NavLink>
          </>
        )}
      </nav>
    </div>
  )
}
