import React from 'react'

import { useHistory, useParams, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import { storeSprint } from '../../service/module/Challenges'
import { getAllModalities, storeModality } from '../../service/module/Modalities';
import { useProfile } from '../../context/MainContext'

import Header from '../../components/modules/dashboard/partials/Header'
import SprintForm from '../../components/modules/dashboard/sprints/SprintForm'

import css from './AddSprint.module.css'

export default function AddSprint() {
  const [errors, setErrors] = React.useState({})
  const [sprint, setSprint] = React.useState({ allow_survey: '0' })
  const { userType, userId } = useProfile()
  const history = useHistory()

  const { id } = useParams()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const modalityId = searchParams.get('modality')
  const [modalityLabel, setModalityLabel] = React.useState()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [errors])

  React.useEffect(() => {
    async function getModalities() {
      await getAllModalities()
        .then(response => {
          getLabel(response.data, modalityId)
        })
        .catch(() => { })
    }

    getModalities()
  }, [])

  function changeCheckbox(e, isChecked) {
    e.target.value = isChecked
    changeInput(e)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function changeInput(e) {
    if (e.target.type === 'number') {
      if (e.target.value < 0) {
        errors[e.target.name] = 'El valor no puede ser negativo.'
        e.target.value = 0

        sleep(5000).then(() => {
          errors[e.target.name] = null
          // Set e.target.value to null to avoid the error message to be shown but mantain the other error messages
          setErrors({ ...errors, [e.target.name]: null })
        })
      }

      e.target.value = Math.round(e.target.value)
    }

    setSprint({
      ...sprint,
      [e.target.name]: e.target.value,
      challenges_module: id,
      created_by: userId,
    })
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function register() {
    await storeSprint({ ...sprint, name: sprint.tittle_modality})
      .then(response => {
        const sprintId = response.data
        storeModality({
          modality_id: modalityId,
          challenge_id: sprintId,
          tittle_modality: '',
          content: '<p></p>',
          is_child: 0,
          content_type: sprint.content_type,
          minutes: 0,
          allow_survey: 0,
          created_by: userId,
        }).then(response => {
          swalCustom.fire({
            title: 'Sprint creado exitosamente',
            icon: 'success',
            showDenyButton: true,
            showConfirmButton: true,
            denyButtonText: 'Seguir editando',
            confirmButtonText: 'Regresar al reto',
          }).then(result => {
            if (result.isConfirmed) {
              history.push(`/dashboard/challenges/${id}/edit`)
            }
            if (result.isDenied) {
              history.push(`/dashboard/challenges/${id}/sprint/${sprintId}/edit`)
            }
          })
        })
      })
      .catch(error => {
        setErrors(error.errors)
        Swal.fire({
          title: 'Error!',
          text: error.errors,
          icon: 'error',
          confirmButtonText: 'Regresar',
        }).then(result => {
          history.push(`/dashboard/challenges/${id}/edit`)
        })
      })
  }

  function getLabel (modalities, modalityId) { 
    let titleModality = modalities.find((modality) => modality.id === parseInt(modalityId))
    if(titleModality){ 
      setModalityLabel(titleModality.modality)
    }
  }

  return (
    <div>
      <Header />
      {(userType === 'operative' || userType === 'manager') && (
        <SprintForm
          changeCheckbox={changeCheckbox}
          changeInput={changeInput}
          errors={errors}
          register={register}
          title="Agregar Sprint"
          modalityTitle={modalityLabel}
        />
      )}
    </div>
  )
}