import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  makeStyles,
} from '@material-ui/core'
import classNames from 'classnames'

import css from './Button.module.css'

const useStyles = makeStyles(theme => ({
  buttonNav: {
    background: '#0000af',
    borderRadius: theme.shape.borderRadius,
    maxHeight: 35,
    fontSize: 16,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
    },
    '& p': {
      fontWeight: 500,
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  textContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      flex: '1 1 auto',
    },
  },
  buttonLight: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    border: '2px solid #004DFF',
    '& p': {
      color: '#004DFF !important',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 600,
    },
  },
  buttonDiscord: {
    backgroundColor: '#fff',
    textAlign: 'center',
    '-webkit-box-shadow': '2px 2px 12px -3px #000000',
    boxShadow: '2px 2px 12px -3px #000000',
    color: '#2727b9 !important',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
  },
  leftIcon: {
    display: 'flex',
    justifyContent: 'left',
    minWidth: 0,
  },
  rightIcon: {
    display: 'flex',
    justifyContent: 'left',
    minWidth: 0,
  },
  disabled: {
    color: '#aaa',
    cursor: 'default',
  },
  enabled: {
    color: '#FFF',
  },
  lightColor: {
    color: '#000',
  },
}))

function ListItemLink(props) {
  const classes = useStyles()
  return (
    <ListItem
      className={
        props.light === 'true'
          ? `${classes.buttonNav} ${classes.buttonLight}`
          : props.discord === 'true'
          ? `${classes.buttonNav} ${classes.buttonDiscord}`
          : `${classes.buttonNav}`
      }
      component={NavLink}
      {...props}
    />
  )
}

export default function Button(props) {
  const classes = useStyles()
  const leftIcon = props.leftIcon
  const rightIcon = props.rightIcon
  const { className, disabled, newButton, isicon, isDropOut } = props

  if (newButton) {
    return (
      <button
        {...props}
        className={classNames(css.root, className, {
          [css.light]: props.light,
          [css.discord]: props.discord === 'true',
          [css.isicon]: isicon,
          [css.disabled]: disabled,
          [css.isDropOut]: isDropOut,
        })}
      >
          {leftIcon}
          {props.title ? (
            <span className={css.text}>{props.title}</span>
          ) : props.children ? (
            props.children
          ) : null}
          {rightIcon}
      </button>
    )
  }

  return (
    <List component="nav" className={props.className}>
      <ListItemLink
        to={props.to}
        onClick={props.onClick}
        light={props.light}
        discord={props.discord}
      >
        {leftIcon && <ListItemIcon className={classes.leftIcon}>{leftIcon}</ListItemIcon>}
        <ListItemText
          className={classes.textContainer}
          primary={
            <Typography
              variant="body2"
              className={
                props.disabled === 'true'
                  ? classes.disabled
                  : props.light === 'true'
                  ? classes.lightColor
                  : classes.enabled
              }
            >
              {props.title}
            </Typography>
          }
        />
        {rightIcon && <ListItemIcon className={classes.rightIcon}>{rightIcon}</ListItemIcon>}
      </ListItemLink>
    </List>
  )
}
