import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "../node_modules/react-modal-video/scss/modal-video.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import App from "./App.jsx";


ReactDOM.render(
 
    <App />
,
  document.getElementById("root")
);
