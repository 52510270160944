import React from 'react'
import Footer from '../../components/modules/main/partials/Footer'

import css from './PrivacyPolicy.module.css'

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <>
      <div className={css.article}>
        <h2 className={css.title}>AVISO DE PRIVACIDAD</h2>
        <h3 className={css.subtitle}>Identidad y domicilio del Responsable</h3>
        <p className={css.text}>
          El Responsable de los datos personales que usted proporciona es Enseñanza e Investigación
          Superior, A.C. (en adelante Universidad Tecmilenio), ubicado en Av. Eugenio Garza Sada No.
          2501 Sur, colonia Tecnológico, Monterrey, Nuevo León, C.P. 64849.
        </p>
        <h3 className={css.subtitle}>
          Datos de contacto del Departamento de Protección de Datos Personales
        </h3>
        <p className={css.text}>
          Para cualquier duda relacionada con la protección de sus datos personales podrá contactar
          al Departamento de Protección de Datos Personales de Universidad Tecmilenio.
        </p>
        <p className={css.text}>
          Los datos de contacto del Departamento de Protección de Datos Personales son los
          siguientes: Paseo del Acueducto No. 2610, colonia Del Paseo Residencial, Monterrey, Nuevo
          León, C.P. 64909. Correo electrónico:{' '}
          <a href="mailto:aviso.privacidad@servicios.tecmilenio.mx">
            aviso.privacidad@servicios.tecmilenio.mx
          </a>
        </p>
        <h3 className={css.subtitle}>
          Datos personales y datos personales sensibles tratados por Universidad Tecmilenio
        </h3>
        <p className={css.text}>
          Universidad Tecmilenio tratará los siguientes datos personales para cumplir con la
          finalidad señalada en el presente aviso de privacidad:
        </p>
        <p className={css.text}>
          <ul>
            <li>Datos de Identificación.</li>
            <li>Datos de contacto.</li>
            <li>Datos de ubicación/localización.</li>
            <li>Intereses y preferencias de los prospectos/audiencias.</li>
            <li>Datos de conversaciones, correos electrónicos o llamadas de prospección.</li>
            <li>
              Identificadores en línea cómo cookies (funcionales, marketing y analytics, publicidad
              y esenciales).
            </li>
            <li>Datos provenientes de anunciantes.</li>
            <li>Información de comportamiento y navegación.</li>
            <li>Datos de pago (tarjeta de crédito/ débito) (Shopify/PayPal).</li>
            <li>
              Datos para facturación en caso de solicitud (RFC, dirección de correo electrónico,
              dirección fiscal completa).
            </li>
          </ul>
        </p>
        <p className={css.text}>
          Asimismo, le informamos que, para cumplir con las finalidades descritas en el presente
          aviso de privacidad, Universidad Tecmilenio no tratará datos personales sensibles.
        </p>
        <h3 className={css.subtitle}>
          Datos personales recabados de menores de edad y personas con discapacidad
        </h3>
        <p className={css.text}>
          Le informamos que los datos personales que recabemos de menores de edad y personas con
          discapacidad cognitiva serán recabados exclusivamente con el consentimiento del padre,
          tutor y/o persona con la patria potestad para dar cumplimiento a las finalidades previstas
          en el presente Aviso de Privacidad. En virtud de lo anterior, al proporcionar datos
          personales de menores de edad, se entenderá que estos son proporcionados por el padre,
          tutor y/o persona con la patria potestad del menor. En ningún caso recabaremos datos
          personales de menores de edad y/o personas con discapacidad cognitiva de forma directa.
        </p>
        <h3 className={css.subtitle}>Finalidades primarias</h3>
        <p className={css.text}>
          Universidad Tecmilenio tratará sus datos personales para las siguientes finalidades
          primarias y necesarias
        </p>
        <p className={css.text}>
          <ul>
            <li>Para Identificarle y registrarle.</li>
            <li>
              Para contactarle a través de diversos medios de comunicación física y electrónica y
              brindarle información.
            </li>
            <li>
              Para dar trámite a la(s) solicitud(es) de productos y servicios que Universidad
              Tecmilenio brinda o podría brindarle.
            </li>
            <li>
              En caso de que aplique, para gestionar y administrar el acceso electrónico a los
              sistemas e infraestructura tecnológica que se utiliza para que Usted reciba nuestros
              productos y servicios.
            </li>
            <li>Para realizar reportes estadísticos.</li>
            <li>
              Para gestionar los pagos, descuentos cuando apliquen, reembolsos y la facturación por
              los productos y servicios que le hayan sido proporcionados, incluyendo las actividades
              de cobranza.
            </li>
            <li>
              Para llevar un registro histórico y estadístico de los productos y servicios
              adquiridos por Usted así como para personalizar nuestra oferta de productos y
              servicios de acuerdo con sus intereses.
            </li>
            <li>
              Para atender dudas, quejas y solicitudes de información en relación con los productos
              y servicios que ofrecemos.
            </li>
            <li>Para dar cumplimiento a obligaciones previstas en la normatividad aplicable.</li>
          </ul>
        </p>
        <p className={css.text}>
          Le informamos que usted no puede oponerse para que Universidad Tecmilenio cese el
          tratamiento de sus datos para las finalidades primarias y necesarias, anteriormente
          mencionadas, en virtud de que el tratamiento es necesario para cumplir obligaciones
          derivadas de la relación entre usted y Universidad Tecmilenio.
        </p>
        <h3 className={css.subtitle}>Finalidades secundarias</h3>
        <p className={css.text}>
          Además, si usted no se opone, Universidad Tecmilenio tratará sus datos personales para las
          siguientes finalidades adicionales que no son necesarias para el servicio solicitado, pero
          que permiten y facilitan brindarle una mejor atención:
        </p>
        <p className={css.text}>
          <ul>
            <li>
              Para enviarle publicidad y comunicaciones con fines de mercadotecnia, tele- marketing
              o campañas financieras.
            </li>
            <li>
              Para la aplicación de encuestas y evaluaciones para mejorar la calidad de los
              productos y servicios que ofrecemos.
            </li>
            <li>
              Para enviarle información promocional de cursos, diplomados, seminarios, simposios,
              talleres extra-académicos y eventos.
            </li>
            <li>
              Brindarle información sobre oportunidades de desarrollo profesional y/o laboral de
              Universidad Tecmilenio y/o terceros con los que tengamos celebrados acuerdos
              jurídicos.
            </li>
          </ul>
        </p>
        <p className={css.text}>
          En caso de que no desee que sus datos personales sean tratados para alguna o todas las
          finalidades adicionales, desde este momento usted nos puede comunicar lo anterior al
          correo{' '}
          <a href="mailto:aviso.privacidad@servicios.tecmilenio.mx">
            aviso.privacidad@servicios.tecmilenio.mx
          </a>
        </p>
        <p className={css.text}>
          La negativa para el uso de sus datos personales para fines adicionales no podrá ser un
          motivo para negarle los servicios o productos que contrata o solicita ni para dar por
          terminada la relación establecida con nosotros.
        </p>
        <h3 className={css.subtitle}>Transferencias</h3>
        <p className={css.text}>
          Universidad Tecmilenio para cumplir las finalidades exigidas legalmente o por las
          autoridades competentes sólo transferirá los datos necesarios en los casos legalmente
          previstos.
        </p>
        <h3 className={css.subtitle}>Derechos ARCO y/o revocación del consentimiento</h3>
        <p className={css.text}>
          Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso,
          rectificación, cancelación u oposición (“Derechos ARCO”), así como revocar su
          consentimiento para el tratamiento de sus datos personales enviando un correo electrónico
          al Departamento de Protección de Datos Personales a la dirección electrónica{' '}
          <a href="mailto:aviso.privacidad@servicios.tecmilenio.mx">
            aviso.privacidad@servicios.tecmilenio.mx
          </a>
        </p>
        <p className={css.text}>
          En este sentido, puede informarse sobre los procedimientos, requisitos y plazos para el
          ejercicio de sus Derechos ARCO y/o revocación del consentimiento en nuestra página de
          internet http://tecmilenio.mx/aviso-de-privacidad/derechos-arco/, o bien ponerse en
          contacto con el Departamento de Protección de Datos Personales que dará trámite a las
          solicitudes para el ejercicio de estos derechos, y atenderá cualquier duda que pudiera
          tener respecto al tratamiento de su información. Los datos de contacto del Departamento de
          Protección de Datos Personales son los siguientes: Paseo del Acueducto No. 2610, colonia
          Del Paseo Residencial, Monterrey, Nuevo León, C.P. 64909. Correo electrónico:{' '}
          <a href="mailto:aviso.privacidad@servicios.tecmilenio.mx">
            aviso.privacidad@servicios.tecmilenio.mx
          </a>
        </p>
        <h3 className={css.subtitle}>Limitación y/o Divulgación de sus datos</h3>
        <p className={css.text}>
          Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud al{' '}
          <a href="mailto:aviso.privacidad@servicios.tecmilenio.mx">
            aviso.privacidad@servicios.tecmilenio.mx.
          </a>{' '}
          En caso de que su solicitud sea procedente se le registrará en el listado de exclusión
          propio de Universidad Tecmilenio.
        </p>
        <h3 className={css.subtitle}>Cambios al Aviso de Privacidad</h3>
        <p className={css.text}>
          Universidad Tecmilenio le notificará sobre cambios o actualizaciones al presente aviso de
          privacidad directamente a través de su correo electrónico.
        </p>
      </div>
      <Footer />
    </>
  )
}
