import { ConstructionOutlined } from '@mui/icons-material'
import Axios from 'axios'
import { reject } from 'lodash'

import {
  getAllChallengesURL,
  getChallengeUpdateURL,
  getLastChallengeCompleteURL,
  getResourceUpdateURL,
  getResourceURL,
  getSprintsAllURL,
  getSprintsChallengeURL,
  getSprintsChildStoreURL,
  getSprintsChildsURL,
  getSprintsResourcesAllURL,
  getSprintsResourcesURL,
  getSprintsUpdateURL,
  getSprintsURL,
  getFindChallengeURL,
  getAllChallengesModuleURL,
  getChallengesCreateURL,
  getChallengeUpdateByIdURL,
  getSprintsCreateURL,
  getResourceCreateURL,
  getChallengeByIdURL,
  getSprintsChallengeByIdURL,
  getChallengeModuleByIdIdURL,
  getDeleteSprintURL,
  getDeleteChallengeModuleURL,
  duplicateChallengeURL,
  getUpdateSprintsOrderURL,
  deleteExternalResourceURL
} from '../../const/Constants'

let instance = Axios.create({
  baseURL: `${process.env.REACT_APP_CMS_API}api/v2`,
})

export async function getAllChallenges(page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getAllChallengesModuleURL(page))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getSprintsChallenge(challengeId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getSprintsChallengeByIdURL(challengeId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function storeSprint(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getSprintsCreateURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function storeResource(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getResourceCreateURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function storeSprintChild(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getSprintsChildStoreURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function storeChallenge(data) {
  return new Promise((resolve, reject) => {
    instance
      .post(getChallengesCreateURL(), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function showChallenge(challengeId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getChallengeByIdURL(challengeId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function showSprint(sprintId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getSprintsURL(sprintId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function showResource(resourceId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getResourceURL(resourceId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getSprints(moduleId, groupId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getSprintsChallengeURL(moduleId, groupId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getSprintsChild(page, parentId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getSprintsChildsURL (page, parentId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getSprintsResources(page, parentId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getSprintsResourcesURL(parentId, page))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function getSprintsResourcesAll(modalityId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getSprintsResourcesAllURL(modalityId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function updateChallenge(challengeId, data) {
  return new Promise((resolve, reject) => {
    instance
      .put(getChallengeUpdateByIdURL(challengeId), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getChallenge(challengeId) {
  return new Promise((resolve, reject) => {
    instance
      .get(getChallengeModuleByIdIdURL(challengeId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function updateSprint(idSprint, data) {
  return new Promise((resolve, reject) => {
    instance
      .put(getSprintsUpdateURL (idSprint), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function updateResource(idSprint, data) {
  return new Promise((resolve, reject) => {
    instance
      .put(getResourceUpdateURL(idSprint), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getLastChallenge(groupId, idUser) {
  return new Promise((resolve, reject) => {
    instance
      .get(getLastChallengeCompleteURL(idUser, groupId))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export async function getFindChallenge(id, page) {
  return new Promise((resolve, reject) => {
    instance
      .get(getFindChallengeURL(id, page))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

//challenges/modules/delete-sprint-in-challenge/:sprintID
export async function deleteSprintChallenge(id, data) { 
  return new Promise((resolve, reject) => { 
    instance
    .delete(getDeleteSprintURL(id), data)
    .then(response => { 
      resolve(response.data)
    })
    .catch(error => {
      reject(error)
    })
  })
}

export async function deleteChallengeModule(id, data){
  return new Promise((resolve, reject) => { 
    instance
    .put(getDeleteChallengeModuleURL(id), data)
    .then(response => { 
      resolve(response.data)
    })
    .catch(error => { 
      reject(error)
    })
  })
}

export async function duplicateChallenge(id, data) {
  return new Promise((resolve, reject) => {
    instance
      .get(duplicateChallengeURL(id), data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function updateSprintOrder(data) {
  return new Promise((resolve, reject) => {
    instance
    .put(getUpdateSprintsOrderURL(), data)
    .then(response => {
      resolve(response.data)
    })
    .catch(error => {
      reject(error)
    })
  })
}

export async function removeExternalResource(id){ 
  return new Promise((resolve, reject) => { 
    instance
    .delete(deleteExternalResourceURL(id))
    .then(response => { 
      resolve(response.data)
    })
    .catch(error => { 
      reject(error)
    })
  })
}