import React from 'react'
import { useHistory } from 'react-router-dom'

import { Alert } from 'reactstrap'

import Select from 'react-select'

import classNames from 'classnames'

import Button from '../partials/Button'

import css from './TeamForm.module.css'

export default function TeamForm({
  message,
  title,
  changeInput,
  register,
  errors,
  learners,
  update,
  roles,
  selectUser,
  usersSelected,
  team,
  members,
}) {

  const history = useHistory()
  function getDefaultValue(id) {
    const user = usersSelected.find(user => user.role_id === id)
    if (user) {
      return { label: `${user.label}`, value: `${user.value}` }
    } else {
      return { label: 'Selecciona un aprendedor', value: '-1' }
    }
  }
  return (
    <div className={css.root}>
      {message && typeof message === 'object' ? (
          <Alert color="danger">
            <strong>¡Ocurrio un error! |</strong>
          </Alert>
        ) : message ? (
          <Alert color="success">
            <strong>{update ? "¡Actualización exitosa!" : "Registro exitoso"}</strong>
          </Alert>
        ) : null}
      <div className={classNames(css.tableContainer, css.darkTableContainer)}>
        <div className={css.tableHeader}>
          <h3 className={css.title}>{title}</h3>
        </div>
        <form className={css.form}>
          <div className={css.inputs}>
            <div className={css.inputHolder}>
              <label className="form-control-label" htmlFor="input-email">
                Nombre
              </label>
              <input
                invalid={errors && errors.name ? true : false}
                onChange={changeInput}
                name="name"
                id="input-name"
                defaultValue={team && team.name}
                className={css.input}
                type="text"
              />
              {errors && errors.name && <div className={css.feedback}>{errors.name}</div>}
            </div>
            {/* <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-user-type"
                        >
                          Lider
                        </label>
                        {team ? (
                          <Input
                            invalid={errors && errors.leader ? true : false}
                            disabled={
                              usersSelected && usersSelected.length > 0
                                ? false
                                : true
                            }
                            name="leader"
                            onChange={changeInput}
                            type="select"
                            value={team && team.leader}
                            id="input-leader"
                            className="form-control-alternative"
                          >
                            <option value="" disabled>
                              Selecciona un Lider
                            </option>
                            {usersSelected &&
                              usersSelected.map((item, key) => {
                                return (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </Input>
                        ) : (
                          <Input
                            invalid={errors && errors.leader ? true : false}
                            disabled={usersSelected.length > 0 ? false : true}
                            name="leader"
                            onChange={changeInput}
                            type="select"
                            defaultValue=""
                            id="input-leader"
                            className="form-control-alternative"
                          >
                            <option value="" disabled>
                              Selecciona un Lider
                            </option>
                            {usersSelected &&
                              usersSelected.map((item, key) => {
                                return (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </Input>
                        )}
                        {errors && errors.leader && (
                          <FormFeedback>{errors.leader}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col> */}
            {/* <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-mission-id"
                        >
                          Misión
                        </label>
                        {team ? (
                          <Input
                            invalid={errors && errors.mission_id ? true : false}
                            name="mission_id"
                            onChange={selectedOption}
                            type="select"
                            disabled={true}
                            value={team.mission_id}
                            id="input-mission-id"
                            className="form-control-alternative"
                          >
                            <option value="" disabled>
                              Selecciona una missión
                            </option>
                            {missions &&
                              missions.map((item, key) => {
                                return (
                                  <option value={item.id} key={key}>
                                    {item.title}
                                  </option>
                                );
                              })}
                          </Input>
                        ) : (
                          <Input
                            invalid={errors && errors.mission_id ? true : false}
                            name="mission_id"
                            onChange={selectedOption}
                            type="select"
                            defaultValue=""
                            id="input-mission-id"
                            className="form-control-alternative"
                          >
                            <option value="" disabled>
                              Selecciona una missión
                            </option>
                            {missions &&
                              missions.map((item, key) => {
                                return (
                                  <option value={item.id} key={key}>
                                    {item.title}
                                  </option>
                                );
                              })}
                          </Input>
                        )}
                        {errors && errors.mission_id && (
                          <FormFeedback>{errors.mission_id}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col> */}
          </div>
          <hr className="my-4" />
          <h6 className="heading-small text-muted mb-4">Roles</h6>
          <div className={css.users}>
            {roles && members && learners
              ? roles.map((item, key) => {
                  return (
                    <div className={css.user}>
                      {/* <a
                                className="avatar"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img alt="..." src={item.thumbnail} />
                              </a> */}
                      <h5>
                        {item.name} {item.seniority && ' | ' + item.seniority}
                      </h5>
                      <Select
                        value={getDefaultValue(item.id)}
                        options={learners}
                        onChange={value => selectUser(value, item.id)}
                      />
                    </div>
                  )
                })
              : roles &&
                roles.map((item, key) => {
                  return (
                    <div className={css.user}>
                      {/* <a
                                className="avatar"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img alt="..." src={item.thumbnail} />
                              </a> */}
                      <h5>
                        {item.name} {item.seniority && ' | ' + item.seniority}
                      </h5>
                      <Select
                        value={getDefaultValue(item.id)}
                        options={learners}
                        onChange={value => selectUser(value, item.id)}
                      />
                    </div>
                  )
                })}
          </div>
          {/* <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Responsables</h6> */}
          {/* <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-user-type"
                        >
                          Mentor
                        </label>
                        {team ? (
                          <Input
                            invalid={errors && errors.coach ? true : false}
                            name="coach"
                            onChange={changeInput}
                            type="select"
                            value={team && team.coach}
                            id="input-coach"
                            className="form-control-alternative"
                          >
                            <option value="" disabled>
                              Selecciona un mentor
                            </option>
                            {operatives &&
                              operatives.map((item, key) => {
                                return (
                                  <option value={item.id} key={key}>
                                    {item.full_name}
                                  </option>
                                );
                              })}
                          </Input>
                        ) : (
                          <Input
                            invalid={errors && errors.coach ? true : false}
                            name="coach"
                            onChange={changeInput}
                            type="select"
                            defaultValue=""
                            id="input-coach"
                            className="form-control-alternative"
                          >
                            <option value="" disabled>
                              Selecciona un mentor
                            </option>
                            {operatives &&
                              operatives.map((item, key) => {
                                return (
                                  <option value={item.id} key={key}>
                                    {item.full_name}
                                  </option>
                                );
                              })}
                          </Input>
                        )}
                        {errors && errors.coach && (
                          <FormFeedback>{errors.coach}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div> */}
        </form>
        <div className={css.tableFooter}>
          <nav aria-label="...">
            <div className={css.pagination}>
              <Button
                className={css.delete}
                type="button"
                onClick={() => {
                  history.goBack()
                }}
              >
                Cancelar
              </Button>
              {register && (
                <Button type="button" onClick={register}>
                  Registrar
                </Button>
              )}
              {update && (
                <Button type="button" onClick={update}>
                  Actualizar
                </Button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
