import React, { forwardRef } from "react";

import { makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../partials/Input";

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: 5,
    color: "#747474",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    margin: 0,
  },
  containerInput: {
    position: "relative",
  },
  inputTextMutable: {
    background: "#fff",
    position: "absolute",
    color: "#6e6e71",
    paddingRight: 10,
    fontSize: 14,
    fontWeight: 400,
    left: 37,
    top: 12,
    width: "calc(100% - 40px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const SocialNetworkInput = (
  { onFocusInput, onBlurInput, onChangeInput, icon, value, url, title, error, errorMessage, isRequerid },
  ref
) => {
  const classes = useStyles();

  const [visibility, setVisibility] = React.useState(null);
  const [plusValue, setPlusValue] = React.useState(null);
  const [firstTime, setFirstTime] = React.useState(true);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (value != null && firstTime) {
      let text = value.replace(url).replace("undefined", "");
      setPlusValue(text);
      if (text !== "") {
        setVisibility(true);
      }
      setFirstTime(false);
    }
  }, [value, firstTime, url]);

  const onFocus = (e) => {
    let text = e.target.value;
    if (text !== "" || text !== null) {
      text = text.replace(url).replace("undefined", "");
      e.target.value = text;
      setVisibility(false);
      onFocusInput(text);
    }
  };

  const onBlur = (e) => {
    let text = e.target.value.replace("/", "").replace("@", "");
    if (text !== "") {
      const urlResult = url + text;
      e.target.value = urlResult;
      setPlusValue(text);
      setVisibility(true);
      onBlurInput(urlResult);
    }
  };

  return (
    <>
      <label className={classes.label}>
        {title}:
      </label>
      <div className={classes.containerInput}>
        <Input
          ref={ref != null ? ref : inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          error={error}
          onChange={onChangeInput}
          type="text"
          icon={<FontAwesomeIcon icon={icon} />}
          title="Escribe aquí tu usuario"
          errorMessage={errorMessage}
          isRequerid={isRequerid}
        />
        <div
          className={classes.inputTextMutable}
          onClick={() => {
            setVisibility(false);
            ref != null ? ref.current.focus() : inputRef.current.focus();
          }}
          style={{
            visibility: visibility ? "visible" : "hidden",
          }}
        >
          {url}
          <b>{plusValue}</b>
        </div>
      </div>
    </>
  );
};

export default forwardRef(SocialNetworkInput);
