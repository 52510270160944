import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useProfile } from "../context/MainContext";

import {
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const isInMaintainance = process.env.REACT_APP_MAINTAINANCE_ADMIN;

export default function ProtectedRouteMain({ component: Component, ...rest }) {
  const { isAuthenticated, userType } = useProfile();
  const classes = useStyles();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isInMaintainance === "true" && isAuthenticated && userType) {
          if (userType === "learner") {
            if (rest.path !== "/maintainance") {
              return (
                <Redirect
                  to={{
                    pathname: "/maintainance",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
            if (rest.path === "/maintainance") {
              return <Component {...props} />;
            }
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }
        if (isAuthenticated && userType) {
          if (userType === "learner") {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        } else {
          return (
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          );
        }
      }}
    />
  );
}
