import React, { useState, useCallback, useRef, useEffect } from 'react';
import { createPortal } from "react-dom";
import classNames from 'classnames';
import {
    $getSelection,
    $isRangeSelection,
    FORMAT_TEXT_COMMAND,
    $createParagraphNode,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {EditorRefPlugin} from '@lexical/react/LexicalEditorRefPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { HeadingNode, QuoteNode, $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $wrapNodes, $isAtNodeEnd } from "@lexical/selection";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
    ListItemNode,
    ListNode
} from "@lexical/list";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faListUl, faListOl, faLink, faEdit } from '@fortawesome/free-solid-svg-icons';

import Button from '../partials/Button'

import css from './Editor.module.css';

const LowPriority = 1;

const Editor = ({
    className,
    name,
    changeInput,
    editorRef
}) => {

    const URL_MATCHER =
        /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

    const MATCHERS = [
        (text) => {
            const match = URL_MATCHER.exec(text);
            if (match === null) {
                return null;
            }
            const fullMatch = match[0];
            return {
                index: match.index,
                length: fullMatch.length,
                text: fullMatch,
                url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
                attributes: { rel: 'noopener', target: '_blank' }, // Optional link attributes
            };
        },
    ];

    return (
        <div className={css.root}>
            <LexicalComposer
                initialConfig={{
                    namespace: name,
                    theme: {
                        heading: {
                            h2: css.h2,
                        },
                        paragraph: css.paragraph, // tailwind classes work!
                        quote: css.quote,
                        text: {
                            bold: css.bold,
                            italic: css.italic,
                            underline: css.underline,
                        },
                        list: {
                            nested: {
                                listitem: "editor-nested-listitem"
                            },
                            ol: "editor-list-ol",
                            ul: "editor-list-ul",
                            listitem: "editor-listitem"
                        },
                        code: css.code,
                        codeHighlight: {
                            atrule: css.atrule,
                            attr: css.attr,
                            "boolean": css.boolean,
                            builtin: css.builtin,
                            cdata: css.cdata,
                            char: css.char,
                            class: css.class,
                            'class-name': css.className,
                            comment: css.comment,
                            constant: css.constant,
                            deleted: css.deleted,
                            doctype: css.doctype,
                            entity: css.entity,
                            function: css.function,
                            important: css.important,
                            inserted: css.inserted,
                            keyword: css.keyword,
                            namespace: css.namespace,
                            number: css.number,
                            operator: css.operator,
                            prolog: css.prolog,
                            property: css.property,
                            punctuation: css.punctuation,
                            regex: css.regex,
                            selector: css.selector,
                            string: css.string,
                            symbol: css.symbol,
                            tag: css.tag,
                            url: css.url,
                            variable: css.variable,
                        },
                    },
                    onError(error) {
                        throw error;
                    },
                    editable: true,
                    nodes: [
                        HeadingNode,
                        ListNode,
                        ListItemNode,
                        QuoteNode,
                        CodeNode,
                        CodeHighlightNode,
                        TableNode,
                        TableCellNode,
                        TableRowNode,
                        AutoLinkNode,
                        LinkNode,
                    ],
                }}
            >
                <Toolbar />
                <RichTextPlugin
                    contentEditable={<div className={css.editor}><ContentEditable className={css.input} /></div>}
                    placeholder={null}
                />
                <OnChangePlugin onChange={(state, editor) => {
                    
                }} />
                <ListPlugin />
                <LinkPlugin />
                <HistoryPlugin />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                <EditorRefPlugin editorRef={editorRef} />
            </LexicalComposer>
        </div>
    )
}

function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

function positionEditorElement(editor, rect) {
    if (rect === null) {
        editor.style.opacity = "0";
        editor.style.top = "-1000px";
        editor.style.left = "-1000px";
    } else {
        editor.style.opacity = "1";
        editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
        editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
            }px`;
    }
}

function FloatingLinkEditor({ editor }) {
    const floatingLinkEditorRef = useRef(null);
    const inputRef = useRef(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState(null);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl("");
            }
        }
        const editorElem = floatingLinkEditorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== css.linkInput) {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl("");
        }

        return true;
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

 

    return (
        <div ref={floatingLinkEditorRef} className={css.linkEditor}>
            {isEditMode ? (
                <input
                    ref={inputRef}
                    className={css.linkInput}
                    value={linkUrl}
                    onChange={(event) => {
                        setLinkUrl(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            if (lastSelection !== null) {
                                if (linkUrl !== "") {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                                }
                                setEditMode(false);
                            }
                        } else if (event.key === "Escape") {
                            event.preventDefault();
                            setEditMode(false);
                        }
                    }}
                />
            ) : (
                <>
                    <div className={css.linkNoInput}>
                        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                            {linkUrl}
                        </a>
                        <div
                            className={css.linkEdit}
                            role="button"
                            tabIndex={0}
                            onMouseDown={(event) => event.preventDefault()}
                            onClick={() => {
                                setEditMode(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

const Toolbar = () => {
    const [editor] = useLexicalComposerContext();
    const [blockType, setBlockType] = React.useState("paragraph");
    const [isBold, setIsBold] = React.useState(false);
    const [isItalic, setIsItalic] = React.useState(false);
    const [isUnderline, setIsUnderline] = React.useState(false);
    const [isLink, setIsLink] = useState(false);
    const [selectedElementKey, setSelectedElementKey] = useState(null);


    const updateToolbar = React.useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                }
            }

            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            
            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    const formatParagraph = () => {
        if (blockType !== "paragraph") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode());
                }
            });
        }
    };

    const formatLargeHeading = () => {
        if (blockType !== "h2") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode("h2"));
                }
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
    };

    const formatQuote = () => {
        if (blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createQuoteNode());
                }
            });
        }
    };

    const addImage = (payload) => {
        if(payload.src) {

        }
    };

    const linkAttributes = {
        target:'_blank',
        rel: 'noonpener noreferrer'
    }

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, {url: "https://", ...linkAttributes});
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    React.useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            })
        );
    }, [updateToolbar, editor]);

    return (
        <div className={css.toolbar}>
            <select
                className={css.select}
                value={blockType}
                onChange={(e) => {
                    e.preventDefault();
                    switch (e.target.value) {
                        case "paragraph":
                            setBlockType("paragraph");
                            formatParagraph();
                            break;
                        case "h2":
                            setBlockType("h2");
                            formatLargeHeading();
                            break;
                        case "quote":
                            setBlockType("quote");
                            formatQuote();
                            break;
                        default:
                            break;
                    }
                }}
            >
                <option value="h2">Subtitulo</option>
                <option value="paragraph">Párrafo</option>
                <option value="quote">Cita</option>
            </select>
            <Button
                isicon={true}
                className={classNames(css.option, {
                    [css.active]: isBold
                })}
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
            >
                <FontAwesomeIcon
                    icon={faBold}
                    className="text-black w-3.5 h-3.5"
                />
            </Button>
            <Button
                isicon={true}
                className={classNames(css.option, {
                    [css.active]: isItalic
                })}
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
            >
                <FontAwesomeIcon
                    icon={faItalic}
                    className="text-black w-3.5 h-3.5"
                />
            </Button>
            <Button
                isicon={true}
                className={classNames(css.option, {
                    [css.active]: isUnderline
                })}
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
            >
                <FontAwesomeIcon
                    icon={faUnderline}
                    className="text-black w-3.5 h-3.5"
                />
            </Button>
            <Button
                isicon={true}
                className={classNames(css.option)}
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    formatBulletList();
                }}
            >
                <FontAwesomeIcon
                    icon={faListUl}
                    className="text-black w-3.5 h-3.5"
                />
            </Button>
            <Button
                isicon={true}
                className={classNames(css.option)}
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    formatNumberedList();
                }}
            >
                <FontAwesomeIcon
                    icon={faListOl}
                    className="text-black w-3.5 h-3.5"
                />
            </Button>
            <span className={css.divider}></span>

            {/* ... */}
            <Button
                type="button"
                isicon={true}
                className={classNames(css.option, {
                    [css.active]: isLink
                })}
                onClick={insertLink}
            >
                <FontAwesomeIcon
                    icon={faLink}
                    className="text-black w-3.5 h-3.5"
                />
            </Button>
            {isLink &&
                createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        </div>
    );
};

export default Editor;