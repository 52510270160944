import React from 'react'

import css from './CurrentChallenge.module.css'
import { LinearProgress, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'

const BlueProgress = withStyles(() => ({
    root: {
      height: 10,
      borderRadius: 0,
    },
    colorPrimary: {
      backgroundColor: '#dddcdd',
    },
    bar: {
      borderRadius: 0,
      backgroundColor: '#004DFF',
    },
  }))(LinearProgress)

export default function CurrentChallenge({ challengeId, imageUrl, currentPercentage, sequence, name, description, challengesComplete, roleId, completed }) {

    return (
        <div className={css.card}>
			<div className={css.imgHolder}>
			<img
				src={ imageUrl !== null 
                    ? imageUrl 
                    : "https://concepto.de/wp-content/uploads/2018/09/lenguaje-de-programación-e1537466880796-800x400.jpg" 
                }
				className={css.squareImage}
				alt=""
			/>
			<div>
				<BlueProgress variant="determinate" value={currentPercentage} />
			</div>
			</div>
			<div className={css.cardContainer}>
			<h5 className={css.titleChallenge}>
				{ sequence -1 === 0 ? 'Introducción' : "Reto " + (sequence -1) }. { name }
			</h5>
			<h6 className={css.subHeader}>COMPETENCIA</h6>
			<h6 className={css.contentChallenge}>{description}</h6>
			<div className={css.cardFooter}>
				<div className={css.cardHalf}>
                    { completed 
                        /* Mark as completed if completed is true */
                        ? (
                            <h6 className={css.inProcessText}>
                                <FontAwesomeIcon icon={faCheckCircle} /> Finalizado
                            </h6>
                        ) 
                        /* Dynamic status assignation */
                        :  challengesComplete?.filter( complete => complete.id === challengeId).length > 0 
                            ? (
                                <h6 className={css.inProcessText}>
                                    <FontAwesomeIcon icon={faCheckCircle} /> Finalizado
                                </h6>
                            ) 
                            : (
                                <>
                                    {currentPercentage === 0 ? (
                                        <h6 className={css.inPendingText}>Pendiente</h6>
                                    ) : (
                                        <h6 className={css.inProcessText}>En Progreso</h6>
                                    )}
                                </>
                            )
                    }
				</div>
				<div className={css.buttonNextContainer}>
				<NavLink
					className={css.buttonContinue}
					to={`/challenges/${challengeId}/sprints?role_id=${roleId}`}
				>
					Continuar
				</NavLink>
				</div>
			</div>
			</div>
		</div>
    )
    
}