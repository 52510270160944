import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  ThemeProvider,
  CssBaseline,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'

import Theme from '../Theme'
import TopBar from '../components/modules/main/partials/TopBar'

import LandingPage from '../views/main/LandingPage'
import ChallengesPage from '../views/main/Challenges'
import CommunityPage from '../views/main/Community'
import ProfilePage from '../views/main/Profile'
import TeamMember from '../views/main/TeamMember'
import SprintsPage from '../views/main/Sprints'
import IntroductionPage from '../views/main/Introduction'
import { useProfile } from '../context/MainContext'
import ProtectedRoute from '../hooks/ProtectedRouteMain'
import TermsAndConditions from '../views/main/TermsAndConditions'
import PrivacyPolicy from '../views/main/PrivacyPolicy'
import MaintainancePage from '../views/main/MaintainancePage'
import { VerifyEmail } from '../views/dashboard/VerifyEmail'
import SofiaBot from '../components/modules/main/partials/SofiaBot'

import css from './Main.module.css'

const isInMaintainance = process.env.REACT_APP_MAINTAINANCE_ADMIN;

export default function Main() {
  const { openBackdrop } = useProfile()

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Backdrop className={css.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isInMaintainance === "true" ? null : <TopBar />}
      <div className={css.root}>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <ProtectedRoute exact path="/terminos-y-condiciones" component={TermsAndConditions} />
          <ProtectedRoute exact path="/aviso-de-privacidad" component={PrivacyPolicy} />
          <ProtectedRoute exact path="/profile/:id" component={ProfilePage} />
          <ProtectedRoute exact path="/team/:id" component={TeamMember} />
          <ProtectedRoute exact path="/community" component={CommunityPage} />
          <ProtectedRoute exact path="/challenges" component={ChallengesPage} />
          <ProtectedRoute exact path="/challenges/:id/sprints" component={SprintsPage} />
          <ProtectedRoute exact path="/introduction" component={IntroductionPage} />
          <ProtectedRoute exact path="/verify-email" component={VerifyEmail}/>
          <ProtectedRoute path="/maintainance" component={MaintainancePage} />
        </Switch>
        {/* sofIA */}
        <SofiaBot />
      </div>
    </ThemeProvider>
  )
}

