import React from "react";

import Header from "../../components/modules/dashboard/partials/Header";
import Swal from 'sweetalert2'

import { useHistory, useParams } from "react-router-dom";
import { storeResource } from "../../service/module/Challenges";
import { useProfile } from "../../context/MainContext";
import ResourceFrom from "../../components/modules/dashboard/resources/ResourceForm";

import css from "./AddResource.module.css";

export default function AddResource() {
  const [errors, setErrors] = React.useState({});
  const [resource, setResource] = React.useState({ type: "reading" });
  const { userType } = useProfile();
  const history = useHistory();

  const { id, idSprint, modalityId, idChild} = useParams();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [errors]);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function changeInput(e) {
    if (e.target.type === 'number') {
      if (e.target.value < 0) {
        errors[e.target.name] = 'El valor no puede ser negativo.'
        e.target.value = 0

        sleep(5000).then(() => {
          errors[e.target.name] = null
          // Set e.target.value to null to avoid the error message to be shown but mantain the other error messages
          setErrors({ ...errors, [e.target.name]: null })
        })
      }

      e.target.value = Math.round(e.target.value)
    }

    setResource({
      ...resource,
      [e.target.name]: e.target.value,
      modality_content_id: idChild ? idChild : modalityId,
    });
  }

  const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: css.confirmButton,
      denyButton: css.cancelButton,
    },
    buttonsStyling: false,
  })

  async function register() {
    await storeResource(resource)
      .then((response) => {
        const { data } = response?.data;
        swalCustom.fire({
          title: 'Recurso creado exitosamente',
          icon: 'success',
          showDenyButton: true,
          showConfirmButton: true,
          denyButtonText: 'Seguir editando',
          confirmButtonText: 'Regresar al sprint',
        }).then(result => {
          if (!idChild) {
            if (result.isConfirmed) {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
            }
            if (result.isDenied) {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/resource/${data}/edit/${modalityId}`)
            }
          }

          if (idChild) {
            if (result.isConfirmed) {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${idChild}/edit`)
            }
            if (result.isDenied) {
              history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${idChild}/resource/${data}/edit`)
            }
          }
        })
      })
      .catch((error) => {
        swalCustom.fire({
          title: '¡Error!',
          text: 'Ha ocurrido un error',
          icon: 'error',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'Regresar',
        }).then(result => {
          if (idChild) {
            history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
          }
        })
      });
  }

  const goBack = () => {
    if (idChild) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/modality/${modalityId}/child/${idChild}/edit`)
    }

    if (!idChild) {
      history.push(`/dashboard/challenges/${id}/sprint/${idSprint}/edit`)
    }
  }

  return (
    <div>
      <Header />
      {(userType === "operative" || userType === "manager") && (
        <ResourceFrom
          changeInput={changeInput}
          errors={errors}
          register={register}
          title="Agregar Recurso"
          goBack={goBack}
        />
      )}
    </div>
  );
}
