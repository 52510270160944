import React from 'react';

import classNames from 'classnames';
import ChallengeAvatar from '../../assets/perfil-01.png';

import css from './ChallengeCard.module.css'

const ChallengeCard = props => {
    const {
        index,
        className,
        challenge,
        disabled,
        onClick
    } = props;

    const classes = classNames(css.card, className, {
        [css.pending]: challenge.status === 'pending',
        [css.disabled]: disabled === true,
    });

    var challengeModule = challenge?.challengesModule?.length > 0 ? challenge.challengesModule[0] : {}

    return (
        <div 
            key={index}
            onClick={onClick} 
            className={classes}
        >
            <div className={css.content}>
                {/* Título del reto*/}
                <h6 className={css.title}>
                {challenge.sequence -1 === 0 ? 'Introducción' : "Reto " + (challenge.sequence -1)}. {challengeModule.name}
                </h6>
                {/* Descripción del reto*/}
                <h6 className={css.subtitle}>
                    {challengeModule.description}
                </h6>
                {/* Status del reto*/}
                {challenge.status === 'pending' && (
                    <h6 className={css.pendingText}>Pendiente</h6>
                )}
                {challenge.status === 'in_progress' && (
                    <h6 className={css.inProgressText}>En Progreso</h6>
                )}
                {challenge.status === 'complete' && (
                    <h6 className={css.completeText}>Completo</h6>
                )}
            </div>
            {/* Avatar del reto*/}
            <img alt="" src={challengeModule.url_image && challengeModule.url_image !== null ? challengeModule.url_image : ChallengeAvatar} className={css.img} />
        </div>
    )
}

export default ChallengeCard;