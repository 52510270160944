import React from 'react'

import { Avatar, withStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons'

import { faDiscord, faGithub, faBehance } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import css from './Team.module.css'

const SocialButton = withStyles(() => ({
  rounded: {
    width: 18,
    height: 18,
    borderRadius: 2,
  },
}))(Avatar)

export default function Team({ group, mission, users, user }) {

  return (
    <>
      <h5 className={css.teamTitle}>Conoce a tu equipo</h5>
      <div className={css.root}>
        {users &&
          users.map((item, key) => {
            return (
              <NavLink key={key} to={`/team/${item.id}`} className={css.fourContainer}>
                <div className={css.card}>
                  <div className={css.imageContainer}>
                    <img
                      alt={item.names}
                      src={item.profile && item.profile.image_profile}
                      className={css.avatarImage}
                    />
                  </div>
                  <h6 className={css.userNames}>
                    {`${item.id}` === `${user.id}`
                      ? item?.names
                      : item?.allow_public_name
                      ? item?.names
                      : item?.alias
                      ? '@' + item.alias
                      : 'Aprendedor'}
                  </h6>
                  <div className={css.socialContainer}>
                    {item.profile?.linkedin && (
                      <a target="_blank" rel="noreferrer" href={item.profile.linkedin}>
                        <div variant="rounded" className={`${css.social} ${css.linkedIn}`}>
                          <LinkedInIcon style={{ fontSize: 14 }} />
                        </div>
                      </a>
                    )}
                    {item.profile?.twitter && (
                      <a target="_blank" rel="noreferrer" href={item.profile.twitter}>
                        <SocialButton variant="rounded" className={`${css.social} ${css.twitter}`}>
                          <TwitterIcon style={{ fontSize: 14 }} />
                        </SocialButton>
                      </a>
                    )}
                    {item.profile?.facebook && (
                      <a target="_blank" rel="noreferrer" href={item.profile.facebook}>
                        <SocialButton variant="rounded" className={`${css.social} ${css.facebook}`}>
                          <FacebookIcon style={{ fontSize: 14 }} />
                        </SocialButton>
                      </a>
                    )}
                    {item.profile?.instagram && (
                      <a target="_blank" rel="noreferrer" href={item.profile.instagram}>
                        <SocialButton
                          variant="rounded"
                          className={`${css.social} ${css.instagram}`}
                        >
                          <InstagramIcon style={{ fontSize: 14 }} />
                        </SocialButton>
                      </a>
                    )}
                    {item.profile?.discord && (
                      <a target="_blank" rel="noreferrer" href={item.profile.discord}>
                        <SocialButton variant="rounded" className={`${css.social} ${css.discord}`}>
                          <FontAwesomeIcon icon={faDiscord} style={{ fontSize: 14 }} />
                        </SocialButton>
                      </a>
                    )}
                    {item.profile?.github && (
                      <a target="_blank" rel="noreferrer" href={item.profile.github}>
                        <SocialButton variant="rounded" className={`${css.social} ${css.gitHub}`}>
                          <FontAwesomeIcon icon={faGithub} style={{ fontSize: 14 }} />
                        </SocialButton>
                      </a>
                    )}
                    {item.profile?.behance && (
                      <a target="_blank" rel="noreferrer" href={item.profile.behance}>
                        <SocialButton variant="rounded" className={`${css.social} ${css.behance}`}>
                          <FontAwesomeIcon icon={faBehance} style={{ fontSize: 14 }} />
                        </SocialButton>
                      </a>
                    )}
                  </div>
                </div>
              </NavLink>
            )
          })}
      </div>
    </>
  )
}
